import * as React from "react";
import { Box, Chip, Divider, Stack, Tooltip, Typography } from "@mui/material";
import ResourcesDataGridPro from "./resourceList";
import AdvancedSearchControl from "./advancedSearchControl";
import { useLazyGetAllResourcesQuery } from "../../../services/cclTokenedResourceApi";
import CclSearchDefault from "../../../components/common/cclSearchDefault";
import CclButton from "../../../components/common/cclButtons/cclButton";
import { useDispatch, useSelector } from "react-redux";
import { updateResourceSearchParams } from "../../../app/slices/resourceListConfigSlice";
import { KeyValue, SavedSearchItem } from "../../../services/types/enterpriseCoachingApiTypes";
import { ResourceDocument } from "../../../services/types/search.service.types";
import CclTextOrDoubleTextSearchBar from "../../../components/common/cclLandingPageSearchBars/cclTextOrDoubleTextSearchBar";

interface CclResourcesTabPanelProps {}

const CclResourcesTabPanel: React.FC<CclResourcesTabPanelProps> = (props) => {
    const searchSelector = useSelector((state: any) => state.resourceListConfig);
    const dispatch = useDispatch();

    const [searchEmail, setSearchEmail] = React.useState<string>(searchSelector.Email);
    const [searchFirstName, setSearchFirstName] = React.useState<string>(searchSelector.FirstName);
    const [searchLastName, setSearchLastName] = React.useState<string>(searchSelector.LastName);

    const [openAdvancedSearch, setOpenAdvancedSearch] = React.useState<boolean>(false);

    const [getAll, { data: resources, isLoading }] = useLazyGetAllResourcesQuery();
    const [data, setData] = React.useState<ResourceDocument[] | undefined>(undefined);
    const [advancedSearchResults, setAdvancedSearchResults] = React.useState<number[] | undefined>(
        undefined
    );
    const [showChips, setShowChips] = React.useState<boolean>(false);
    const [currentSearch, setCurrentSearch] = React.useState<SavedSearchItem>(
        {} as SavedSearchItem
    );
    const [chips, setChips] = React.useState<KeyValue[]>([]);
    const [preSearchChips, setPreSearchChips] = React.useState<KeyValue[]>([]);
    const [baseLength, setBaseLength] = React.useState<number>(0);
    const [excessLength, setExcessLength] = React.useState<number>(0);

    const onAdvancedSearchResults = (pKeys: number[] | undefined) => {
        setSearchEmail("");
        setSearchFirstName("");
        setSearchLastName("");
        setChips(preSearchChips);
        if (preSearchChips.length > 4) {
            setExcessLength(preSearchChips.length - 4);
        } else {
            setExcessLength(0);
        }
        setBaseLength(preSearchChips.length);
        setShowChips(true);
        dispatch(
            updateResourceSearchParams({
                email: null,
                firstName: null,
                lastName: null,
            })
        );
        setAdvancedSearchResults(pKeys);
        if (!data || data.length === 0) {
            getAll()
                .unwrap()
                .then((data) => {
                    setData(data);
                })
                .catch();
        }
    };

    const filteredResources = () => {
        if (!resources || resources.length === 0) {
            return [];
        } else if (advancedSearchResults) {
            return resources?.filter((x) => advancedSearchResults.includes(x.pkey));
        } else if (searchSelector.Email !== null) {
            return resources?.filter(
                (x) =>
                    x.email != null &&
                    x.email.localeCompare(searchSelector.Email, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0
            );
        } else if (searchSelector.FirstName !== null && searchSelector.LastName !== null) {
            return resources?.filter(
                (x) =>
                    x.firstname != null &&
                    x.lastname != null &&
                    x.firstname.localeCompare(searchSelector.FirstName, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0 &&
                    x.lastname.localeCompare(searchSelector.LastName, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0
            );
        } else if (searchSelector.FirstName) {
            return resources?.filter(
                (x) =>
                    x.firstname != null &&
                    x.firstname.localeCompare(searchSelector.FirstName, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0
            );
        } else if (searchSelector.LastName) {
            return resources?.filter(
                (x) =>
                    x.lastname != null &&
                    x.lastname.localeCompare(searchSelector.LastName, undefined, {
                        ignorePunctuation: true,
                        sensitivity: "base",
                    }) === 0
            );
        } else {
            return resources;
        }
    };

    const handleGetAll = () => {
        setSearchEmail("");
        clearChips();
        setSearchFirstName("");
        setSearchLastName("");
        setAdvancedSearchResults(undefined);
        dispatch(
            updateResourceSearchParams({
                email: null,
                firstName: null,
                lastName: null,
            })
        );
        getAll()
            .unwrap()
            .then((data) => {
                setData(data);
            })
            .catch();
        
    };

    const getReousrces = (email: string, firstName: string, lastName: string) => {
        setSearchEmail(email);
        clearChips();
        setSearchFirstName(firstName);
        setSearchLastName(lastName);
        setAdvancedSearchResults(undefined);
        dispatch(
            updateResourceSearchParams({
                email: email === "" ? null : email,
                firstName: firstName === "" ? null : firstName,
                lastName: lastName === "" ? null : lastName,
            })
        );
        if (!data || data.length === 0) {
            getAll()
                .unwrap()
                .then((data) => {
                    setData(data);
                })
                .catch();
        }
    };

    const updateCurrentSearch = (search: SavedSearchItem) => {
        let temp:any = [];
        temp = temp.concat(search.workPools, search.roles, search.timeZones, search.chips);
        if (search.originCountry) {
            temp.push(search.originCountry);
        }
        if (search.shippingCountry) {
            temp.push(search.shippingCountry);
        }
        if (search.shippingState) {
            temp.push(search.shippingState);
        }
        if (search.shippingCity) {
            temp.push(search.shippingCity);
        }
        temp = temp.filter(Boolean);
        setPreSearchChips(temp);
        setCurrentSearch(search);
        if (Object.keys(search).length === 0) {
            handleClearSearch();
        }
    };

    const clearChips = () => {
        setChips([]);
        setPreSearchChips([]);
        setShowChips(false);
        setBaseLength(0);
        setExcessLength(0);
        setCurrentSearch({} as SavedSearchItem);
    };

    const handleClearSearch = () => {
        setData(undefined);
        clearChips();
    };

    const chipDisplay = () => {
        if (!chips || chips.length === 0 || !showChips) return;
        let displayChips = chips.slice(0,4);
        let excessChips = chips.slice(4);

        return (
            <>
                {displayChips.map((x) => {
                        return <Chip key={x.id} label={x.label} sx={{ m: 1 }} />; 
                })}
                { excessLength > 0 &&
                    <Tooltip title={
                        <Box>
                            {excessChips.map((x) => {
                                return (<Typography variant="body2" key={x.id} sx={{m:1}}>{x.label}</Typography>);
                            })}
                        </Box>
                    } arrow placement="bottom">
                        <Typography sx={{m:1, alignSelf: "center"}} >
                             +{excessLength}
                        </Typography>
                    </Tooltip>
                }
            </>
        );
    }

    return (
        <Stack spacing={2} width={1} direction={"column"}>
            <Box sx={{ display: "flex", flexDirection: "row", width: 1 }}>
                <Box sx={{ flex: "1", paddingRight: 1 }}>
                    <CclTextOrDoubleTextSearchBar
                        initialFieldOneSearchTerm={searchEmail}
                        fieldOneSearchLabel={"Enter Resource Email"}
                        initialFieldTwoSearchTerm={searchFirstName}
                        initialFieldThreeSearchTerm={searchLastName}
                        fieldTwoSearchLabel={"Enter First Name"}
                        fieldThreeSearchLabel={"Enter Last Name"}
                        executeSearch={getReousrces}
                        clearSearchTrigger={handleClearSearch}
                        suppressErrors={true}
                        showResetButton={true}
                        requireBothFields={false}
                    />
                </Box>

                <Box
                    sx={{
                        flexShrink: 0,
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 1,
                        paddingRight: 1,
                        float: "right",
                    }}
                >
                    <CclButton
                        size="small"
                        onClick={handleGetAll}
                        sx={{
                            flexShrink: 0,
                            whiteSpace: "nowrap",
                            mr: 2,
                        }}
                        mode={"primary"}
                    >
                        Get All
                    </CclButton>
                </Box>
            </Box>
            {openAdvancedSearch && (
                <AdvancedSearchControl
                    open={openAdvancedSearch}
                    onClose={() => {
                        setOpenAdvancedSearch(false);
                    }}
                    onSearchResults={onAdvancedSearchResults}
                    updateCurrentSearch={updateCurrentSearch}
                    pastSearch={currentSearch}
                />
            )}
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Divider />
                <Box sx={{ width: "100%", m: 3, display: "flex", flexDirection: "row" }}>
                    <CclButton
                        onClick={() => setOpenAdvancedSearch(true)}
                        mode={"secondary"}
                        sx={{ mr: 3 }}
                    >
                        <Stack direction={"row"}>
                            Advanced Search
                            {baseLength > 0 && showChips && (
                                <Box
                                    sx={{
                                        ml: 2,
                                        background: "#2F5F95",
                                        borderRadius: "50%",
                                        width: "20px",
                                        color: "white"
                                    }}
                                >
                                    {baseLength}
                                </Box>
                            )}
                        </Stack>
                    </CclButton>
                    {chipDisplay()}
                </Box>
                <Divider sx={{ mb: 4 }} />
                {isLoading || data !== undefined ? (
                    <ResourcesDataGridPro loading={isLoading} resources={filteredResources()} />
                ) : (
                    <CclSearchDefault
                        line1={"Start searching for Resources"}
                        line2={
                            "Enter Resource Email or First and Last Name and click Search or click Get All or expand Advanced Search"
                        }
                        hasButtons={false}
                    ></CclSearchDefault>
                )}
            </Box>
        </Stack>
    );
};

export default CclResourcesTabPanel;