import * as React from "react";
import { Grid } from "@mui/material";
import { useGetAllBiosQuery} from "../../../services/cclTokenedEnterpriseCoachingApi";
import BioSearchDataGridPro from "./bioSearchList";
import { Biography } from "../../../services/types/bioApiTypes";
import { useLazyGetBioByEmailQuery } from "../../../services/cclTokenedBioApi";
import { useSelector } from "react-redux";
import { useGetAllResourcesQuery } from "../../../services/cclTokenedResourceApi";
import { ResourceDocument } from "../../../services/types/search.service.types";

interface BioSearchTabPanelProps {}

const BioSearchTabPanel: React.FC<BioSearchTabPanelProps> = (props) => {
    const searchSelector = useSelector((state: any) => state.resourceListConfig);
    const { data: getAllBios, isLoading: loading } = useGetAllBiosQuery(null);
    const [searchBios, { data: bio, isLoading: bioLoading }] = useLazyGetBioByEmailQuery();

    const [bios, setBios] = React.useState<Biography[]>([]);

    const {data, isLoading} = useGetAllResourcesQuery();

    const [resources, setResources] = React.useState<ResourceDocument[]>([]);

    

    React.useEffect(() => {
        if (searchSelector.Email == null) {
            return;
        }
        searchBios(searchSelector.Email);
        //eslint-disable-next-line
    }, [searchSelector.Email]);

    React.useEffect(() => {
        if (!getAllBios && !bio) {
            return;
        }
        if (getAllBios && !bio) {
            setBios(getAllBios.filter((bio: Biography) => bio.bioComplete));
        }
        if (bio) {
            if (!bio.bioComplete) return;

            setBios([bio]);
        }

        if (data && getAllBios) {
            let temp = data.filter((x) => getAllBios.map((y) => y.pKey).includes(x.pkey));
            setResources(temp);
        }
        // eslint-disable-next-line
    }, [getAllBios, bio, data]);

    const dataLoading = (): boolean => {
        if (loading || bioLoading || isLoading) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                alignItems="stretch"
                sx={{ justify: "space-between", flexGrow: 1 }}
            >
                <Grid item sx={{ display: "flex", flexDirection: "column", width: 1, height: 1 }}>
                    {bios && (
                        <BioSearchDataGridPro loading={dataLoading()} resources={bios} resourceData={resources} />
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default BioSearchTabPanel;
