import * as React from "react";
import { AlertColor } from "@mui/material";
import {
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridRenderCellParams,
    DataGridPro,
    GridColumns,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import CclDataGridToolbar from "../../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import { addCustomDataGridFilters } from "../../../../../components/common/customDataGridFilters/customDataGridFilters";
import {
    useGetCclParticipantsArchivedByCoachImKeyQuery,
    useUnarchiveParticipantMutation,
} from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import CclAlertSnackbar from "../../../../../components/common/cclAlertSnackbar";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";

interface ArchivedParticipantsDataGridProps {
    coachImKey: number;
}

const ArchivedParticipantsDataGrid: React.FC<ArchivedParticipantsDataGridProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
    const {
        data: archivedParticipants,
        isLoading,
        isError,
    } = useGetCclParticipantsArchivedByCoachImKeyQuery(props.coachImKey);
    const { logEvent } = useLogAccessEvent();

    const [unarchiveParticipants] = useUnarchiveParticipantMutation(); // unarchive product sale
    const claimsService = new UserClaimsService();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    React.useEffect(() => {
        if (isError === true) {
            ShowSnackbarElement("Error Retrieving Archived Participants", "error");
        }
    }, [isError]);

    const handleUnarchive = () => {
        if (selectedIds.length > 0)
            unarchiveParticipants({
                imkey: +claimsService.GetCurrentUserId(),
                esiKeys: selectedIds,
            })
                .unwrap()
                .then((result) => {
                    logEvent("ParticipantsUnarchived", null);
                    ShowSnackbarElement("Participant(s) Un-Archived", "success");
                })
                .catch(() => {
                    ShowSnackbarElement("Participant(s) Un-Archive Failed", "error");
                });
    };

    function getFullName(params: any) {
        return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
    }

    const COLUMNS = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
        },
        {
            field: "name",
            headerName: "Participant",
            flex: 0.65,
            valueGetter: getFullName,
        },
        {
            field: "projectCode",
            headerName: "Project ID",
            valueGetter: (params: GridRenderCellParams) => {
                return params.row.projectCode ?? "";
            },
            type: "string",
            flex: 0.4,
        },
        {
            field: "sessionType",
            headerName: "Type",
            valueGetter: (params: GridRenderCellParams) => {
                return params.row.sessionType;
            },
            type: "string",
            flex: 0.25,
        },
        {
            field: "startDate",
            headerName: "Start Date",
            valueGetter: (params: any) => new Date(params.row.startDate),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 175,
        },
    ];

    return (
        <React.Fragment>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <DataGridPro
                density="compact"
                getRowId={(row) => row.esiKey}
                rows={archivedParticipants ?? []}
                columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                components={{
                    Toolbar: CclDataGridToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        showSaveState: true,
                        hideSettingsButtons: true,
                        miscButtons: [
                            {
                                id: "unarchive",
                                caption: "Un-Archive Participant(s)",
                                label: "Un-Archive Participant(s)",
                                disable: selectedIds?.length <= 0,
                                onClick: () => handleUnarchive(),
                                loadingBtn: true,
                                isLoading: false,
                            },
                        ],
                        btnClick: () => handleUnarchive(),
                    },
                }}
                loading={isLoading}
                disableSelectionOnClick
                checkboxSelection={true}
                onSelectionModelChange={(esiKeys: GridSelectionModel) => {
                    setSelectedIds([...esiKeys.map((key) => key as number)]);
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "name", sort: "asc" }],
                    },
                }}
                sx={{
                    "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                }}
                autoHeight
            />
        </React.Fragment>
    );
};
export default ArchivedParticipantsDataGrid;
