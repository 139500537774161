import * as React from "react";
import { addressList } from "../../../../services/types/clientTypes";
import CclInfoTable, { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblAddressTemplate } from "./addressInfoTableTemplate";
import { Alert } from "@mui/material";

export interface HomeAddressDataGridProProps {
    addressDataList: addressList[] | undefined;
    showToolbar: boolean;
    isLoading: boolean;
}

export const HomeAddressDataGridPro: React.FC<HomeAddressDataGridProProps> = (
    props
) => {
    const [addressTemplate, setAddressTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);
    
    React.useEffect(() => {
        if (!props.addressDataList) {return}
        let template = CclInfoTblAddressTemplate;
        setAddressTemplate([...template]);
    }, [props.addressDataList]);

    return (
        <>
            <CclInfoTable 
                template={addressTemplate} 
                data={props.addressDataList? props.addressDataList?? [] : [] }            
            />
            {(props.addressDataList === undefined || props.addressDataList?.length === 0) &&
                <Alert sx={{mt: 2}} severity="info">No address found for client</Alert>
            }
        </>
    );
};

export default HomeAddressDataGridPro;
