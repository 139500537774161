import { Box } from "@mui/material";
import * as React from "react";
import UserParticipantPanel from "../../../users/userDetail/participants/userParticipantsPanel";

interface PartnerParticipantsProps {
    imKey: string;
    email: string;
    fullName: string;
}

const PartnerParticipants: React.FC<PartnerParticipantsProps> = (props) => {
    return (
        <Box sx={{ width: 1, height: 1 }}>
            <UserParticipantPanel
                imKey={+props.imKey}
                emailAddress={props.email}
                fullName={props.fullName}
                fromResources={true}
            />
        </Box>
    );
};

export default PartnerParticipants;
