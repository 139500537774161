import * as React from "react";
import { Box, Grid, Stack, TextField } from "@mui/material";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import FlexGrid from "../../../../layouts/flexGrid";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import {
    useSaveFiscalChangesMutation,
    useSearchAccountExecMutation,
} from "../../../../services/cclTokenedCustomerHierarchyApi";
import { AccountCEResponse, AccountExecRequest } from "../../../../services/types/clientTypes";
import { useDispatch } from "react-redux";
import { updateClientAccountExecSearchParams } from "../../../../app/slices/clientAccountExecSearchConfigSlice";
import AccountManagerDataGridPro from "./accountManagerDataGridPro";
import ComponentLoader from "../../../../components/common/componentLoader";
import EditAccountManagerDialog from "./editAccountManagerDialog";

export interface AccountSearchListParams {
    OnSelectedAccountExecData: (selectAccountExecData: AccountCEResponse[] | undefined) => void;
}

export const AccountSearchList: React.FC<AccountSearchListParams> = (props) => {
    // redux state
    const dispatch = useDispatch();
    // const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
    const [showEditAccountManagerDialog, setShowEditAccountManagerDialog] =
        React.useState<boolean>(false);
    const [showSearchToBroad, setShowSearchToBroad] = React.useState<boolean>(false);
    const [searchParentKey, setSearchParentKey] = React.useState<number>();
    const [searchFiscalKey, setSearchFiscalKey] = React.useState<number>();
    const [searchClientName, setSearchClientName] = React.useState<string>("");
    const [searchAccountOwnerName, setSearchAccountOwnerName] = React.useState<string>("");
    //const [searchWild, setSearchWild] = React.useState<boolean>(true);/*  */
    const [searchAccountExec, { data: accountExecData, isLoading, isSuccess }] =
        useSearchAccountExecMutation();
    const [saveFiscalChange] = useSaveFiscalChangesMutation();
    // const [searchResultString, setSearchResultString] = React.useState<string>("");
    const [accountManagerEditData, setAccountManagerEditData] =
        React.useState<AccountCEResponse[]>();
    const [searchPayload, setSearchPayload] = React.useState<AccountExecRequest>({
        fiscalkey: 0,
        parentKey: 0,
        account_name: "",
        clientName: "",
        isSearchWild: true,
    });

    React.useEffect(() => {
        if (isSuccess && accountExecData && accountExecData.length >= 30000)
            setShowSearchToBroad(true);
    }, [accountExecData, isSuccess]);

    React.useEffect(() => {
        if (
            searchPayload.account_name &&
            searchPayload.account_name.length === 0 &&
            searchPayload.parentKey &&
            searchPayload.parentKey <= 0 &&
            searchPayload.clientName &&
            searchPayload.clientName.length === 0 &&
            searchPayload.fiscalkey &&
            searchPayload.fiscalkey <= 0
        )
            return;

        searchAccountExec(searchPayload);
        dispatch(
            updateClientAccountExecSearchParams({
                AccountOwnerName: searchPayload.account_name ?? "",
                ParentKey: searchPayload.parentKey ?? 0,
                IsSearchWild: searchPayload.isSearchWild,
                ClientName: searchPayload.clientName ?? "",
                FiscalKey: searchPayload.fiscalkey ?? 0,
            })
        );

        /*    var an = searchPayload.account_name;
        var pk = searchPayload.parentKey;
        var c = searchPayload.clientName;
        var fk = searchPayload.fiscalkey;
        let msg =`results for search` /* && `${(pc === true)? 'Parent' : 'Child'}`;
        setSearchResultString(msg); */
        // eslint-disable-next-line
    }, [searchPayload]);

    const getAccountExecDetails = async () => {
        setSearchPayload({
            account_name: searchAccountOwnerName,
            parentKey: searchParentKey ?? 0,
            fiscalkey: searchFiscalKey ?? 0,
            clientName: searchClientName,
            isSearchWild: true,
        });
    };

    const OnHandleEditSave = (
        mapperId: number,
        fiscalYear: number,
        newClientIndicator: number,
        effectiveStartDate: Date | null | undefined,
        effectiveEndDate: Date | null | undefined
    ) => {
        setShowEditAccountManagerDialog(false);
        const OldAccountExeEdit = accountManagerEditData?.filter((a) => a.mapperID === mapperId)[0];
        saveFiscalChange({
            accExe: mapperId,
            fiscal: fiscalYear,
            eStarteDate: effectiveStartDate ?? null,
            eEndDate: effectiveEndDate ?? null,
            parentKey: OldAccountExeEdit?.parentKey ?? 0,
            clientInd: newClientIndicator,
            oldFY: OldAccountExeEdit?.fiscalYear ?? 0,
            oldStartDate: OldAccountExeEdit?.effectiveStartDate ?? null,
            oldEndDate: OldAccountExeEdit?.effectiveEndDate ?? null,
            account_owner: "",
            parent_name: "",
        });
    };

    const handleSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            evt.preventDefault();
            getAccountExecDetails();
        }
    };

    const OnSelectDataList = (mapperId: number) => {
        props.OnSelectedAccountExecData(accountExecData?.filter((a) => a.mapperID === mapperId));
    };

    const OnAccountEdit = (mapperId: number) => {
        setShowEditAccountManagerDialog(true);
        setAccountManagerEditData(accountExecData?.filter((a) => a.mapperID === mapperId));
    };

    const OnAccountDelete = (mapperId: number) => {
        props.OnSelectedAccountExecData(accountExecData?.filter((a) => a.mapperID === mapperId));
    };
    return (
        <Stack height={1} width={1}>
            {false && <ComponentLoader msg={"Updating"} />}
            <CclErrorDialog
                open={showSearchToBroad}
                title={"Too Many Results"}
                msg={
                    "There are too many results for this search. Consider adjusting your search parameters and searching again."
                }
                onOk={() => setShowSearchToBroad(false)}
            />
            <EditAccountManagerDialog
                open={showEditAccountManagerDialog}
                onOk={OnHandleEditSave}
                onCancel={() => setShowEditAccountManagerDialog(false)}
                AccountCEResponseSelect={accountManagerEditData ?? []}
            />
            <Grid item xs={12} md={6}>
                <Box
                    sx={{
                        m: 2,
                        width: 1,

                    }}
                >
                    <Grid container component="form" sx={{alignItems: "center"}}>
                        <Grid item xs={6} md={2.5} sx={{ p: 1 }}>
                            <TextField
                                value={searchFiscalKey}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-email-search"
                                label="Enter Fiscal key"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    let number = e.target.value.replace(/[^0-9]/g, "");
                                    if (number === "") {
                                        number = "0";
                                    }
                                    setSearchFiscalKey(
                                        parseInt(number)
                                    );
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2.5} sx={{ p: 1 }}>
                            <TextField
                                value={searchParentKey}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-email-search"
                                label="Enter Parent Key"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    let number = e.target.value.replace(/[^0-9]/g, "");
                                    if (number === "") {
                                        number = "0";
                                    }
                                    setSearchParentKey(
                                        parseInt(number)
                                    );
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2.5} sx={{ p: 1 }}>
                            <TextField
                                value={searchClientName}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-email-search"
                                label="Enter Client Name"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchClientName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2.5} sx={{ p: 1 }}>
                            <TextField
                                value={searchAccountOwnerName}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-email-search"
                                label="Enter Account Owner Name"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchAccountOwnerName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={1} sx={{ p: 1 }}>
                            <CclButton
                                onClick={() => getAccountExecDetails()}
                                aria-label="Search by CHT"
                                sx={{ width: 1, height: 1, maxHeight: "57px", marginLeft: "10px" }}
                                mode={"primary"}                            >
                                Search
                            </CclButton>
                        </Grid>{" "}
                    </Grid>
                </Box>
            </Grid>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    m: 2,
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <FlexGrid>
                    <AccountManagerDataGridPro
                        accountCELists={accountExecData ?? []}
                        isLoading={isLoading}
                        showToolbar={!isLoading}
                        OnSelectDetails={OnSelectDataList}
                        OnAccountExecEdit={OnAccountEdit}
                        OnAccountExecDelete={OnAccountDelete}
                    />
                </FlexGrid>
            </Box>
        </Stack>
    );
};

export default AccountSearchList;
