import { CclInfoTableRowTemplate } from "../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblSystemTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "systemId",
        fieldType: "string",
        editable: false,
        label: "ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "systemName",
        fieldType: "string",
        editable: true,
        label: "Name",
        required: true,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.TextInput,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
