import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface ClientCESystemUserSearchConfigState {
    CCL_Departmentname: string;
    BusinessUnitIdName: string;
    FirstName: string;
    LastName: string;
    Email: string;
};

// Define the initial state using that type
const initialState: ClientCESystemUserSearchConfigState = {
    CCL_Departmentname: "",
    BusinessUnitIdName: "",
    FirstName: "",
    LastName: "",
    Email: "",
};

export const ClientCESystemUserSearchConfigSlice = createSlice({
  name: 'clientAccountExecSearchListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateClientCESystemUserSearchParams: (state, action: PayloadAction<ClientCESystemUserSearchConfigState>) => {
      state.CCL_Departmentname = action.payload.CCL_Departmentname;
      state.BusinessUnitIdName = action.payload.BusinessUnitIdName;
      state.FirstName = action.payload.FirstName;
      state.LastName = action.payload.LastName;
      state.Email = action.payload.Email;
    }
  }
});

export const { updateClientCESystemUserSearchParams } = ClientCESystemUserSearchConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCESystemUserSearchListConfig = (state: RootState) => state;
export default ClientCESystemUserSearchConfigSlice.reducer;
