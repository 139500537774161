import * as React from "react";

import { BioPDF, Biography } from "../../../services/types/bioApiTypes";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { updateBiosGridState } from "../../../app/slices/resourceBioSearchGridSlice";
import CclPersistentDataGrid from "../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { useSelector } from "react-redux";
import { AccessEventIndividualDetails } from "../../../services/types/accessEventTypes";
import { useDownloadPDFMutation } from "../../../services/cclTokenedEnterpriseCoachingBiographyApi";
import CclCommonLink from "../../../components/common/cclCommonLink";
import CclAlertSnackbar from "../../../components/common/cclAlertSnackbar";
import { ResourceDocument } from "../../../services/types/search.service.types";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

function getDateFromTimestamp(params: any) {
    return new Date(params.row.modifiedDate).toLocaleDateString();
}

export interface BioSearchListProps {
    resources: Biography[];
    resourceData: ResourceDocument[];
    loading: boolean;
}

export const BioSearchDataGridPro: React.FC<BioSearchListProps> = (props) => {
    const [resources, setResources] = React.useState<Biography[]>(props.resources);
    const [rowsSelected, setRowsSelected] = React.useState<Biography[]>([]);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [downloadPDF, { isLoading: downloadPDFIsLoading, isError }] = useDownloadPDFMutation();
    const { logEvent } = useLogAccessEvent();

    const makeFileName = (firstName: string, lastName: string) => {
        const now = new Date();
        let monthInt = now.getMonth() + 1;
        let month = monthInt > 9 ? monthInt.toString() : "0" + monthInt.toString();
        let date = now.getDate() > 9 ? now.getDate().toString() : "0" + now.getDate().toString();
        let fileName =
            firstName + "_" + lastName + "_" + now.getFullYear() + "_" + month + "_" + date;
        fileName = fileName.replaceAll(".", "");
        return fileName;
    };

    const downloadSinglePDF = (
        pKey: number,
        imKey: number,
        firstName: string,
        lastName: string,
        email: string
    ) => {
        const downloadParams: BioPDF = {
            pKey: pKey,
            pdfName: makeFileName(firstName ? firstName : "", lastName ? lastName : ""),
        };
        downloadPDF(downloadParams)
            .unwrap()
            .then(() => {
                if (!showSnackbar) {
                    setShowSnackbar(true);
                }
                const evtData: AccessEventIndividualDetails = {
                    imKey: imKey.toString(),
                    email: email,
                };
                logEvent("BioDownloaded", evtData);
            });
    };

    const handleDownload = (rows?: any[]) => {
        if (!rows || rows.length === 0) return;

        rows.forEach((row) => {
            downloadSinglePDF(row.pKey, row.imKey, row.firstName, row.lastName, row.email);
        });
    };

    const getResourceRegion = (params: any) => {
        let region = props.resourceData.find((x) => x.pkey === params.row.pKey)?.campus;
        return region ? region : "";
    }

    const getResourceReportingLocation = (params: any) => {
        let reportingLocation = props.resourceData.find((x) => x.pkey === params.row.pKey)?.reportinglocation;
        return reportingLocation ? reportingLocation : "";
    }

    React.useLayoutEffect(() => {
        setResources(props.resources);
    }, [props.resources]);

    const COLUMNS = [
        {
            field: "name",
            headerName: "Resource",
            type: "string",
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/resource/${params.row.pKey}/0`}
                />
            ),
            flex: 0.8,
        },
        {
            field: "email",
            headerName: "Email",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.row.email}
                    keyVal={params.row.email}
                    to={`mailto:${params.row.email}`}
                    type={"email"}
                />
            ),
            type: "string",
            flex: 1.0,
        },
        {
            field: "title",
            headerName: "Title",
            type: "string",
            flex: 0.7,
        },
        {
            field: "region",
            headerName: "Region",
            type: "string",
            valueGetter: getResourceRegion,
            flex: 0.5,
        },
        {
            field: "reportingLocation",
            headerName: "Reporting Location",
            valueGetter: getResourceReportingLocation,
            type: "string",
            flex: 0.5,
        },
        {
            field: "modifiedDate",
            headerName: "Last Modified",
            valueGetter: getDateFromTimestamp,
            flex: 0.4,
        },
        {
            field: "status",
            headerName: "D365 Status",
            flex: 0.3,
        },
    ];

    const stateSelector = useSelector((state: any) => state.biosSearchTableState);

    const updateSelectedRows = (rows: any[] | undefined) => {
        if (rows) {
            setRowsSelected(rows);
        } else {
            setRowsSelected([]);
        }
    };

    return (
        <React.Fragment>
            <CclAlertSnackbar 
                open={showSnackbar}
                severity={isError ? "error" : "success"}
                message={isError ? "Download Failed" : "Download Completed"}
                onClose={() => {setShowSnackbar(false)}}
            />
            <CclPersistentDataGrid
                columns={COLUMNS}
                data={resources}
                loading={props.loading}
                stateSelector={stateSelector}
                userPreferenceId={13}
                getRowIdFn={(row: Biography) => row.id}
                actionCreator={updateBiosGridState}
                initState={JSON.stringify({
                    sorting: { sortModel: [{ field: "name", sort: "asc" }] },
                    filter: {
                        filterModel: {
                            items: [
                                {
                                    id: 1,
                                    columnField: "status",
                                    operatorValue: "startsWith",
                                    value: "active",
                                },
                            ],
                        },
                    },
                })}
                miscButtons={[
                    {
                        id: "download-reports-button",
                        caption: "Download Bio(s)",
                        onClick: handleDownload,
                        returnSelected: true,
                        disable: rowsSelected.length === 0,
                        loadingBtn: true,
                        isLoading: downloadPDFIsLoading,
                        restricted: false,
                    },
                ]}
                hideSettingsButtons={true}
                hideFooter={false}
                showCheckboxes={true}
                onSelectedRowsChange={(rows: any[] | undefined) => updateSelectedRows(rows)}
            />
        </React.Fragment>
    );
};

//Add filter model back into this

export default BioSearchDataGridPro;
