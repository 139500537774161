import * as React from "react";
import {
    Box,
    FormControlLabel,
    Grid,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import FlexGrid from "../../../../layouts/flexGrid";
import HomeIndustriesDataGridPro from "./homeIndustriesDataGridPro";
import {
    useGetAddressListMutation,
    useGetOrgSizeListQuery,
    useGetSectorListQuery,
    useSaveOrgSizeDataMutation,
    useSaveSectorDataMutation,
    useSearchHomeIndustrieMutation,
} from "../../../../services/cclTokenedCustomerHierarchyApi";
import {
    HomeIndustriesList,
    HomeIndustriesRequest,
    SaveOrgSizeRequest,
    SaveSectorRequest,
} from "../../../../services/types/clientTypes";
import { updateClientHomeSearchParams } from "../../../../app/slices/clientHomeSearchConfigSlice";
import { useDispatch } from "react-redux";
import ViewAddressDialog from "./viewAddressDialog";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export type HomeIndustriesParentChildParams = {
    OnSelectRowData: (ParentChildSelectData: HomeIndustriesList[]) => void;
    updateParentChild: (value: boolean) => void;
};

export const HomeIndustriesParentChild: React.FC<HomeIndustriesParentChildParams> = (props) => {
    // redux state
    const dispatch = useDispatch();
    const { data: sectorList } = useGetSectorListQuery();
    const { data: OrgSizeList } = useGetOrgSizeListQuery();
    const [openAddressDialog, setOpenAddressDialog] = React.useState<boolean>(false);
    const [showSearchToBroad, setShowSearchToBroad] = React.useState<boolean>(false);
    const [selectedChildName, setSelectedChildName] = React.useState<string>("");

    const [searchParentName, setSearchParentName] = React.useState<string>("");
    const [searchParentKey, setSearchParentKey] = React.useState<number>();
    const [searchChildName, setSearchChildName] = React.useState<string>("");
    const [searchChildKey, setSearchChildKey] = React.useState<number>();
    const [searchChildParent, setSearchChildParent] = React.useState<boolean>(true);
    const [searchDelete, setSearchDelete] = React.useState<boolean>(false);

    const [searchUsers, { data: homeIndustrieData, isLoading }] = useSearchHomeIndustrieMutation();

    const [getAddressbyCMKey, { data: addressDataList, isLoading: isLoadingaddress }] =
        useGetAddressListMutation();
    
    const [newHomeIndustrieData, setNewHomeIndustrieData] = React.useState<HomeIndustriesList[]>();

    const [saveSectorData] = useSaveSectorDataMutation();
    const [saveOrgSizeData] = useSaveOrgSizeDataMutation();

    const [searchPayload, setSearchPayload] = React.useState<HomeIndustriesRequest>({
        parentName: "",
        parentKey: 0,
        childName: "",
        childKey: 0,
        isParent: false,
        isChild: false,
        isSearchWild: true,
        isSearchDelete: false,
    });

    React.useEffect(() => {
        props.updateParentChild(searchChildParent);
        //eslint-disable-next-line
    }, [searchChildParent]);

    React.useEffect(() => {
        setNewHomeIndustrieData(homeIndustrieData ?? []);
    }, [homeIndustrieData]);

    React.useEffect(() => {
        setNewHomeIndustrieData([]);
        if (
            searchPayload.parentName &&
            searchPayload.parentName.length === 0 &&
            searchPayload.parentKey &&
            searchPayload.parentKey <= 0 &&
            searchPayload.childName &&
            searchPayload.childName.length === 0 &&
            searchPayload.childKey &&
            searchPayload.childKey <= 0 &&
            searchPayload.isSearchDelete === false
        )
            return;

        searchUsers(searchPayload);
        dispatch(
            updateClientHomeSearchParams({
                ParentName: searchPayload.parentName ?? "",
                ParentKey: searchPayload.parentKey ?? 0,
                IsSearchWild: searchPayload.isSearchWild,
                ChildName: searchPayload.childName ?? "",
                ChildKey: searchPayload.childKey ?? 0,
                IsParent: searchPayload.isParent,
                IsChild: searchPayload.isChild,
                IsSearchDelete: searchPayload.isSearchDelete,
            })
        );
        
        // eslint-disable-next-line
    }, [searchPayload]);

    const getHomeIndustryList = async () => {
        setSearchPayload({
            parentName: searchParentName,
            parentKey: searchParentKey ?? 0,
            childKey: searchChildKey ?? 0,
            childName: searchChildName,
            isChild: !searchChildParent,
            isParent: searchChildParent,
            isSearchDelete: searchDelete,
            isSearchWild: true,
        });
    };

    const handleSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            evt.preventDefault();
            getHomeIndustryList();
        }
    };

    const OnSelectDataList = (parentKey: number, childKey: number) => {
        var parentChildDataList = homeIndustrieData?.filter(
            (a) => a.parentKey === parentKey && a.childKey === childKey
        );
        props.OnSelectRowData(parentChildDataList ?? []);
    };

    const OnAddressList = (childKey: number, childName: string) => {
        getAddressbyCMKey(childKey);
        setSelectedChildName(childName);
        setOpenAddressDialog(true);
    };

    const OnSectorSizeChange = (childKey: number, SectorName: string) => {
        var Sectors = sectorList?.filter((s) => s.sectorName === SectorName);
        const SectorKey = Sectors && Sectors?.length > 0 ? Sectors[0].sectorKey : 0;
        const saveSectorPayload: SaveSectorRequest = {
            cmKey: childKey.toString(),
            sectorKey: SectorKey,
        };
        saveSectorData(saveSectorPayload);
        const updateIndustriesData = newHomeIndustrieData?.map((a) => {
            if (a.childKey !== childKey) {
                return a;
            } else {
                return {
                    ...a,
                    sectorName: SectorName,
                };
            }
        });
        setNewHomeIndustrieData(updateIndustriesData);
    };

    const OnOrgSizeChange = (childKey: number, OrgSizeName: string) => {
        var orgSizes = OrgSizeList?.filter((o) => o.orgSize === OrgSizeName);
        const orgSizeKey = orgSizes && orgSizes?.length > 0 ? orgSizes[0].orgSizeKey : 0;
        const saveOrgSizePayload: SaveOrgSizeRequest = {
            cmKey: childKey.toString(),
            orgSizeKey: orgSizeKey,
        };
        saveOrgSizeData(saveOrgSizePayload);
        const updateIndustriesData = newHomeIndustrieData?.map((a) => {
            if (a.childKey !== childKey) {
                return a;
            } else {
                return {
                    ...a,
                    orgSize: OrgSizeName,
                };
            }
        });
        setNewHomeIndustrieData(updateIndustriesData);
    };

    const updateParentChild = () => {
        if (searchChildParent) {
            setSearchChildParent(false);
        } else {
            setSearchChildParent(true);
        }
    }

    return (
        <Stack height={1} width={1}>
            <CclErrorDialog
                open={showSearchToBroad}
                title={"Too Many Results"}
                msg={
                    "There are too many results for this search. Consider adjusting your search parameters and searching again."
                }
                onOk={() => setShowSearchToBroad(false)}
            />
            <ViewAddressDialog
                open={openAddressDialog}
                onOk={() => setOpenAddressDialog(false)}
                onCancel={() => setOpenAddressDialog(false)}
                Isloading={isLoadingaddress}
                AddressList={addressDataList ?? []}
                childName={selectedChildName}
            />

            <Stack direction={"row"} sx={{padding: 3}}>
                <Typography 
                    sx={{alignSelf: "center", m: 3}} 
                    variant="h6">
                    Search for
                </Typography>
                <FormControlLabel
                    value="parent"
                    control={
                        <Switch 
                            defaultChecked
                            checked={searchChildParent}
                            onChange={() => updateParentChild()}
                        />
                    }
                    sx={{m:2}}
                    label={<Typography variant="body2">Parent</Typography>}
                />
                <FormControlLabel
                    value="child"
                    control={
                        <Switch 
                            checked={!searchChildParent}
                            onChange={() => updateParentChild()}
                        />}
                    sx={{m:2}}
                    label={<Typography variant="body2">Child</Typography>}
                />
                <FormControlLabel
                    value="Search Deleted"
                    control={
                        <Switch 
                            checked={searchDelete}
                            onChange={(evt) => setSearchDelete(evt.target.checked)}
                        />
                    }
                    sx={{m:2}}
                    label={<Typography variant="body2">Search Deleted</Typography>}
                />
            </Stack>

            <Grid item xs={12} md={5} sx={{padding: 4}}>
                <Box
                    sx={{
                        width: 1,
                    }}
                >
                    <Grid container component="form" sx={{alignItems: "center"}}>
                        <Grid item xs={10} md={3.5} sx={{ p: 1 }}>
                            <TextField
                                value={searchParentName}
                                sx={{ width: 1 }}
                                id="outlined-adornment-email-search"
                                label="Enter Parent Name"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchParentName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} sx={{ p: 1 }}>
                            <TextField
                                value={searchParentKey}
                                sx={{ width: 1 }}
                                id="outlined-adornment-email-search"
                                label="Enter Parent Key"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    let number = e.target.value.replace(/[^0-9]/g, "");
                                    if (number === "") {
                                        number = "0";
                                    }
                                    setSearchParentKey(
                                        parseInt(number)
                                    );
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={10} md={3.5} sx={{ p: 1 }}>
                            <TextField
                                value={searchChildName}
                                sx={{ width: 1 }}
                                id="outlined-adornment-email-search"
                                label="Enter Child Name"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchChildName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} sx={{ p: 1 }}>
                            <TextField
                                value={searchChildKey}
                                sx={{ width: 1 }}
                                id="outlined-adornment-email-search"
                                label="Enter Child Key"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    let number = e.target.value.replace(/[^0-9]/g, "");
                                    if (number === "") {
                                        number = "0";
                                    }
                                    setSearchChildKey(parseInt(number));
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={1} sx={{ p: 1 }}>
                            <CclButton
                                onClick={() => getHomeIndustryList()}
                                aria-label="Search by CHT"
                                sx={{ml: 4}}
                                mode={"primary"} >
                                Search
                            </CclButton>
                        </Grid>{" "}
                    </Grid>
                </Box>
            </Grid>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    m: 2,
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <FlexGrid>
                    <HomeIndustriesDataGridPro
                        homeIdustriesList={newHomeIndustrieData ?? []}
                        orgSizeList={OrgSizeList ?? []}
                        sectorList={sectorList ?? []}
                        isLoading={isLoading}
                        showToolbar={!isLoading}
                        OnSelectDetails={OnSelectDataList}
                        OnOrgChange={OnOrgSizeChange}
                        OnSectorChange={OnSectorSizeChange}
                        OnAddressSelect={OnAddressList}
                        parentOrChild={searchChildParent}
                    />
                </FlexGrid>
            </Box>
        </Stack>
    );
};

export default HomeIndustriesParentChild;
