import React from "react";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import {
    useDeleteRaterByIdMutation,
    useGetSessionRatersByEsKeyMutation,
    useGetRaterDetailsByParticipantIDMutation,
    useReSendRaterInvitationMutation,
    useReSendRaterRemindersMutation,
    useUpdateRaterSwitchMutation,
    useAddRatersMutation,
} from "../../../../services/cclTokenedEnterpriseRaterApi";
import {
    AddRaterRequest,
    DeleteRaterRequest,
    EmailRequest,
    EmailType,
    RaterDetails,
    RaterSwitchRequest,
} from "../../../../services/types/assessmentAPITypes";
import ComponentLoader from "../../../../components/common/componentLoader";
import { AlertColor, Grid, Stack } from "@mui/material";
import RaterManagementDataGridPro from "./raterManagementDataGridPro";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import {
    AccessEventRegistrationDetails,
    AccessEventSessionDetails,
} from "../../../../services/types/accessEventTypes";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import EditRaterTypesDrawer from "./editRaterTypesDrawer";
import AddRaterDrawer from "./addRaterDrawer";
import { Participant } from "../../../../services/types/enterpriseParticipantApiTypes";

interface RaterManagementPanelProps {
    sessionKey: number;
    programCode: string;
    registration?: Participant;
    readOnly?: boolean;
}

const RaterManagementPanel: React.FC<RaterManagementPanelProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [showAddRaters, setShowAddRaters] = React.useState<boolean>(false);
    const [showRaterTypeDialog, setShowRaterTypeDialog] = React.useState<boolean>(false);
    const [selectedRaterDetail, setSelectedRaterDetail] = React.useState<RaterDetails | null>(null);
    const [loadingButtonId, setLoadingButtonId] = React.useState<string>("");
    const [raterDetailsList, setRaterDetailsList] = React.useState<RaterDetails[]>([]);

    const [
        searchSessionRaters,
        { data: sessionRaterDetailsList, isLoading: sessionRatersIsLoading },
    ] = useGetSessionRatersByEsKeyMutation();
    const [
        searchParticipantRaters,
        { data: participantRaterDetailsList, isLoading: participantRatersIsLoading },
    ] = useGetRaterDetailsByParticipantIDMutation();
    const [updateRaterType] = useUpdateRaterSwitchMutation();
    const [deleteRater] = useDeleteRaterByIdMutation();
    const [reSendReminders] = useReSendRaterRemindersMutation();
    const [reSendInvitation] = useReSendRaterInvitationMutation();
    const [addRater, { isLoading: addInProgress }] = useAddRatersMutation();

    const { logEvent } = useLogAccessEvent();
    const claimsService = new UserClaimsService();
    const isUserRaterManager = claimsService.IsUserInAppRole(3);
    const logData: AccessEventSessionDetails | AccessEventRegistrationDetails =
        props.registration !== undefined
            ? {
                  esiKey: props.registration.esiKey.toString(),
                  imKey: props.registration.imKey.toString(),
                  email: props.registration.emailAddress,
              }
            : {
                  sessionID: props.sessionKey.toString(),
                  projectCode: props.programCode,
              };

    React.useEffect(() => {
        if (props.registration !== undefined) {
            searchParticipantRaters(props.registration.esiKey);
        } else {
            searchSessionRaters(props.sessionKey);
        }
        //eslint-disable-next-line
    }, [props.sessionKey, props.registration]);

    React.useEffect(() => {
        if (sessionRaterDetailsList != null) {
            setRaterDetailsList(sessionRaterDetailsList);
        } else if (participantRaterDetailsList != null) {
            setRaterDetailsList(participantRaterDetailsList);
        } else {
            setRaterDetailsList([]);
        }
    }, [sessionRaterDetailsList, participantRaterDetailsList]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleButtonPress = (buttonName: string, ids: number[]) => {
        switch (buttonName) {
            case "add":
                setShowAddRaters(true);
                break;
            case "edit":
                if (ids != null && ids.length === 1 && raterDetailsList != null) {
                    const raterDetail = raterDetailsList.find((r) => r.rM_Key === ids[0]);
                    if (raterDetail) {
                        setSelectedRaterDetail(raterDetail);
                        setShowRaterTypeDialog(true);
                        return;
                    }
                }
                ShowSnackbarElement("Update Rater Not Possible", "error");
                break;
            case "delete":
                if (ids != null && ids.length === 1 && raterDetailsList != null) {
                    handleDelete(ids[0]);
                    return;
                }
                ShowSnackbarElement("Delete Rater Not Possible", "error");
                break;
            case "invitation":
                handleResendInvitations(ids);
                break;
            case "reminder":
                handleResendReminders(ids);
                break;
        }
    };

    const handleResendReminders = (ids: number[]) => {
        if (
            raterDetailsList == null ||
            raterDetailsList.length <= 0 ||
            ids == null ||
            ids.length <= 0
        ) {
            ShowSnackbarElement("Reminder Resend Not Possible", "error");
            return;
        }
        setLoadingButtonId("reminder");
        var newEmailrequest: EmailRequest[] = [];
        ids.forEach((i) => {
            const EmailList: EmailRequest = {
                EmailDateToSend: new Date(),
                EmailTypeId: EmailType.RaterReminder,
                ParticipantId:
                    raterDetailsList?.filter((r) => r.rM_Key === i)[0].participant_ESI_Key ?? "",
                RaterId: parseInt(i.toString()),
            };
            newEmailrequest.push(EmailList);
        });
        reSendReminders(newEmailrequest)
            .unwrap()
            .then(() => {
                logEvent("RaterRemindersResent", logData);
                ShowSnackbarElement("Reminder(s) Resent", "success");
                setLoadingButtonId("");
            })
            .catch(() => {
                ShowSnackbarElement("Reminder Resend Failed", "error");
                setLoadingButtonId("");
            });
    };

    const handleResendInvitations = (ids: number[]) => {
        if (
            raterDetailsList == null ||
            raterDetailsList.length <= 0 ||
            ids == null ||
            ids.length <= 0
        ) {
            ShowSnackbarElement("Invitation Resend Not Possible", "error");
            return;
        }
        setLoadingButtonId("invitation");
        var newEmailrequest: EmailRequest[] = [];
        ids.forEach((i) => {
            const EmailList: EmailRequest = {
                EmailDateToSend: new Date(),
                EmailTypeId: EmailType.ProductRaterInvitation,
                ParticipantId:
                    raterDetailsList?.filter((r) => r.rM_Key === i)[0].participant_ESI_Key ?? "",
                RaterId: i,
            };
            newEmailrequest.push(EmailList);
        });
        reSendInvitation(newEmailrequest)
            .unwrap()
            .then(() => {
                logEvent("RaterInvitationsResent", logData);
                ShowSnackbarElement("Invitation(s) Resent", "success");
                setLoadingButtonId("");
            })
            .catch(() => {
                ShowSnackbarElement("Invitation Resend Failed", "error");
                setLoadingButtonId("");
            });
    };

    const handleEditRater = (RMKey: number, RaterTypeKey: number) => {
        const raterObj = raterDetailsList?.filter((r) => r.rM_Key === RMKey)[0];
        const request: RaterSwitchRequest = {
            formActualID: raterObj?.formActualKey ?? 0,
            raterTypeKey: RaterTypeKey,
            rmkey: RMKey,
            esiKey: raterObj?.participant_ESI_Key ? +raterObj?.participant_ESI_Key : 0,
            esKey: props.sessionKey,
        };
        updateRaterType(request)
            .unwrap()
            .then(() => {
                logEvent("RaterTypeChanged", logData);
                ShowSnackbarElement("Rater Updated", "success");
                setLoadingButtonId("");
                updateRaters();
                setShowRaterTypeDialog(false);
            })
            .catch(() => {
                ShowSnackbarElement("Rater Update Failed", "error");
                setLoadingButtonId("");
                setShowRaterTypeDialog(false);
            });
    };

    const handleDelete = (RMKey: number) => {
        setLoadingButtonId("delete");
        const raterObj = raterDetailsList?.filter((r) => r.rM_Key === RMKey)[0];
        const request: DeleteRaterRequest = {
            rmkey: RMKey,
            esiKey: raterObj?.participant_ESI_Key ? +raterObj?.participant_ESI_Key : 0,
            esKey: props.sessionKey,
        };
        deleteRater(request)
            .unwrap()
            .then(() => {
                logEvent("RaterDeleted", logData);
                ShowSnackbarElement("Rater Deleted", "success");
                setLoadingButtonId("");
                updateRaters();
            })
            .catch(() => {
                ShowSnackbarElement("Rater Deletion Failed", "error");
                setLoadingButtonId("");
            });
    };

    const handleAddRater = (request: AddRaterRequest) => {
        var addRatersList: AddRaterRequest[] = [];
        addRatersList.push(request);
        addRater(addRatersList)
            .unwrap()
            .then(() => {
                logEvent("RaterAdded", logData);
                ShowSnackbarElement("Rater Added", "success");
                setShowAddRaters(false);
                updateRaters();
            })
            .catch(() => {
                ShowSnackbarElement("Rater Addition Failed", "error");
            });
    };

    const updateRaters = () => {
        if (props.registration != null) {
            searchParticipantRaters(props.registration.esiKey);
        } else {
            searchSessionRaters(props.sessionKey);
        }
    };

    if (participantRatersIsLoading || sessionRatersIsLoading) {
        return <ComponentLoader msg="Loading" />;
    }

    return (
        <Stack height={1} width={1}>
            {showAddRaters ? (
                <AddRaterDrawer
                    raterList={raterDetailsList ?? []}
                    esKey={props.sessionKey}
                    open={true}
                    onAdd={handleAddRater}
                    onCancel={() => setShowAddRaters(false)}
                    registration={props.registration}
                    isAdding={addInProgress}
                />
            ) : null}
            {showRaterTypeDialog ? (
                <EditRaterTypesDrawer
                    open={true}
                    onOk={(rMKey, raterTypeId) => handleEditRater(rMKey, raterTypeId)}
                    raterDetail={selectedRaterDetail}
                    onCancel={() => setShowRaterTypeDialog(false)}
                />
            ) : null}
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <Grid container height={1} spacing={2}>
                <Grid item xs={12}>
                    <RaterManagementDataGridPro
                        ratersDetailsList={raterDetailsList ?? []}
                        programCode={props.programCode}
                        showToolbar={true}
                        isSessionRater={props.registration == null}
                        isUserRaterManager={isUserRaterManager}
                        readOnly={props.readOnly}
                        isLoading={participantRatersIsLoading || sessionRatersIsLoading}
                        handleButtonClick={handleButtonPress}
                        loadingButtonId={loadingButtonId}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default RaterManagementPanel;
