import * as React from "react";
import { TextFieldProps, TextField, Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CclButton from "../cclButtons/cclButton";
import ReplayIcon from "@mui/icons-material/Replay";

interface CclTextOrDateSearchBarProps {
    initialTextFieldSearchTerm: string;
    textFieldSearchLabel: string;
    initialStartDate: Date | null;
    initialEndDate: Date | null;
    startDateLabel: string;
    endDateLabel: string;
    executeSearch: (text: string, startDate: Date | null, endDate: Date | null) => void;
    errorStateChange?: (error: boolean) => void;
    allowNullDates?: boolean;
    clearSearchTrigger?: () => void;
    showResetButton?: boolean;
}

const CclTextOrDateSearchBar: React.FC<CclTextOrDateSearchBarProps> = (props) => {
    const [lastFieldChanged, setLastFieldChanged] = React.useState<1 | 2 | 3>(1);
    const [textFieldSearchString, setTextFieldSearchString] = React.useState<string>(
        props.initialTextFieldSearchTerm ?? ""
    );

    const [startDate, setStartDate] = React.useState<Date | null>(
        props.initialStartDate ? new Date(props.initialStartDate) : null
    );
    const [endDate, setEndDate] = React.useState<Date | null>(
        props.initialEndDate ? new Date(props.initialEndDate) : null
    );

    React.useEffect(() => {
        if (props.initialTextFieldSearchTerm !== "") {
            setLastFieldChanged(1);
        } else if (props.initialStartDate != null && props.initialEndDate != null) {
            setLastFieldChanged(3);
        }
    }, [props.initialTextFieldSearchTerm, props.initialStartDate, props.initialEndDate]);

    const searchInputSx = {
        "& .MuiOutlinedInput-input": {
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
        flexGrow: 1,
    };

    const enableSearchButton = (): boolean => {
        if (lastFieldChanged === 1) {
            return textFieldSearchString !== "";
        } else {
            return endDate != null && startDate != null;
        }
    };

    const handleSearchButtonClick = () => {
        switch (lastFieldChanged) {
            case 1: {
                if (textFieldSearchString !== "") {
                    props.executeSearch(textFieldSearchString, null, null);
                    setStartDate(null);
                    setEndDate(null);
                }
                break;
            }
            case 2:
            case 3: {
                if (endDate != null && startDate != null) {
                    props.executeSearch("", startDate, endDate);
                    setTextFieldSearchString("");
                }
                break;
            }
        }
    };

    const handleTextSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            evt.preventDefault();
            props.executeSearch(textFieldSearchString, null, null);
            setStartDate(null);
            setEndDate(null);
        }
    };

    const handleDateSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            if (endDate != null && startDate != null) {
                evt.preventDefault();
                props.executeSearch("", startDate, endDate);
                setTextFieldSearchString("");
            }
        }
    };

    const clearSearchArguments = () => {
        setTextFieldSearchString("");
        setStartDate(null);
        setEndDate(null);
        if (props.clearSearchTrigger) {
            props.clearSearchTrigger();
        }
    };

    return (
        <Box
            gap={2}
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <TextField
                value={textFieldSearchString === "*" ? "" : textFieldSearchString}
                sx={searchInputSx}
                id="outlined-adornment-keyword-search-field"
                label={props.textFieldSearchLabel ?? "Text Field Search Term"}
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                    e.preventDefault();
                    setLastFieldChanged(1);
                    setTextFieldSearchString(e.target.value);
                }}
                onKeyDown={(e) => handleTextSearchKeyPress(e)}
            />
            <Typography sx={{ color: "text.secondary" }}>Or</Typography>
            <DatePicker
                label={props.startDateLabel ?? "Start Date"}
                value={startDate}
                onChange={(newValue: Date | null) => {
                    setLastFieldChanged(2);
                    setStartDate(newValue);
                }}
                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                    params.fullWidth = true;
                    return (
                        <TextField
                            {...params}
                            onKeyDown={(e) => handleDateSearchKeyPress(e)}
                            sx={searchInputSx}
                        />
                    );
                }}
            />
            <DatePicker
                label={props.endDateLabel ?? "End Date"}
                value={endDate}
                onChange={(newValue: Date | null) => {
                    setLastFieldChanged(3);
                    setEndDate(newValue);
                }}
                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                    params.fullWidth = true;
                    return (
                        <TextField
                            {...params}
                            onKeyDown={(e) => handleDateSearchKeyPress(e)}
                            sx={searchInputSx}
                        />
                    );
                }}
            />

            {props.showResetButton && (
                <CclButton
                    mode="secondary"
                    sx={{
                        borderRadius: "100%",
                        maxWidth: "15px",
                        minWidth: "10px",
                        pl: "20px",
                        pr: "20px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        gap: "6px",
                        padding: "8px 4px",
                    }}
                    onClick={clearSearchArguments}
                >
                    <ReplayIcon fontSize="small" />
                </CclButton>
            )}

            <CclButton
                onClick={handleSearchButtonClick}
                aria-label="Search"
                size="small"
                sx={{ flexShrink: 0, whiteSpace: "nowrap" }}
                disabled={!enableSearchButton()}
                mode={"primary"}
            >
                Search
            </CclButton>
        </Box>
    );
};

export default CclTextOrDateSearchBar;
