import { CclInfoTableRowTemplate } from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const cclInfoTblCalendarEventTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "ceBookableResourceBookingId",
        fieldType: "string",
        editable: false,
        label: "Booking ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "eventTitle",
        fieldType: "string",
        editable: false,
        label: "Title",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "appointmentStatus",
        fieldType: "string",
        editable: false,
        label: "Status",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "bookingType",
        fieldType: "string",
        editable: false,
        label: "Booking Type",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "resourceType",
        fieldType: "string",
        editable: false,
        label: "Resource Type",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Text,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "sessionCode",
        fieldType: "string",
        editable: false,
        label: "Project ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "customer",
        fieldType: "string",
        editable: false,
        label: "Customer",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "projectManager",
        fieldType: "string",
        editable: false,
        label: "Project Manager",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "start",
        fieldType: "Date",
        editable: false,
        label: "Start Date",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "end",
        fieldType: "Date",
        editable: false,
        label: "End Date",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "code",
        fieldType: "string",
        editable: false,
        label: "Code",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "sessionFormat",
        fieldType: "string",
        editable: false,
        label: "Session Format",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "managingLocation",
        fieldType: "string",
        editable: false,
        label: "Managing Location",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "deliveryLocation",
        fieldType: "string",
        editable: false,
        label: "Delivery Location",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "hasCompassSku",
        fieldType: "string",
        editable: false,
        label: "Has Compass",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.YesNoLabel,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
