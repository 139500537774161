import * as React from "react";
import { Autocomplete, Box, Grid, Stack, TextField } from "@mui/material";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import FlexGrid from "../../../../layouts/flexGrid";
import { useSearchCESystemUsersMutation } from "../../../../services/cclTokenedCustomerHierarchyApi";
import { AccountCEResponse, CESystemUserRequest } from "../../../../services/types/clientTypes";
import { useDispatch } from "react-redux";
import { updateClientCESystemUserSearchParams } from "../../../../app/slices/clientCESystemUserSearchConfigSlice";
import CESystemUserDataGridPro from "./cESystemUserDataGridPro";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export interface CESystemUserListParams {
    OnSelectedCESystemUserData: (selectCESystemUserData: AccountCEResponse[] | undefined) => void;
}

export const CESystemUserList: React.FC<CESystemUserListParams> = (props) => {
    // redux state
    const dispatch = useDispatch();
    const [showSearchToBroad, setShowSearchToBroad] = React.useState<boolean>(false);
    const [searchDepartmentName, setSearchDepartmentName] = React.useState<string>();
    const [searchBusinessName, setSearchBusinessName] = React.useState<string>();
    const [searchFirstName, setSearchFirstName] = React.useState<string>("");
    const [searchLastName, setSearchLastName] = React.useState<string>("");
    const [searchEmailName, setSearchEmailName] = React.useState<string>("");
    const [searchCESystemList, { data: cESystemUserData, isLoading, isSuccess }] =
        useSearchCESystemUsersMutation();
    //const [searchResultString, setSearchResultString] = React.useState<string>("");
    const [searchPayload, setSearchPayload] = React.useState<CESystemUserRequest>({
        departmentName: "",
        businessName: "",
        firstName: "",
        lastName: "",
        email: "",
    });
    const DepartmentNames: { val: string; option: string }[] = [
        { val: "0", option: "Sales" },
        { val: "1", option: "Client Services" },
        { val: "2", option: "Marketing" },
        { val: "3", option: "Others" },
    ];

    React.useEffect(() => {
        if (isSuccess && cESystemUserData && cESystemUserData.length >= 30000)
            setShowSearchToBroad(true);
    }, [cESystemUserData, isSuccess]);

    React.useEffect(() => {
        if (
            searchPayload.businessName &&
            searchPayload.businessName.length === 0 &&
            searchPayload.departmentName &&
            searchPayload.departmentName.length === 0 &&
            searchPayload.firstName &&
            searchPayload.firstName.length === 0 &&
            searchPayload.lastName &&
            searchPayload.lastName.length === 0 &&
            searchPayload.lastName &&
            searchPayload.lastName.length === 0
        )
            return;

        searchCESystemList(searchPayload);
        dispatch(
            updateClientCESystemUserSearchParams({
                CCL_Departmentname: searchPayload.departmentName ?? "",
                BusinessUnitIdName: searchPayload.businessName ?? 0,
                FirstName: searchPayload.firstName,
                LastName: searchPayload.lastName ?? "",
                Email: searchPayload.email ?? 0,
            })
        );

        /*   let msg =`results for search` /* && `${(pc === true)? 'Parent' : 'Child'}`;
        setSearchResultString(msg); */
        // eslint-disable-next-line
    }, [searchPayload]);

    const getCESystemUserList = async () => {
        setSearchPayload({
            departmentName: searchDepartmentName ?? "",
            businessName: searchBusinessName ?? "",
            firstName: searchFirstName,
            lastName: searchLastName,
            email: searchEmailName,
        });
    };

    const handleSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            evt.preventDefault();
            getCESystemUserList();
        }
    };

    const OnSelectDataList = (systemUserId: string) => {
        props.OnSelectedCESystemUserData(
            cESystemUserData?.filter((a) => a.systemUserId === systemUserId)
        );
    };

    return (
        <Stack height={1} width={1}>
            <CclErrorDialog
                open={showSearchToBroad}
                title={"Too Many Results"}
                msg={
                    "There are too many results for this search. Consider adjusting your search parameters and searching again."
                }
                onOk={() => setShowSearchToBroad(false)}
            />
            <Grid item xs={12} md={5}>
                <Box
                    sx={{
                        m: 2,
                        width: 1,
                        minHeight: 60,
                    }}
                >
                    <Grid container component="form">
                        <Grid item xs={6} md={2} sx={{ p: 1 }}>
                            <Autocomplete
                                id="tags-standard"
                                autoComplete
                                options={DepartmentNames.map((option) => option.option)}
                                value={searchDepartmentName}
                                onChange={(e: any, newValue: string | null) => {
                                    setSearchDepartmentName(newValue ?? "");
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{ width: 1, maxHeight: "57px" }}
                                        {...params}
                                        variant="standard"
                                        placeholder="Enter Department Name"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} sx={{ p: 1 }}>
                            <TextField
                                value={searchBusinessName}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-business-search"
                                label="Enter Business Name"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchBusinessName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} sx={{ p: 1 }}>
                            <TextField
                                value={searchFirstName}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-name-search"
                                label="Enter First Name"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchFirstName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} sx={{ p: 1 }}>
                            <TextField
                                value={searchLastName}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-name-search"
                                label="Enter Last Name"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchLastName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} sx={{ p: 1 }}>
                            <TextField
                                value={searchEmailName}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-email-search"
                                label="Enter E-Mail"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchEmailName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2} sx={{ p: 1 }}>
                            <CclButton
                                onClick={() => getCESystemUserList()}
                                aria-label="Search by CHT"
                                sx={{ width: 1, height: 1, maxHeight: "57px" }} mode={"primary"}                            >
                                Search
                            </CclButton>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    m: 2,
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <FlexGrid>
                    <CESystemUserDataGridPro
                        accountCELists={cESystemUserData ?? []}
                        isLoading={isLoading}
                        showToolbar={!isLoading}
                        OnSelectDetails={OnSelectDataList}
                    />
                </FlexGrid>
            </Box>
        </Stack>
    );
};

export default CESystemUserList;
