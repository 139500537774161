import * as React from "react";
import ScoringCalendar from "./scoringCalendar";
import ScoringSnapshot from "./scoringSnapshot";
import ScoringReportDownloads from "./scoringReportDownloads";
import { Stack, Typography } from "@mui/material";
import DetailPage from "../../components/common/detailPage/detailPage";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import ProductSalesCard from "./productSales/productSalesCard";
import { useParams } from "react-router-dom";

export type AssessmentsProps = {
    tabIndex: string;
};

export const Assessments: React.FC = (props) => {
    const [actualTab, setActualTab] = React.useState<number>(0);

    const params = useParams<AssessmentsProps>();

    React.useEffect(() => {
        if (params.tabIndex) {
            setActualTab(+params.tabIndex);
        }
    }, [params.tabIndex]);

    const tablist: [string, number, React.ReactNode][] = [
        ["Scoring Calendar", 0, <ScoringCalendar />],
        ["Scoring Status", 1, <ScoringSnapshot />],
        ["Assessments", 2, <ScoringReportDownloads />],
        ["Product Sales", 3, <ProductSalesCard />],
    ];

    const handleTabChangeRequest = (event: React.SyntheticEvent, newValue: number) => {
        setActualTab(newValue);
    };

    const breadcrumbs = (
        <Stack direction={"row"} spacing={3}>
            <SimpleBreadcrumbs>
                <Typography variant="h6" color="text.primary">
                    Assessments
                </Typography>
            </SimpleBreadcrumbs>
        </Stack>
    );

    return (
        <div>
            <DetailPage
                descriptor={"session"}
                breadcrumbs={breadcrumbs}
                tablist={tablist}
                handleTabChange={handleTabChangeRequest}
                activeTab={actualTab}
            />
        </div>
    );
};

export default Assessments;
