import {
    Box,
    InputAdornment,
    TextField,
    TextFieldProps,
} from "@mui/material";
import * as React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { AccountCEResponse } from "../../../../services/types/clientTypes";
import FlexGrid from "../../../../layouts/flexGrid";
import AccountManagerSubDataGridPro from "./accountManagerSubDataGridPro";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export type EditAccountManagerDialogProps = {
    open: boolean;
    onOk: (mapperId:number, fiscalYear: number, newClientIndicator: number, effectiveStartDate: Date | null | undefined, effectiveEndDate: Date | null | undefined) => void;
    onCancel: () => any;
    AccountCEResponseSelect: AccountCEResponse[];
};

const EditAccountManagerDialog: React.FC<EditAccountManagerDialogProps> = (props) => {
    const [title, setTitle] = React.useState<string>("");
    const [fiscalYear, setFiscalYear] = React.useState<number>(0);
    const [newClientIndicator, setNewClientIndicator] = React.useState<number>(0);
    const [effectiveStartDate, setEffectiveStartDate] = React.useState<Date|null>();
    const [effectiveEndDate, setEffectiveEndDate] = React.useState<Date|null>();
    const [mapperID, setMapperId] = React.useState<number>(0);

    React.useEffect(() => {   
        if(props.AccountCEResponseSelect.length >0 && props.open)
        {
            setFiscalYear(props.AccountCEResponseSelect[0].fiscalYear);
            setNewClientIndicator(props.AccountCEResponseSelect[0].newClientIndicator);
            setEffectiveStartDate(props.AccountCEResponseSelect[0].effectiveStartDate);
            setEffectiveEndDate(props.AccountCEResponseSelect[0].effectiveEndDate);
            setTitle(props.AccountCEResponseSelect[0].parentKey.toString() + "-" + props.AccountCEResponseSelect[0].parentName);
            setMapperId(props.AccountCEResponseSelect[0].mapperID)
        }
    }, [props.AccountCEResponseSelect, props.open]);

    const handleOk = () => {        
        props.onOk(mapperID,fiscalYear, newClientIndicator, effectiveStartDate, effectiveEndDate);
    };

    const onStartDateChange = (newdate: Date | null) => {
       setEffectiveStartDate(newdate);
    };
     const onEndDateChange = (newdate: Date | null) => {
       setEffectiveEndDate(newdate);
    };

    const handleKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            handleOk();
        }
    };

    return (
        <CclDrawer 
            title={title} 
            open={props.open} 
            onClose={()=> props.onCancel()}
        >
                <TextField
                    name="FiscalYearValue"
                    placeholder="Fiscal Year"
                    value={fiscalYear}
                    id="outlined-adornment-new-user-first-name"
                    label="Fiscal Year"
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* <AccountBoxIcon /> */}
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        e.preventDefault();
                        setFiscalYear(parseInt(e.target.value));
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    sx={{ mt: 2, mb: 3 }}
                />
                <TextField
                    name="newClientIndicator"
                    placeholder="New Client Indicator"
                    value={newClientIndicator}
                    id="outlined-adornment-new-user-first-name"
                    label="New Client Indicator"
                    variant="outlined"
                    fullWidth={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* <AccountBoxIcon /> */}
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        e.preventDefault();
                        setNewClientIndicator(parseInt(e.target.value));
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    sx={{ mt: 2, mb: 3 }}
                />

                <DatePicker
                label="Effective Start Date"
                value= {effectiveStartDate}
                onChange={onStartDateChange}
                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                    params.fullWidth = true;
                    params.variant = "standard";
                    params.inputProps = { style: { fontSize: 14 } };
                    params.value = effectiveStartDate;
                    return <TextField {...params} />;
                }}
                ></DatePicker>

                <Box sx={{m: 5}} />

                <DatePicker
                label="Effective End Date"
                value={effectiveEndDate}
                onChange={onEndDateChange}
                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                    params.fullWidth = true;
                    params.variant = "standard";
                    params.inputProps = { style: { fontSize: 14 } };
                    params.value = effectiveEndDate;
                    return <TextField {...params} />;
                }}
                ></DatePicker>

                <Box sx={{m: 5}} />

                <FlexGrid>
                    <AccountManagerSubDataGridPro
                        accountCELists={props.AccountCEResponseSelect ?? []}
                        isLoading={false} 
                    />
                </FlexGrid>
                
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        mt: 2,
                    }}
                >
                    <CclButton
                        id="cancel-btn"
                        onClick={() => 
                            props.onCancel()
                        }
                        sx={{
                            mr: 1
                        }} 
                        mode={"primary"}                
                    >
                        Cancel
                    </CclButton>
                    <CclButton 
                        id="ok-btn" 
                        onClick={handleOk} 
                        mode={"primary"}
                    >
                        Add
                    </CclButton>
                </Box>
        </CclDrawer>
    );
};

export default EditAccountManagerDialog;
