import * as React from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { Participant } from "../../../../services/types/enterpriseParticipantApiTypes";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import ScoringStatusPanel from "../../../sessions/sessionDetail/assessments/scoringStatus/scoringStatusPanel";
import { useGetCclParticipantsByCoachImKeyQuery } from "../../../../services/cclTokenedEnterpriseParticipantApi";
import {
    getOptionFromVal,
    SelectOption,
} from "../../../../components/common/selectOptions/selectOption";

interface ParticipantAssessmentStatusSubPanelProps {
    resourceImKey: number;
    selectedParticipantKey: number | undefined;
}

const ParticipantAssessmentStatusSubPanel: React.FC<ParticipantAssessmentStatusSubPanelProps> = (
    props
) => {
    const [selectedPax, setSelectedPax] = React.useState<SelectOption | null>(null);
    const [participantOptions, setParticipantOptions] = React.useState<SelectOption[]>([
        { val: "-1", option: "Select Participant" },
    ]);
    const [showStatus, setShowStatus] = React.useState<boolean>(false);
    const { data: participants } = useGetCclParticipantsByCoachImKeyQuery(props.resourceImKey);

    React.useEffect(() => {
        if (participants) {
            var newParticipantOptions = participants
                .map((p: Participant) => {
                    return { val: p.esiKey.toString(), option: `${p.firstName} ${p.lastName}` };
                })
                .sort((a, b) => a.option.localeCompare(b.option));
            setParticipantOptions(newParticipantOptions);
            if (props.selectedParticipantKey !== undefined && selectedPax == null) {
                var opt = newParticipantOptions.find(
                    (p) => p.val === props.selectedParticipantKey?.toString()
                );
                if (opt) {
                    setSelectedPax(opt);
                    setShowStatus(true);
                }
            }
        } else {
            setParticipantOptions([{ val: "-1", option: "Select Participant" }]);
            setSelectedPax(null);
        }
    }, [participants, props.selectedParticipantKey, selectedPax, participantOptions]);

    const getParticipantFromSelectedOption = (): Participant | null => {
        if (selectedPax == null) return null;
        var pax = participants?.filter((p) => p.esiKey === parseInt(selectedPax.val));
        return pax && pax.length > 0 ? pax[0] : null;
    };

    const handleShowStatusClick = () => {
        setShowStatus(true);
    };

    const paxChange = (evt: SelectChangeEvent) => {
        setShowStatus(false);
        let newopt: { val: string; option: string } = {
            val: evt.target.value as string,
            option: getOptionFromVal(evt.target.value, participantOptions) ?? "",
        };
        setSelectedPax(newopt);
    };

    return (
        <Stack width={1} spacing={4} sx={{ display: "flex", height: "100%", width: "100%" }}>
            {participants && participants.length > 0 ? (
                <Stack direction="row" spacing={3} justifyContent={"space-evenly"}>
                    <FormControl size="small" fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="participant-select-dropdown-label">Participant</InputLabel>
                        <Select
                            labelId="participant-select-dropdown"
                            id="participant-select"
                            value={selectedPax?.val ?? ""}
                            renderValue={(v) => {
                                return (
                                    getOptionFromVal(v, participantOptions) ?? "Select Participant"
                                );
                            }}
                            label="Select Participant"
                            onChange={paxChange}
                        >
                            {participantOptions.map((opt) => (
                                <MenuItem
                                    key={opt.val}
                                    selected={opt.val === selectedPax?.val}
                                    value={opt.val}
                                >
                                    {opt.option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <CclLoadingButton
                        mode={"primary"}
                        onClick={handleShowStatusClick}
                        size="small"
                        sx={{ textWrap: "nowrap" }}
                        disabled={showStatus}
                    >
                        Show Status
                    </CclLoadingButton>
                </Stack>
            ) : null}
            {showStatus && selectedPax && getParticipantFromSelectedOption() ? (
                <ScoringStatusPanel
                    disallowScoring={true}
                    sessionKey={getParticipantFromSelectedOption()?.esKey ?? undefined}
                    sessionCode={getParticipantFromSelectedOption()?.projectCode ?? undefined}
                    participant={getParticipantFromSelectedOption() ?? undefined}
                    readOnly={true}
                />
            ) : null}
        </Stack>
    );
};

export default ParticipantAssessmentStatusSubPanel;
