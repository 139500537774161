import * as React from "react";
import { Alert, AlertTitle, Card } from "@mui/material";
import { getErrorMsg } from "../../../../utilities/rtkQueryErrorHelpers";
import ComponentLoader from "../../../common/componentLoader";
import {
    useGetAccessEventsByEmailAndKeywordMutation,
    useGetAccessEventsByKeywordMutation,
} from "../../../../services/cclTokenedAccessEventsApi";
import { AccessEvent } from "../../../../services/types/accessEventTypes";
import AccessLogsDataGridPro from "../../../../pages/accesslogs/accessLogsDataGridPro";

interface IndividualApplicationLogsCardProps {
    emailAddress: string;
    imKey: string;
    requireEsiKey?: boolean;
    esiKey?: string;
}

const IndividualApplicationLogsCard: React.FC<IndividualApplicationLogsCardProps> = (props) => {
    const [allLogEntries, setAllLogEntries] = React.useState<AccessEvent[]>([]);
    const [
        runSearchByEmailAndKeyword,
        { isLoading: loadingByEmail, isError: isErrorByEmail, error: errorByEmail },
    ] = useGetAccessEventsByEmailAndKeywordMutation();
    const [
        runSearchByKeyword,
        { isLoading: loadingByKeyword, isError: isErrorByKeyword, error: errorByKeyword },
    ] = useGetAccessEventsByKeywordMutation();

    React.useEffect(() => {
        if (props.requireEsiKey) {
            if (props.esiKey !== undefined) {
                runSearchByKeyword({
                    keyword: props.esiKey,
                    start: null,
                    end: null,
                })
                    .unwrap()
                    .then((logentries: AccessEvent[]) => {
                        const filteredLogEntries = logentries.filter(
                            (entry) =>
                                entry.data.includes("esiKey") || entry.data.includes("esikey")
                        );
                        setAllLogEntries([...filteredLogEntries]);
                    })
                    .catch(() => {
                        setAllLogEntries([]);
                    });
            }
        } else {
            runSearchByEmailAndKeyword({
                email: props.emailAddress,
                payload: { keyword: props.emailAddress, start: null, end: null },
            })
                .unwrap()
                .then((logentries: AccessEvent[]) => {
                    setAllLogEntries([...logentries]);
                })
                .catch(() => {
                    setAllLogEntries([]);
                });
        }
    }, [props, runSearchByEmailAndKeyword, runSearchByKeyword]);

    function showErrorAlert(msg: string | undefined) {
        return (
            <div>
                <Alert severity="error" sx={{ fontSize: 14 }}>
                    <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                    {msg}
                </Alert>
            </div>
        );
    }

    if (isErrorByKeyword || isErrorByEmail) {
        const error = props.requireEsiKey ? errorByKeyword : errorByEmail;
        return showErrorAlert(getErrorMsg(error));
    }

    return (
        <Card sx={{ height: 1, width: 1, m: 2, p: 2 }}>
            {loadingByEmail || loadingByKeyword ? (
                <ComponentLoader msg="Loading" />
            ) : (
                <AccessLogsDataGridPro
                    accessEvents={allLogEntries}
                    loading={loadingByEmail || loadingByKeyword}
                    showSaveState={false}
                />
            )}
        </Card>
    );
};

export default IndividualApplicationLogsCard;
