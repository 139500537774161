import * as React from "react";
import {
    DataGridPro,
    GridRowId,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { TargetRevenueResponse } from "../../../../services/types/clientTypes";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

export interface TargetRevenueDataGridProProps {
    targetRevenueList: TargetRevenueResponse[] | undefined;
    showToolbar: boolean;
    OnTargetRevenueDelete: (targetRevenueID: number) => void;
    OnTargetRevenueEdit: (targetRevenueID: number) => void;
    isLoading: boolean;
}

export const TargetRevenueDataGridPro: React.FC<TargetRevenueDataGridProProps> = (
    props
) => {
    const COLUMNS = [
        {
            field: "fiscalYear",
            headerName: "Fiscal Year",
            type: "string",
            flex: 0.25,
        }, 
        {
            field: "accountOwnerName",
            headerName: "Account Owner Name",
            type: "string",
            flex: 0.25,
        },
        {
            field: "revenue",
            headerName: "Target Revenue",
            type: "number",
            flex: 0.25,
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

    const handleSelection = (ids: GridSelectionModel) => {
        if (!ids) {
            return;
        }
        const selectedRowsData = ids.map((id) =>  props.targetRevenueList?.find((row) => row.revenueId=== id));
        setSelectedRows(selectedRowsData);
    };

    const handleSelect = (id: string) => {
        switch (id) {
            case "edit":
                props.OnTargetRevenueEdit(selectedRows[0].revenueId);
                break;
            case "delete":
                props.OnTargetRevenueDelete(selectedRows[0].revenueId);
                break;
        }
    };

    return (
        <DataGridPro
            getRowId={(row) => row.revenueId}
            rows={props.targetRevenueList == null ? [] : props.targetRevenueList}
            columns={COLUMNS}
            loading={props.isLoading}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    showSaveState: true,
                    savestate: null,
                    clearstate: null,
                    miscButtons: 
                        [
                            {
                                id: "edit",
                                caption: `Edit`,
                                label: `Edit`,
                                returnSelected: false,
                                restricted: false,
                                disable: selectedRows.length !== 1,
                                onClick: () => {
                                    props.OnTargetRevenueEdit(selectedRows[0].revenueId);
                                }
                            },
                            {
                                id: "delete",
                                caption: `Delete`,
                                label: `Delete`,
                                returnSelected: false,
                                restricted: false,
                                disable: selectedRows.length !== 1,
                                onClick: () => {
                                    props.OnTargetRevenueDelete(selectedRows[0].revenueId);
                                }
                            }
                        ],
                        btnClick: ((id: string) => handleSelect(id)),
                        hideSettingsButtons: true,
                        anySelected: null,
                    }
            }}
            disableMultipleSelection
            disableSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [{ field: "revenueId", sort: "asc" }],
                },
            }}
            checkboxSelection
            selectionModel={selectionModel}
            onSelectionModelChange={(id: GridSelectionModel) => {
                if (id.length > 1) {
                    const selectionSet = new Set(selectionModel);
                    const result = id.filter((s) => !selectionSet.has(s));
                    handleSelection(result);
                    setSelectionModel(result);
                } else {
                    handleSelection(id);
                    setSelectionModel(id);
                }
            }}
        />       
    );
};

export default TargetRevenueDataGridPro;
