import * as React from "react";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { AllAttributeOptions } from "../../../services/types/myAccessTypes";
import { SearchAttribute } from "../../../services/types/enterpriseCoachingApiTypes";
import CclButton from "../../../components/common/cclButtons/cclButton";

interface AdvancedSearchAttributeListItemProps {
    allAttributeOptions: AllAttributeOptions | undefined;
    handleAttributeSelected: (attribute: SearchAttribute) => void;
    addAttribute: () => void;
    id: string;
}

export interface AutocompleteItem {
    id: number;
    label: string;
}

const AdvancedSearchAttributeListItem: React.FC<AdvancedSearchAttributeListItemProps> = (props) => {
    const attributes = props.allAttributeOptions
        ? props.allAttributeOptions.profileItemTypes
              .map((x) => {
                  return { id: x.key, label: x.name };
              })
              .sort((a, b) => a.label.localeCompare(b.label))
        : [];
    const [parentAttribute, setParentAttribute] = React.useState<number>(0);
    const [selectedAttributeOptions, setSelectedAttributeOptions] = React.useState<
        AutocompleteItem[]
    >([]);
    const [selectedLevelOptions, setSelectedLevelOptions] = React.useState<AutocompleteItem[]>([]);
    const [selectedAttribute, setSelectedAttribute] = React.useState<AutocompleteItem | undefined>(
        undefined
    );
    const [selectedLevel, setSelectedLevel] = React.useState<AutocompleteItem | undefined>(
        undefined
    );
    //const [hasChanged, setHasChanged] = React.useState<boolean>(false);

    const [key1, setKey1] = React.useState<string>("1");
    const [key2, setKey2] = React.useState<string>("2");
    const [key3, setKey3] = React.useState<string>("3");

    const clearAttributes = () => {
        setParentAttribute(0);
        setSelectedAttributeOptions([]); 
        setSelectedLevelOptions([]);
        setSelectedAttribute(undefined);
        setSelectedLevel(undefined);
        //setHasChanged(false);
        setKey1(crypto.randomUUID());
        setKey2(crypto.randomUUID());
        setKey3(crypto.randomUUID());
    }

    const onSelectChange = (newValue: { label: string; id: number } | null) => {
        if (newValue && newValue.id > 0) {
            setSelectedAttribute(undefined);
            setSelectedLevel(undefined);
            setKey2(crypto.randomUUID());
            setKey3(crypto.randomUUID());
            setParentAttribute(newValue.id);
            //setHasChanged(true);
            if (newValue.id === 2) {
                let attributeOptions = props.allAttributeOptions?.languages
                    .map((x) => {
                        return { id: x.key, label: x.name } as AutocompleteItem;
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
                setSelectedAttributeOptions(attributeOptions ? attributeOptions : []);
            } else {
                let attributeOptions = props.allAttributeOptions?.profileItemTypes
                    .find((x) => x.key === newValue.id)
                    ?.items.map((x) => {
                        return { id: x.key, label: x.name } as AutocompleteItem;
                    })
                    .sort((a, b) => a.label.localeCompare(b.label));
                setSelectedAttributeOptions(attributeOptions ? attributeOptions : []);
            }
            let searchAttribute: SearchAttribute = {
                pitKey: newValue.id,
                id: props.id,
            };
            props.handleAttributeSelected(searchAttribute);
        }
    };

    const onAttributeOptionChanged = (newValue: { label: string; id: number } | null) => {
        if (newValue && newValue.id > 0) {
            setSelectedAttribute(newValue);
            let levels = props.allAttributeOptions?.profileItemTypes
                .filter((x) => x.key === parentAttribute)[0]
                .itemLevels.map((x) => {
                    return { id: x.key, label: x.name } as AutocompleteItem;
                })
                .sort((a, b) => a.label.localeCompare(b.label));
            if (parentAttribute === 2) {
                levels = [];
            }
            setSelectedLevelOptions(levels ? levels : []);
            let searchAttribute: SearchAttribute = {
                pitKey: parentAttribute,
                piKey: newValue.id,
                pitlKey: selectedLevel?.id,
                id: props.id,
            };
            props.handleAttributeSelected(searchAttribute);
        }
    };

    const onAttributeLevelChanged = (newValue: { label: string; id: number } | null) => {
        if (newValue && newValue.id > 0) {
            setSelectedLevel(newValue);
            let searchAttribute: SearchAttribute = {
                pitKey: parentAttribute,
                piKey: selectedAttribute?.id,
                pitlKey: newValue.id,
                id: props.id,
            };
            props.handleAttributeSelected(searchAttribute);
        } else {
            setSelectedLevel(undefined);
            let searchAttribute: SearchAttribute = {
                pitKey: parentAttribute,
                piKey: selectedAttribute?.id,
                id: props.id,
            };
            props.handleAttributeSelected(searchAttribute);
        }
    };
    
    const handleAddAttribute = () => {
        props.addAttribute();
        clearAttributes();
    }

    return (
        <Stack spacing={4} sx={{ m:1, mt: 3, width: "-webkit-fill-available" }}>
            <Autocomplete
                fullWidth
                size="small"
                disablePortal
                id="combo-box-demo-1"
                options={attributes}
                onChange={(
                    event: React.SyntheticEvent<Element, Event>,
                    value: { label: string; id: number } | null
                ) => onSelectChange(value)}
                renderInput={(params) => <TextField {...params} label="Select item type" />}
                key={key1}
            />
            <Autocomplete
                fullWidth={true}
                size="small"
                disablePortal
                value={selectedAttribute}
                disabled={!parentAttribute}
                id="combo-box-demo-2"
                options={selectedAttributeOptions}
                onChange={(
                    e: React.SyntheticEvent<Element, Event>,
                    value: { label: string; id: number } | null
                ) => onAttributeOptionChanged(value)}
                renderInput={(params) => <TextField {...params} label="Select item type" />}
                key={key2}
            />
            <Stack direction={"row"} spacing={2} padding={0}>
                <Autocomplete
                    fullWidth
                    size="small"
                    disablePortal
                    value={selectedLevel}
                    disabled={!parentAttribute || selectedLevelOptions.length === 0}
                    onChange={(
                        e: React.SyntheticEvent<Element, Event>,
                        value: { label: string; id: number } | null
                    ) => onAttributeLevelChanged(value)}
                    id="combo-box-demo-3"
                    options={selectedLevelOptions}
                    renderInput={(params) => (
                        <TextField {...params} label="Select item type" />
                    )}
                    key={key3}
                />
            </Stack>
            <Stack direction={"row"} sx={{width: 1,justifyContent: "left"}}>
                <CclButton 
                    sx={{mr: 3}} 
                    disabled={!parentAttribute || !selectedAttribute} 
                    onClick={() => handleAddAttribute()} 
                    mode={"secondary"}
                    size="small"
                >
                    Add Attribute
                </CclButton>
                <CclButton 
                    disabled={!parentAttribute} 
                    onClick={clearAttributes} 
                    mode="secondary"
                    size="small"
                >
                    Clear Attribute
                </CclButton>
            </Stack>
        </Stack>
    );
};

export default AdvancedSearchAttributeListItem;
