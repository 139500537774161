import * as React from "react";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { DetailsTabPanel } from "../../../../components/common/detailTabPanel/detailTabPanel";
import ResourceRegistrationsPanel from "../../../resources/resourceDetail/participants/registrations/resourceRegistrationsPanel";
import ResourceProductSalesPanel from "../../../resources/resourceDetail/participants/productsales/resourceProductSalesPanel";
import UserFilesPanel from "../files/userFilePanel";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import ParticipantAssessmentStatusSubPanel from "../../../my-access/accessDetail/myFilesTab/participantAssessmentStatusSubPanel";

interface UserParticipantsPanelProps {
    imKey: number;
    emailAddress: string;
    fullName?: string;
    fromResources?: boolean;
}

const UserParticipantPanel: React.FC<UserParticipantsPanelProps> = (props) => {
    const claimsService = new UserClaimsService();
    const isNwaAdmin = claimsService.IsUserNwaAdmin();
    const [alignment, setAlignment] = React.useState<number>(0);
    const [selectedParticipantKey, setSelectedParticipantKey] = React.useState<number | undefined>(
        undefined
    );

    const handleTabChange = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue != null) setAlignment(newValue);
    };

    const handleViewPaxAssessmentStatus = (esiKey: number) => {
        setSelectedParticipantKey(esiKey);
        var tabIndex = tablist.findIndex((t) => t[0] === "Participant Assessment Status");
        setAlignment(tabIndex);
    };

    // Tablist
    let tablist: [string, React.ReactNode][] = [
        [
            "Participant Files",
            <ResourceRegistrationsPanel
                imkey={props.imKey}
                handleViewPaxAssessmentStatus={handleViewPaxAssessmentStatus}
                isUserDetail={true}
            />,
        ],
        [
            "Product Sale Assessments",
            <ResourceProductSalesPanel email={props.emailAddress} isUserDetail={true} />,
        ],
        [
            "Users Files",
            <UserFilesPanel
                imKey={props.imKey}
                email={props.emailAddress}
                fullName={props.fullName}
                allowDelete={claimsService.IsUserSuperAdmin()}
            />,
        ],
    ];

    if (isNwaAdmin) {
        tablist = [
            [
                "Participant Files",
                <ResourceRegistrationsPanel
                    imkey={props.imKey}
                    handleViewPaxAssessmentStatus={handleViewPaxAssessmentStatus}
                    isUserDetail={true}
                />,
            ],
        ];
    }

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                borderWidth: 0,
                display: "flex",
                flexDirection: "column",
            }}
        >
            {!props.fromResources && !isNwaAdmin && (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    sx={{ borderWidth: 0, flexGrow: 1 }}
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleTabChange}
                        sx={{ mb: 3 }}
                    >
                        {tablist.map((tab, i) => (
                            <ToggleButton
                                key={i}
                                id={`user-detail-participants-tab-${i}`}
                                value={i}
                            >
                                {tab[0]}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
            )}
            {tablist.map((tab, i) => (
                <DetailsTabPanel
                    detailType={"user-participants"}
                    key={tab[0]}
                    value={alignment}
                    index={i}
                >
                    {tab[1]}
                </DetailsTabPanel>
            ))}
        </Box>
    );
};

export default UserParticipantPanel;
