import * as React from "react";
import { Button, ButtonProps } from "@mui/material";
import { getButtonStyle } from "./cclButtonStylings";

type CclButtonProps = ButtonProps & {
    mode: "primary" | "secondary" | "tertiary";
};

export const CclButton: React.FC<CclButtonProps> = (props) => {
    const { mode, sx, children, ...passthroughprops } = props;

    let styling = { ...getButtonStyle(mode, passthroughprops.size === "small"), ...sx };

    return (
        <Button sx={{ ...styling }} {...passthroughprops}>
            {children}
        </Button>
    );
};

export default CclButton;
