import * as React from "react";
import { AlertColor, Stack } from "@mui/material";
import { Asset, Participant } from "../../../../services/types/enterpriseParticipantApiTypes";
import ParticipantAssetsDataGrid from "./participantFileDataGrid/participantFilesDataGrid";
import {
    useReleaseSessionAssetsMutation,
    useRemoveAssetsMutation,
    useDeleteAssetsMutation,
} from "../../../../services/cclTokenedAssetsApi";
import {
    useGetCclEventByEventKeyQuery,
    useGetSessionOptionsByEventKeyQuery,
    useSetEventSessionOptionMutation,
    useUpdateSessionCoachesAccessQuery,
} from "../../../../services/cclTokenedSessionApi";
import { AccessEventSessionDetails } from "../../../../services/types/accessEventTypes";
import {
    SessionEventOption,
    SetSessionEventOptionRequest,
} from "../../../../services/types/sessionOptionsType";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { FileDownloadService } from "../../../../services/fileDownloadService/fileDownloadService";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclAlertDialog from "../../../../components/common/cclAlertDialog";
import CclDownloadWarningAlert from "../../../../components/common/cclDownloadWarningAlert";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import SessionFileUploadDrawer from "./fileUploadDialog/sessionFileUploadDrawer";

interface ParticipantFilesSubPanelProps {
    sessionId: string;
    assets: Asset[];
    participants: Participant[];
    readOnly?: boolean;
}

const ParticipantFilesSubPanel: React.FC<ParticipantFilesSubPanelProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const claimsService = new UserClaimsService();
    const [showLargeUploadWarning, setShowLargeUploadWarning] = React.useState<boolean>(false);
    const [selectedCount, setSelectedCount] = React.useState<number>(0);
    const [openUploadDrawer, setOpenUploadDrawer] = React.useState<boolean>(false);
    const [coachReleaseEnabled, setCoachReleaseEnabled] = React.useState<boolean>(false);
    const [eventInProgress, setEventInProgress] = React.useState<string>("");
    const { data: sessionDetails } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });
    const { data: sessionOptions, isSuccess: optionsSuccess } = useGetSessionOptionsByEventKeyQuery(
        props.sessionId,
        { skip: props.sessionId === "" }
    );
    const { refetch } = useUpdateSessionCoachesAccessQuery(props.sessionId); //, {skip: !updateCoachAccess}
    const [releaseAssets] = useReleaseSessionAssetsMutation();
    const [removeAssets] = useRemoveAssetsMutation();
    const [deleteAssets] = useDeleteAssetsMutation();
    const [setCoachReleaseOption] = useSetEventSessionOptionMutation();
    const { logEvent } = useLogAccessEvent();
    const downloadService = new FileDownloadService();

    const personList = props.participants.map((p) => {
        return { firstName: p.firstName, lastName: p.lastName, imKey: p.imKey };
    });

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    React.useEffect(() => {
        if (optionsSuccess) {
            let opt = sessionOptions.sessionEventOptions.find((o) => o.optionId === 3);
            setCoachReleaseEnabled(opt != null);
        }
        // eslint-disable-next-line
    }, [sessionOptions]);

    const onGridToolbarButtonClick = (eventName: string, selectedIds: (number | string)[]) => {
        let newids = selectedIds.map((i) => +i);
        if (eventName === "download" && selectedIds.length > 30) {
            setSelectedCount(selectedIds.length);
            setShowLargeUploadWarning(true);
            return;
        }
        completeAction(eventName, newids);
    };

    const completeAction = (eventname: string, aikeys: number[]) => {
        setEventInProgress(eventname);
        switch (eventname) {
            case "upload":
                setOpenUploadDrawer(true);
                break;

            case "download":
                let filename: string = "";
                if (aikeys.length > 1) {
                    filename =
                        sessionDetails != null
                            ? `${sessionDetails.sessionCode}_ParticipantFiles.zip`
                            : "ParticipantFiles.zip";
                } else if (aikeys.length === 1) {
                    let ast = props.assets.find((a) => a.id === aikeys[0]);
                    if (ast == null) return; // just in case id isn't found
                    filename = ast.name;
                    if (ast.fileExtension !== "") {
                        let fileExt = ast.name.split(".").pop();
                        if (fileExt?.toLowerCase() !== ast.fileExtension.toLowerCase())
                            filename += ast.fileExtension;
                    }
                } else {
                    ShowSnackbarElement("Download Failed", "error");
                    setEventInProgress("");
                    return; // nothing selected
                }
                downloadService
                    .DownloadAssets({ fname: filename, aikeys: aikeys, flatzip: false })
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            sessionID: props.sessionId.toString(),
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("ParticipantFilesDownloaded", evtData);
                        ShowSnackbarElement("Download Completed", "success");
                        setEventInProgress("");
                    })
                    .catch((error) => {
                        ShowSnackbarElement("Download Failed", "error");
                        setEventInProgress("");
                    });
                break;

            case "release":
                const clearedAssets: Asset[] = props.assets
                    .filter((a) => aikeys.find((key) => key === a.id) != null)
                    .filter((a) => a.fileType !== "Consultant Report");
                releaseAssets({ eskey: +props.sessionId, aikeys: clearedAssets.map((a) => a.id) })
                    .unwrap()
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            sessionID: props.sessionId.toString(),
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("ParticipantFilesReleased", evtData);
                        ShowSnackbarElement("Release Completed", "success");
                        setEventInProgress("");
                    })
                    .catch(() => {
                        ShowSnackbarElement("Release Failed", "error");
                        setEventInProgress("");
                    });
                break;

            case "unrelease":
                removeAssets({ eskey: +props.sessionId, aikeys: aikeys })
                    .unwrap()
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            sessionID: props.sessionId.toString(),
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("ParticipantFilesUnReleased", evtData);
                        ShowSnackbarElement("Un-Release Completed", "success");
                        setEventInProgress("");
                    })
                    .catch(() => {
                        ShowSnackbarElement("Un-Release Failed", "error");
                        setEventInProgress("");
                    });
                break;

            case "delete":
                deleteAssets({ eskey: +props.sessionId, aikeys: aikeys })
                    .unwrap()
                    .then(() => {
                        const evtData: AccessEventSessionDetails = {
                            sessionID: props.sessionId.toString(),
                            projectCode: sessionDetails?.sessionCode ?? "",
                        };

                        logEvent("ParticipantFilesDeleted", evtData);
                        ShowSnackbarElement("File(s) Deleted", "success");
                        setEventInProgress("");
                    })
                    .catch(() => {
                        ShowSnackbarElement("File Deletion Failed", "error");
                        setEventInProgress("");
                    });
                break;

            case "coachreleaseenable":
                setEventInProgress("");
                callSetCoachReleaseOption(true);
                refetch();
                break;

            case "coachreleasedisable":
                setEventInProgress("");
                setEventInProgress("");
                callSetCoachReleaseOption(false);
                break;
        }
    };

    const callSetCoachReleaseOption = (enable: boolean) => {
        if (sessionDetails == null) return;
        const option: SessionEventOption = {
            optionId: 3,
            optionName: "Coach Release",
            parentOptionId: null,
            active: enable,
            scheduled: false,
            scheduledActivation: null,
            changedBy: claimsService.GetCurrentUserEmail(),
            changedDate: new Date(),
        };

        const request: SetSessionEventOptionRequest = {
            eskey: sessionDetails?.sessionKey.toString(),
            eventOption: option,
        };

        setCoachReleaseOption(request)
            .unwrap()
            .then(() => {
                const evtData: AccessEventSessionDetails = {
                    sessionID: props.sessionId.toString(),
                    projectCode: sessionDetails?.sessionCode ?? "",
                };

                logEvent("AllowCoachReleaseChanged", evtData);
            });
    };

    return (
        <Stack width={1} spacing={2} sx={{ display: "flex", height: "100%", width: "100%" }}>
            <CclDownloadWarningAlert />
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CclAlertDialog
                title={`Too Many Files (${selectedCount}) Selected For Download`}
                msg="For data security and privacy purposes files can only be downloaded in batches of 30 or less. Please deselect some files and try again."
                open={showLargeUploadWarning}
                onOk={() => setShowLargeUploadWarning(false)}
                severity={"error"}
            />
            <SessionFileUploadDrawer
                sessionId={props.sessionId}
                people={personList}
                open={openUploadDrawer}
                personType={"participant"}
                onClose={() => setOpenUploadDrawer(false)}
                isSessionFile={false}
                projectCode={sessionDetails?.sessionCode ?? ""}
            />
            <div style={{ flexGrow: 1 }}>
                <ParticipantAssetsDataGrid
                    participants={personList}
                    assets={props.assets}
                    handleEvent={onGridToolbarButtonClick}
                    coachReleaseEnabled={coachReleaseEnabled}
                    readOnly={props.readOnly}
                    eventInProgress={eventInProgress}
                />
            </div>
        </Stack>
    );
};

export default ParticipantFilesSubPanel;
