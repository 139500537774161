import { SxProps, Theme } from "@mui/material";

const baseStyling: SxProps<Theme> = {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.1px",
    borderRadius: "100px",
    gap: "8px",
    padding: "10px 24px",
};

const baseSmallStyling: SxProps<Theme> = {
    display: "inline-flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.1px",
    borderRadius: "100px",
    gap: "6px",
    padding: "8px 16px",
};

// primary stylings

const disabledPrimaryStyle: SxProps<Theme> = {
    background: "var(--neutral-carbon-25-outline, #C3C6CF)",
    border: "none",
    color: "var(--Neutral-Black, #000);",
    opacity: 0.5,
};

const hoverPrimaryStyle: SxProps<Theme> = {
    background: "var(--primary-center-blue-primary-100, #2F5F95)",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
};

const activePrimaryStyle: SxProps<Theme> = {
    background: "var(--Primary-Center-Blue---Primary-Hover, #00254E)",
};

const focusPrimaryStyle: SxProps<Theme> = {
    background: "var(--primary-center-blue-primary-100, #2F5F95)",
};

const primaryStyle: SxProps<Theme> | undefined = {
    background: "var(--primary-center-blue-primary-100, #2F5F95)",
    border: "none",
    color: "#FFF",
    ...baseStyling,
    "&.Mui-disabled": disabledPrimaryStyle,
    "&:hover": hoverPrimaryStyle,
    "&:focus": focusPrimaryStyle,
    "&:active": activePrimaryStyle,
};

const primarySmallStyle: SxProps<Theme> | undefined = {
    background: "var(--primary-center-blue-primary-100, #2F5F95)",
    border: "none",
    color: "#FFF",
    ...baseSmallStyling,
    "&.Mui-disabled": disabledPrimaryStyle,
    "&:hover": hoverPrimaryStyle,
    "&:focus": focusPrimaryStyle,
    "&:active": activePrimaryStyle,
};

// secondary stylings
const disabledSecondaryStyle: SxProps<Theme> = {
    background: "var(--Neutral-White, #FFF)",
    border: "1px solid var(--neutral-carbon-25-outline, #C3C6CF)",
    color: "#000000",
    opacity: 0.5,
};

const hoverSecondaryStyle: SxProps<Theme> = {
    background: "var(--primary-center-blue-primary-10, #EAEFF4)",
};

const activeSecondaryStyle: SxProps<Theme> = hoverSecondaryStyle;

const focusSecondaryStyle: SxProps<Theme> = hoverSecondaryStyle;

const secondaryStyle: SxProps<Theme> | undefined = {
    background: "white",
    border: "1px solid #2F5F95",
    color: "#2F5F95",
    ...baseStyling,
    "&.Mui-disabled": disabledSecondaryStyle,
    "&:hover": hoverSecondaryStyle,
    "&:focus": focusSecondaryStyle,
    "&:active": activeSecondaryStyle,
};

const secondarySmallStyle: SxProps<Theme> | undefined = {
    background: "white",
    border: "1px solid #2F5F95",
    color: "#2F5F95",
    ...baseSmallStyling,
    "&.Mui-disabled": disabledSecondaryStyle,
    "&:hover": hoverSecondaryStyle,
    "&:focus": focusSecondaryStyle,
    "&:active": activeSecondaryStyle,
};

// tertiary stylings
const disabledTertiaryStyle: SxProps<Theme> = {
    background: "var(--Neutral-White, #FFF)",
    border: "1px solid var(--neutral-carbon-25-outline, #C3C6CF)",
    color: "var(--Neutral-Black, #000)",
    opacity: 0.5,
};

const hoverTertiaryStyle: SxProps<Theme> = {
    background: "var(--primary-center-blue-primary-100, #EAEFF4)",
};

const activeTertiaryStyle: SxProps<Theme> = hoverTertiaryStyle;

const focusTertiaryStyle: SxProps<Theme> = hoverTertiaryStyle;

const tertiaryStyle: SxProps<Theme> | undefined = {
    background: "white",
    border: "none",
    color: "var(--primary-center-blue-primary-100, #2F5F95)",
    ...baseStyling,
    "&.Mui-disabled": disabledTertiaryStyle,
    "&:hover": hoverTertiaryStyle,
    "&:focus": focusTertiaryStyle,
    "&:active": activeTertiaryStyle,
};

const tertiarySmallStyle: SxProps<Theme> | undefined = {
    background: "white",
    border: "none",
    color: "var(--primary-center-blue-primary-100, #2F5F95)",
    ...baseSmallStyling,
    "&.Mui-disabled": disabledTertiaryStyle,
    "&:hover": hoverTertiaryStyle,
    "&:focus": focusTertiaryStyle,
    "&:active": activeTertiaryStyle,
};

export function getButtonStyle(mode: "primary" | "secondary" | "tertiary", isSmall: boolean) {
    switch (mode) {
        case "primary":
            return isSmall ? primarySmallStyle : primaryStyle;
        case "secondary":
            return isSmall ? secondarySmallStyle : secondaryStyle;
        case "tertiary":
            return isSmall ? tertiarySmallStyle : tertiaryStyle;
        default:
            return isSmall ? primarySmallStyle : primaryStyle;
    }
}
