import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

export type RouteAccess = {
    allowHome: boolean;
    allowSessions: boolean;
    allowRegistrations: boolean;
    allowRegistrationDetails: boolean;
    allowResources: boolean;
    allowNotifications: boolean;
    allowNotificationDetails: boolean;
    allowApplications: boolean;
    allowUsers: boolean;
    allowUserDetails: boolean;
    allowAssessments: boolean;
    allowAdmin: boolean;
    allowLogs: boolean;
    allowMyAccess: boolean;
    allowClients: boolean;
};

export interface AllowedRoutesState {
    routeFlags: RouteAccess;
}

// Define the initial state using that type
const initialState: AllowedRoutesState = {
    routeFlags: {
        allowHome: true,
        allowSessions: false,
        allowRegistrations: false,
        allowRegistrationDetails: false,
        allowResources: false,
        allowNotifications: false,
        allowNotificationDetails: false,
        allowApplications: false,
        allowUsers: false,
        allowUserDetails: false,
        allowAssessments: false,
        allowAdmin: false,
        allowLogs: false,
        allowMyAccess: false,
        allowClients: false,
    },
};

export const allowedRoutesSlice = createSlice({
    name: "allowedRoutes",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateAllowedRoutes: (state, action: PayloadAction<RouteAccess>) => {
            state.routeFlags = action.payload;
        },
    },
});

export const { updateAllowedRoutes } = allowedRoutesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAllowedRoutes = (state: RootState) => state;
export default allowedRoutesSlice.reducer;
