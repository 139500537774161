import * as React from "react";
import { OrganizationSizeList, SectorList } from "../../../../services/types/clientTypes";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import { Box} from "@mui/material";
import CclInfoTable, { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblHomeIndustriesEditTemplate } from "./homeIndustriesEditTemplate";

export type HomeIndustriesEditDialogProps = {
    open: boolean;
    onOk: () => any;
    onCancel: () => any;
    data: any;
    sectorList : SectorList[];
    OnSectorChange: (childKey: number, sectorNmae: string) => void;
    OnOrgChange: (childKey: number, OrgName: string) => void;
    orgSizeList : OrganizationSizeList[];
    Isloading: boolean;
};

const HomeIndustriesEditDialog: React.FC<HomeIndustriesEditDialogProps> = (props) => {

    const [editTemplate, setEditTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);

    React.useEffect(() => {
        if (!props.data) return;

        let template = CclInfoTblHomeIndustriesEditTemplate;
        setEditTemplate(template);

        if (props.sectorList != null && props.sectorList.length > 0) {
            var rowitem = template.find((item) => item.id === "sectorName");
            let temp = [...props.sectorList];
            temp.sort((a, b) => a.sectorName.localeCompare(b.sectorName));
            if (rowitem && rowitem.valueField) {
                rowitem.valueField.selectOptions = temp.map((item) => {
                    return {val: item.sectorKey.toString(), option: item.sectorName};
                });
            }

            rowitem = template.find((item) => item.id === "orgSize");
            let temp2 = [...props.orgSizeList];
            temp2.sort((a, b) => a.orgSize.localeCompare(b.orgSize));
            if (rowitem && rowitem.valueField) {
                rowitem.valueField.selectOptions = temp2.map((item) => {
                    return {val: item.orgSizeKey.toString(), option: item.orgSize};
                });
            }
        }
        //eslint-disable-next-line
    }, [props.data]);

    const handleSave = (data: any) => {
        let industry = "";
        let size = "";

        for (let i = 0; i < Object.keys(data).length; i++) {
            if (data[i].name === "sectorName") {
                industry = data[i].value.option;
            }
            if (data[i].name === "orgSize") {
                size = data[i].value.option;
            }
        }

        props.OnSectorChange(props.data.childKey, industry);
        props.OnOrgChange(props.data.childKey, size);
        props.onOk();
    };

    const handleClose = () => {
        props.onCancel();
    };

    return (
        <CclDrawer 
            title={props.data?.childName} 
            open={props.open} 
            onClose={()=>{props.onCancel()}}
        >
            <>
                <Box>
                    <CclInfoTable 
                        template={editTemplate} 
                        data={props.data}
                        onSave={handleSave}
                        skipConfirmation={true}
                    />

                    {/* <Autocomplete id="tags-standard" 
                        options={props.sectorList.map((option) => option.sectorName)?? []}
                        value={industry}
                        fullWidth={true}
                        onChange={(e: any, newValue: string|null) => {
                            setIndustry(newValue ?? "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Sector Name"
                                sx={{ mt: 2, mb: 2 }}
                            />
                        )}
                        sx={{ mt: 2, mb: 2 }}
                    />
                    <Autocomplete id="tags-standard" 
                        options={props.orgSizeList.map((option) => option.orgSize)?? []}
                        value={size}
                        fullWidth={true}
                        onChange={(e: any, newValue: string|null) => {
                                    setSize(newValue??"");
                                }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Organization Size"
                                sx={{ mt: 2, mb: 2 }}
                            />
                        )}
                        sx={{ mt: 2, mb: 2 }}
                    /> */}
                </Box>
                <CclButton
                    color="primary"
                    id="cancel-btn"
                    onClick={() => handleClose()} 
                    mode={"primary"}            
                >
                    Cancel
                </CclButton>
            </>
        </CclDrawer>
    );
};

export default HomeIndustriesEditDialog;
