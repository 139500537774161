import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ResourceDocument } from "../../../services/types/search.service.types";
import { useSelector } from "react-redux";
import { updateResourceGridState } from "../../../app/slices/resourceListTableStateSlice";
import CclPersistentDataGrid from "../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { addCustomDataGridFilters } from "../../../components/common/customDataGridFilters/customDataGridFilters";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CclCommonLink from "../../../components/common/cclCommonLink";

function getStatusString(params: any) {
    return params.row["statuskey"] === 1 && params.row["bookableresourceid"] == null
        ? "Needs Attention"
        : `${params.row["status"]}`;
}

function getFullName(params: any) {
    return `${params.row.firstname || ""} ${params.row.lastname || ""}`;
}

export interface ResourcesListProps {
    resources: ResourceDocument[];
    loading: boolean;
    hideToolbar?: boolean;
}

export const ResourcesDataGridPro: React.FC<ResourcesListProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.resourceListTableState);
    const [resources, setResources] = React.useState<ResourceDocument[]>(props.resources);

    React.useLayoutEffect(() => {
        setResources(props.resources);
    }, [props.resources]);

    const COLUMNS = [
        {
            field: "name",
            headerName: "Resource",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/resource/${params.row.pkey}/0`}
                    target="_blank"
                />
            ),
            flex: 0.8,
            valueGetter: getFullName,
        },
        {
            field: "email",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 1.25,
        },
        {
            field: "status",
            headerName: "D365 Status",
            type: "string",
            valueGetter: getStatusString,
            renderCell: (params: GridRenderCellParams) =>
                params.row["statuskey"] === 1 && params.row["bookableresourceid"] == null ? (
                    <>
                        Needs Attention
                        <ErrorOutlineIcon color="error" fontSize="small" sx={{ pl: 1 }} />
                    </>
                ) : (
                    `${params.value}`
                ),
            flex: 0.6,
        },
        {
            field: "relationship",
            headerName: "Resource Type",
            flex: 0.75,
            type: "string",
        },
        {
            field: "title",
            headerName: "Title",
            type: "string",
            flex: 1.1,
        },
        {
            field: "company",
            headerName: "Company",
            flex: 1.25,
            type: "string",
        },
        {
            field: "reportingregion",
            headerName: "Region",
            flex: 0.65,
            type: "string",
        },
        {
            field: "reportinglocation",
            headerName: "Reporting Location",
            flex: 0.65,
            type: "string",
        },
    ];

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={resources}
            loading={props.loading}
            stateSelector={stateSelector}
            userPreferenceId={4}
            getRowIdFn={(row: ResourceDocument) => row.pkey}
            actionCreator={updateResourceGridState}
            hideToolbar={props.hideToolbar === true}
            initState={JSON.stringify({
                sorting: {
                    sortModel: [{ field: "name", sort: "asc" }],
                },
                filter: {
                    filterModel: {
                        items: [
                            {
                                id: 1,
                                columnField: "status",
                                operatorValue: "startsWith",
                                value: "active",
                            },
                        ],
                    },
                },
            })}
            resultBar={false}
            resultBarText={""}
            hideFooter={false}
        />
    );
};

export default ResourcesDataGridPro;
