import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
export interface ProductSaleListConfigState {
    StartDate: string;
    EndDate: string;
    Email: string;
    IsEmailSearchActive: boolean;
}

// Define the initial state using that type
const initialState: ProductSaleListConfigState = {
    StartDate: "",
    EndDate: "",
    Email: "",
    IsEmailSearchActive: false,
};

export const productSaleListConfigSlice = createSlice({
    name: "productSaleListConfig",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateProductSaleSearchParams: (
            state,
            action: PayloadAction<ProductSaleListConfigState>
        ) => {
            state.StartDate = action.payload.StartDate;
            state.EndDate = action.payload.EndDate;
            state.Email = action.payload.Email;
            state.IsEmailSearchActive = action.payload.IsEmailSearchActive;
        },
    },
});

export const { updateProductSaleSearchParams } = productSaleListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectProductSaleListConfig = (state: RootState) => state;
export default productSaleListConfigSlice.reducer;
