import * as React from "react";
import { Stack, Box } from "@mui/material";
import { Application } from "../../../../services/types/cclGrandCentralApiTypes";
import ApplicationInvitationDataGrid from "./applicationInvitationDataGrid";
import { useSearchInvitationsforApplicationMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { ApplicationSearchInvitationBody } from "../../../../services/types/applicationTypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import ComponentLoader from "../../../../components/common/componentLoader";
import CclSearchDefault from "../../../../components/common/cclSearchDefault";
import CclTextOrDateSearchBar from "../../../../components/common/cclLandingPageSearchBars/cclTextOrDateSearchBar";

interface ApplicationInvitationPanelProps {
    application: Application | undefined;
}

const ApplicationInvitationPanel: React.FC<ApplicationInvitationPanelProps> = (props) => {
    const [runSearch, { data: searchData, isSuccess, isLoading }] =
        useSearchInvitationsforApplicationMutation();

    const emailOrImKey = (value: string) => {
        if (isNaN(+value)) {
            return false;
        } else {
            return true;
        }
    };

    const searchInvitations = (
        startDate: Date | null,
        endDate: Date | null,
        searchEmail: string
    ) => {
        if (!props.application) {
            return;
        }

        const payload: ApplicationSearchInvitationBody = {
            applicationId: props.application.applicationId,
            payload: {
                imKey: searchEmail ? (emailOrImKey(searchEmail) ? +searchEmail : null) : null,
                emailAddress: searchEmail ? (emailOrImKey(searchEmail) ? "" : searchEmail) : "",
                startDate: startDate,
                endDate: endDate ? endDate : startDate ? new Date() : null,
            },
        };

        runSearch(payload);
    };

    return (
        <Stack spacing={2} width={1} direction={"column"}>
            <Box sx={{ display: "flex", flexDirection: "row", width: 1 }}>
                <Box sx={{ flex: "1", paddingRight: 1 }}>
                    <CclTextOrDateSearchBar
                        executeSearch={(
                            searchText: string,
                            startDate: Date | null,
                            endDate: Date | null
                        ) => searchInvitations(startDate, endDate, searchText)}
                        initialTextFieldSearchTerm={""}
                        textFieldSearchLabel="Enter User Email"
                        initialStartDate={null}
                        initialEndDate={null}
                        startDateLabel="From Date"
                        endDateLabel="To Date"
                        allowNullDates={false}
                        showResetButton={true}
                        clearSearchTrigger={undefined}
                    />
                </Box>
                <Box
                    sx={{
                        flexShrink: 0,
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 1,
                        paddingRight: 1,
                        float: "right",
                    }}
                >
                    <CclButton
                        size="small"
                        onClick={() => {
                            searchInvitations(null, null, "");
                        }}
                        aria-label="Get All Invitations"
                        disabled={props.application === undefined}
                        sx={{ height: 1 }}
                        mode={"primary"}
                    >
                        Get All
                    </CclButton>
                </Box>
            </Box>
            {isLoading ? (
                <ComponentLoader msg={"Loading"} />
            ) : isSuccess && searchData ? (
                <ApplicationInvitationDataGrid
                    invitations={searchData ? searchData : []}
                    isLoading={isLoading}
                    showResultBar={false}
                    resultBarText={""}
                />
            ) : (
                <CclSearchDefault
                    line1="Start searching for Invitations"
                    line2="Enter User Email or enter a Date Range and click Search or click Get All"
                    hasButtons={false}
                />
            )}
        </Stack>
    );
};

export default ApplicationInvitationPanel;
