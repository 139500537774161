import * as React from "react";
import { AlertColor, Box, Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import { useAddCclTenantMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { generateGUID } from "../../../../utilities/generalUtilities";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import { AccessEventGCSystemDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";

export interface AddTenantDrawerProps {
    open: boolean;
    systemId: string;
    systemName: string;
    onClose: (msg?: string, severity?: AlertColor) => void;
}

export const AddTenantDrawer: React.FC<AddTenantDrawerProps> = (props) => {
    const [newTenantName, setNewTenantName] = React.useState<string>("");
    const [newTenantIsPrivate, setNewTenantIsPrivate] = React.useState<boolean>(false);
    const { logEvent } = useLogAccessEvent();
    const [addTenant] = useAddCclTenantMutation();

    const onAddTenant = () => {
        addTenant({
            name: newTenantName,
            tenantId: generateGUID(),
            isPrivate: newTenantIsPrivate,
            systemId: props.systemId,
        })
            .unwrap()
            .then((res: boolean) => {
                if (!res) {
                    props.onClose("Add Tenant Failed", "error");
                } else {
                    const evtData: AccessEventGCSystemDetails = {
                        systemId: props.systemId,
                        systemName: props.systemName,
                    };
                    logEvent("GCSystemChanged", evtData);
                    props.onClose("Tenant Added", "success");
                }
            })
            .catch((err: { data: any }) => {
                props.onClose("Add Tenant Failed", "error");
            });
    };

    return (
        <CclDrawer open={props.open} onClose={props.onClose} title="Add Tenant">
            <Box component="form" noValidate autoComplete="off">
                <Stack direction="column" spacing={2}>
                    <TextField
                        required
                        id="new-tenant-name"
                        label="Tenant Name"
                        variant="outlined"
                        value={newTenantName}
                        onChange={(e) => setNewTenantName(e.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newTenantIsPrivate}
                                onChange={() => setNewTenantIsPrivate(!newTenantIsPrivate)}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label="Is Private"
                    />
                    <CclDrawerActionBar>
                        <CclButton
                            onClick={() => onAddTenant()}
                            disabled={newTenantName === ""}
                            mode={"primary"}
                        >
                            Save
                        </CclButton>
                        <CclButton onClick={() => props.onClose()} mode={"secondary"}>
                            Cancel
                        </CclButton>
                    </CclDrawerActionBar>
                </Stack>
            </Box>
        </CclDrawer>
    );
};

export default AddTenantDrawer;
