import { Alert, Box } from "@mui/material";
import * as React from "react";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { useGetCclEventByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import { SessionDetailType } from "../../../../../services/types/sessionDetailTypes";
import { CclInfoTblSessionPopupTemplate } from "./cclInfoTblSessionPopupInfoTemplate";
import CclDrawerActionBar from "../../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import ComponentLoader from "../../../../../components/common/componentLoader";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import { eInfoTableValueFieldEditType } from "../../../../../components/common/cclInfoTable/cclInfoTableValueField";

interface SessionDetailDrawerProps {
    sessionId: string;
    open: boolean;
    onClose: () => void;
}

const SessionDetailDrawer: React.FC<SessionDetailDrawerProps> = (props) => {
    const { data, isLoading, isSuccess } = useGetCclEventByEventKeyQuery(props.sessionId, {
        skip: !props.sessionId,
    });
    const [sessionDetail, setSessionDetail] = React.useState<SessionDetailType | undefined>(
        undefined
    );
    const [template, setTemplate] = React.useState<CclInfoTableRowTemplate[]>(
        CclInfoTblSessionPopupTemplate
    );
    const claimsService = new UserClaimsService();
    const isAdmin = claimsService.IsUserCclAdminOrSuperAdmin();

    React.useEffect(() => {
        if (data === undefined) {
            setSessionDetail(undefined);
        } else {
            let sd: SessionDetailType = JSON.parse(JSON.stringify(data));
            sd.start = new Date(sd.start.toString().slice(0, -1));
            sd.end = new Date(sd.end.toString().slice(0, -1));
            setSessionDetail(sd);

            if (isAdmin) {
                let url = `/session/${props.sessionId}`;
                var newTemplate = [...template];
                let row = newTemplate.find((r) => r.id === "sessionCode");
                if (row != null) {
                    row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                    row.valueField.url = url;
                }
                row = newTemplate.find((r) => r.id === "title");
                if (row != null) {
                    row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                    row.valueField.url = url;
                }
                setTemplate(newTemplate);
            }
        }
    }, [data, isAdmin, props.sessionId, template]);

    if (isSuccess || isLoading) {
        return (
            <CclDrawer title={"Session Details"} open={props.open} onClose={props.onClose}>
                <Box>
                    {isLoading ? (
                        <ComponentLoader msg="" />
                    ) : sessionDetail != null ? (
                        <CclInfoTable template={template} data={sessionDetail} />
                    ) : (
                        <Alert severity="error">No data found for session</Alert>
                    )}
                </Box>
                <CclDrawerActionBar>
                    <CclButton onClick={props.onClose} mode={"primary"}>
                        Close
                    </CclButton>
                </CclDrawerActionBar>
            </CclDrawer>
        );
    }
    return <span>No Session Data Loaded</span>;
};

export default SessionDetailDrawer;
