export function generateGUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function validateEmail(email: string): boolean {
    let e: string = email.trim();
    let regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gm;

    return regex.test(e.replace(/\s/g, ""));
}

export function validateUrl(url: string): boolean {
    let u: string = decodeURIComponent(url.trim());
    let regex =
        /^(https?:\/\/)?([a-z\d-]+\.)+[a-z]{2,}(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

    return regex.test(u);
}

type UniqueField = string | number | symbol;

export function removeDuplicates<T>(array: T[], key: keyof T): T[] {
    const seen = new Set<UniqueField>();
    return array.filter((item) => {
        const value = item[key] as UniqueField;
        if (seen.has(value)) {
            return false;
        } else {
            seen.add(value);
            return true;
        }
    });
}
