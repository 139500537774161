import * as React from "react";
import { Box, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { useLazyGetGroupsByTenantIdQuery } from "../../../../services/cclTokenedGrandCentralApi";
import { Application, Tenant } from "../../../../services/types/cclGrandCentralApiTypes";
import { SendInvitationsRequest } from "../../../../services/types/rtkQueryTypes";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclDrawerActionBarButtonGrouping from "../../../../components/common/cclDrawer/cclDrawerActionBarButtonGrouping";

export interface SendInvitationsStepPanelProps {
    application: Application | null;
    tenant: Tenant | null;
    request: SendInvitationsRequest | null;
    onFinalize: () => void;
    onPreviousPanel: () => void;
    onCancel: () => void;
}

export const SendInvitationsStepPanel: React.FC<SendInvitationsStepPanelProps> = (props) => {
    const tenantName = props.tenant?.tenantName ?? "";
    const [getTenantGroups, { data: tenantGroups }] = useLazyGetGroupsByTenantIdQuery();
    const [selectedTenantGroups, setSelectedTenantGroups] = React.useState<string[]>([]);
    const [sendInvitationsClicked, setSendInvitationsClicked] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.request === null || props.request.groups.length === 0) return;
        getTenantGroups(props.request.tenantId);
    }, [getTenantGroups, props.request]);

    React.useEffect(() => {
        if (props.request === null || tenantGroups === undefined) return;
        const selected = tenantGroups.filter((g) => props.request?.groups.includes(g.groupId));
        setSelectedTenantGroups(selected.map((s) => s.name));
    }, [props.request, tenantGroups]);

    const handleSendInvitations = () => {
        setSendInvitationsClicked(true);
        props.onFinalize();
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    width: "100%",
                    borderStyle: "solid",
                    borderRadius: 3,
                    borderWidth: 1,
                    borderColor: "lightgray",
                    p: 4,
                    mt: 5,
                }}
            >
                {props.request === null || props.application === null || tenantName === "" ? (
                    <Typography sx={{ m: 2, fontWeight: "bold", textDecoration: "underline" }}>
                        Error: Invitation configuration is not set, please go back to previous tabs
                        and configure invitation settings and recipient list.
                    </Typography>
                ) : (
                    <Stack direction="row">
                        <Box width={1}>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={props.request.recipients.length}
                                        secondary="Number of Recipients"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.application.name}
                                        secondary="Application"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={tenantName} secondary="Tenant" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.request.daysValid}
                                        secondary="Days Invitation Is Valid"
                                    />
                                </ListItem>
                            </List>
                        </Box>
                        <Box width={1}>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            props.request.state === ""
                                                ? "(empty)"
                                                : props.request.state
                                        }
                                        secondary="State"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={selectedTenantGroups?.join(", ")}
                                        secondary="Groups"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.request.disableEmail ? "Yes" : "No"}
                                        secondary="Disable Email"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={props.request.autoAccept ? "Yes" : "No"}
                                        secondary="Auto Accept"
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Stack>
                )}
            </Box>
            <CclDrawerActionBar>
                <CclDrawerActionBarButtonGrouping>
                    <CclLoadingButton
                        disabled={props.request === null}
                        onClick={handleSendInvitations}
                        mode={"primary"}
                        loading={sendInvitationsClicked}
                    >
                        Send
                    </CclLoadingButton>
                    <CclButton
                        disabled={false}
                        onClick={props.onPreviousPanel}
                        sx={{ mr: 1 }}
                        mode={"secondary"}
                    >
                        Back
                    </CclButton>
                </CclDrawerActionBarButtonGrouping>
                <CclButton
                    color="primary"
                    id="cancel-btn"
                    onClick={props.onCancel}
                    mode={"secondary"}
                    sx={{ mr: 2 }}
                >
                    Cancel
                </CclButton>
            </CclDrawerActionBar>
        </React.Fragment>
    );
};

export default SendInvitationsStepPanel;
