import * as React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useSelector } from "react-redux";
import { CclPersistentDataGrid } from "../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import { EmailMessageTemplate } from "../../services/types/emailTemplateTypes";
import CreateEmailtemplateDrawer from "./EmailTemplateDetails/createTemplateDialog/createNewTemplateDrawer";
import { useDeleteEmailTemplateMutation } from "../../services/cclTokenedEmailTemplateApi";
import EditEmailtemplate from "./EmailTemplateDetails/editTemplateDialog";
import CclCommonLink from "../../components/common/cclCommonLink";
import { updateEmailTemplateGridState } from "../../app/slices/emailTemplateTableStateSlice";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import { AccessEventEmailTemplate } from "../../services/types/accessEventTypes";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import CclAlertSnackbar from "../../components/common/cclAlertSnackbar";

export interface EmailTemplatesListProps {
    emailTemplates: EmailMessageTemplate[];
    isLoading: boolean;
}

export const EmailTemplatesDataGridPro: React.FC<EmailTemplatesListProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.emailTemplateTableState);

    const { logEvent } = useLogAccessEvent();
    const claimservice = new UserClaimsService();

    const [confirmEdit, setConfirmEdit] = React.useState<boolean>(false);
    const [confirmCreate, setConfirmCreate] = React.useState<boolean>(false);
    const [resultBarText, setResultBarText] = React.useState<string>("");

    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<"error" | "success" | "warning">(
        "success"
    );

    const [data, setData] = React.useState<EmailMessageTemplate[]>([]);

    React.useEffect(() => {
        if (props.emailTemplates && props.emailTemplates.length) {
            setData(props.emailTemplates);
        } else {
            setData([]);
        }
    }, [props.emailTemplates]);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [
        deleteEmailTemplate,
        { isLoading: deleteLoading, isError: deleteError, isSuccess: deleteSuccess },
    ] = useDeleteEmailTemplateMutation();

    const updateSelectedRows = (rows: any[] | undefined) => {
        if (!rows) return;
        setSelectedRows(rows);
    };

    const doDeleteEmailTemplate = () => {
        if (!selectedRows[0]) {
            return;
        }

        deleteEmailTemplate(selectedRows[0].id)
            .unwrap()
            .then(() => {
                const evtData: AccessEventEmailTemplate = {
                    templateName: selectedRows[0].templateName,
                };
                logEvent("EmailTemplateDeleted", evtData);
            });
    };

    const COLUMNS = [
        {
            field: "templateName",
            headerName: "Name",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/emails/templates/${params.row.id}`}
                />
            ),
            type: "string",
            flex: 1,
        },
        {
            field: "trackingId",
            headerName: "Tracking ID",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/emails/templates/${params.row.id}`}
                />
            ),
            type: "string",
            flex: 0.4,
        },
        {
            field: "createdBy",
            headerName: "Created By",
            type: "string",
        },
        {
            field: "createdUtc",
            headerName: "Created",
            renderCell: (params: GridRenderCellParams) =>
                new Date(params.value).toLocaleDateString("en-US"),
            type: "date",
            flex: 0.3,
        },
        {
            field: "modifiedBy",
            headerName: "Modified By",
            type: "string",
        },
        {
            field: "modifiedUtc",
            headerName: "Modified",
            renderCell: (params: GridRenderCellParams) =>
                new Date(params.value).toLocaleDateString("en-US"),
            flex: 0.4,
            type: "date",
        },
    ];

    React.useEffect(() => {
        if (deleteError) {
            setShowSnackbar(true);
            setResultBarText("Email Template Deletion Failed");
            setSnackbarSeverity("error");
        } else if (deleteSuccess) {
            setShowSnackbar(true);
            setSnackbarSeverity("success");
            setResultBarText("Email Template Deleted");
        }
    }, [deleteError, deleteSuccess]);

    const handleClose = (showSnackBar: boolean, error?: boolean) => {
        if (!showSnackBar) {
            setConfirmCreate(false);
            return;
        }

        if (error) {
            setResultBarText("Email Template Creation Failed");
            setSnackbarSeverity("error");
        } else {
            setResultBarText("Email Template Created");
            setSnackbarSeverity("success");
        }
        setShowSnackbar(true);
        setConfirmCreate(false);
    };

    return (
        <>
            {!props.isLoading && (
                <CclPersistentDataGrid
                    columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                    data={data}
                    loading={props.isLoading}
                    stateSelector={stateSelector}
                    userPreferenceId={3}
                    getRowIdFn={(row: EmailMessageTemplate) => row.id}
                    actionCreator={updateEmailTemplateGridState}
                    miscButtons={
                        !claimservice.IsUserInAppRole(8)
                            ? []
                            : [
                                  {
                                      id: "create-template",
                                      caption: "Create Template",
                                      onClick: () => {
                                          setConfirmCreate(true);
                                      },
                                      returnSelected: true,
                                      restricted: false,
                                      disable: false,
                                  },
                                  {
                                      id: "delete-template",
                                      caption: "Delete Template",
                                      onClick: () => {
                                          doDeleteEmailTemplate();
                                      },
                                      returnSelected: true,
                                      restricted: false,
                                      loadingBtn: true,
                                      isLoading: deleteLoading,
                                      disable:
                                          selectedRows === undefined ||
                                          selectedRows.length > 1 ||
                                          selectedRows.length === 0,
                                  },
                              ]
                    }
                    initState={JSON.stringify({
                        sorting: { sortModel: [{ field: "templateName", sort: "asc" }] },
                    })}
                    showCheckboxes={true}
                    onSelectedRowsChange={(rows: any[] | undefined) => updateSelectedRows(rows)}
                    hideSettingsButtons={true}
                    hideFooter={false}
                />
            )}

            <CclAlertSnackbar
                open={showSnackbar}
                onClose={() => {
                    setShowSnackbar(false);
                }}
                message={resultBarText}
                severity={snackbarSeverity}
            />

            {!props.emailTemplates && <></>}

            {confirmCreate && (
                <CreateEmailtemplateDrawer open={confirmCreate} onClose={handleClose} />
            )}

            {confirmEdit && (
                <EditEmailtemplate
                    open={confirmEdit}
                    onClose={() => {
                        setConfirmEdit(false);
                    }}
                />
            )}
        </>
    );
};

export default EmailTemplatesDataGridPro;
