import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface ClientHomeSearchListConfigState {
    ParentName: string;
    ParentKey: number;
    ChildName: string;
    ChildKey: number;
    IsParent: boolean;
    IsChild: boolean;
    IsSearchWild: boolean;
    IsSearchDelete: boolean;
};

// Define the initial state using that type
const initialState: ClientHomeSearchListConfigState = {
    ParentName: "",
    ParentKey: 0,
    ChildName: "",
    ChildKey: 0,
    IsParent: false,
    IsChild: false,
    IsSearchWild: true,
    IsSearchDelete: false,
};

export const clientHomeSearchListConfigSlice = createSlice({
  name: 'clientHomeSearchListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateClientHomeSearchParams: (state, action: PayloadAction<ClientHomeSearchListConfigState>) => {
      state.ParentName = action.payload.ParentName;
      state.ParentKey = action.payload.ParentKey;
      state.ChildName = action.payload.ChildName;
      state.ChildKey = action.payload.ChildKey;
      state.IsParent = action.payload.IsParent;
      state.IsChild = action.payload.IsChild;
      state.IsSearchWild = action.payload.IsSearchWild;
      state.IsSearchDelete = action.payload.IsSearchWild;
    }
  }
});

export const { updateClientHomeSearchParams } = clientHomeSearchListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectClientHomeSearchListConfig = (state: RootState) => state;
export default clientHomeSearchListConfigSlice.reducer;
