import * as React from "react";
import { SxProps, TextField, Typography, Theme } from "@mui/material";

export interface CclInfoTableEditTextProps {
    value: string;
    onChange: (newText: string, required: boolean) => void;
    required: boolean;
    readonly: boolean;
    sx?: SxProps<Theme>;
}

const CclInfoTableEditText: React.FC<CclInfoTableEditTextProps> = (props) => {
    const [text, setText] = React.useState<string>(props.value);

    React.useEffect(() => {
        setText(props.value);
    }, [props.value]);

    const handleChange = (newText: string) => {
        setText(newText);
        props.onChange(newText, props.required);
    };

    if (props.readonly) {
        return <Typography sx={{ fontSize: 14 }}>{text === "" ? " " : text}</Typography>;
    }

    const fullSx = {
        ...props.sx,
        fontSize: 14,
        width: 1,
        "& .MuiInput-root": {
            marginTop: "0px",
        },
        "& .MuiInputLabel-root.Mui-error": {
            marginTop: "-16px",
        },
        "& .MuiInputLabel-root.Mui-focused": {
            marginTop: "-8px",
        },
    };

    return (
        <TextField
            id="info-text-input"
            value={text}
            error={props.required && !text ? true : false}
            label={props.required && !text ? "Required" : ""}
            onChange={(e) => handleChange(e.target.value)}
            variant={"standard"}
            sx={fullSx}
            disabled={props.readonly}
        />
    );
};

export default CclInfoTableEditText;
