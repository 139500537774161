import { Box, CSSObject, Drawer, Theme, styled } from "@mui/material";
import * as React from "react";
import SideNavBar from "../../components/navigation/sideNavBar";
import MuiDrawer from "@mui/material/Drawer";

const drawerWidth = 160;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 40px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 40px)`,
    },
});

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    })
);

export interface LeftnavProps {
    smallScreen: boolean;
    desktopOpen: boolean;
    mobileOpen: boolean;
    handleMobileDrawerToggle: () => void;
}

export const Leftnav: React.FC<LeftnavProps> = ({
    smallScreen,
    desktopOpen,
    mobileOpen,
    handleMobileDrawerToggle,
}) => {
    return (
        <Box
            height="calc(100vh - 110px)"
            component="nav"
            sx={{
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
                zIndex: 99,
            }}
            aria-label="ccl navigation"
        >
            {smallScreen ? (
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleMobileDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: "block",
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    <SideNavBar open={true} />
                </Drawer>
            ) : (
                <StyledDrawer variant="permanent" open={desktopOpen}>
                    <SideNavBar open={desktopOpen} />
                </StyledDrawer>
            )}
        </Box>
    );
};

export default Leftnav;
