import { Alert, Box } from "@mui/material";
import * as React from "react";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { useGetCclEventByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import { SessionDetailType } from "../../../../../services/types/sessionDetailTypes";
import CclDrawerActionBar from "../../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import ComponentLoader from "../../../../../components/common/componentLoader";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import { eInfoTableValueFieldEditType } from "../../../../../components/common/cclInfoTable/cclInfoTableValueField";
import { CalendarEvent } from "../../../../../services/types/enterpriseCoachingCalendarApiTypes";
import { cclInfoTblCalendarEventTemplate } from "./cclInfoTblCalendarEventTemplate";

type CalendarEventTemplateData = {
    ceBookableResourceBookingId: string;
    eventTitle: string;
    appointmentStatus: string;
    bookingType: string;
    resourceType: string;
    sessionCode: string;
    customer: string;
    projectManager: string;
    start: Date | null;
    end: Date | null;
    code: string;
    sessionFormat: string;
    managingLocation: string;
    deliveryLocation: string;
    hasCompassSku: boolean;
};

interface CalendarEventDetailDrawerProps {
    event: CalendarEvent;
    open: boolean;
    onClose: () => void;
}

const CalendarEventDetailDrawer: React.FC<CalendarEventDetailDrawerProps> = (props) => {
    const { data, isLoading } = useGetCclEventByEventKeyQuery(props.event.esKey?.toString() ?? "", {
        skip: props.event.esKey === null,
    });
    const [template, setTemplate] = React.useState<CclInfoTableRowTemplate[]>(
        cclInfoTblCalendarEventTemplate
    );
    const claimsService = new UserClaimsService();
    const isAdmin = claimsService.IsUserCclAdminOrSuperAdmin();
    const persistentFields = [
        "ceBookableResourceBookingId",
        "eventTitle",
        "appointmentStatus",
        "bookingType",
        "resourceType",
        "sessionCode",
    ];

    function generateTemplateData(
        calevt: CalendarEvent,
        session: SessionDetailType | null
    ): CalendarEventTemplateData {
        const tmpltData: CalendarEventTemplateData = {
            ceBookableResourceBookingId: calevt.ceBookableResourceBookingId ?? "",
            eventTitle: session?.title ?? calevt.eventTitle,
            appointmentStatus: calevt.appointmentStatus,
            bookingType: calevt.bookingType,
            resourceType: calevt.resourceType,
            sessionCode: session?.sessionCode ?? calevt.esBudgetCode,
            customer: session?.customer ?? "",
            projectManager: session?.projectManager ?? "",
            start: session?.start ?? null,
            end: session?.end ?? null,
            code: session?.code ?? "",
            sessionFormat: session?.sessionFormat ?? "",
            managingLocation: session?.managingLocation ?? "",
            deliveryLocation: session?.deliveryLocation ?? "",
            hasCompassSku: session?.hasCompassSku ?? false,
        };
        return tmpltData;
    }

    React.useEffect(() => {
        if (data === undefined) {
            var newTemplate = [
                ...cclInfoTblCalendarEventTemplate.filter((r) => persistentFields.includes(r.id)),
            ];
            setTemplate(newTemplate);
        } else {
            var newSessionTemplate = [...cclInfoTblCalendarEventTemplate];
            if (isAdmin) {
                let url = `/session/${props.event.esKey}`;
                let row = newSessionTemplate.find((r) => r.id === "sessionCode");
                if (row != null) {
                    row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                    row.valueField.url = url;
                }
                row = newSessionTemplate.find((r) => r.id === "eventTitle");
                if (row != null) {
                    row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                    row.valueField.url = url;
                }
                setTemplate(newSessionTemplate);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isAdmin, props.event]);

    return (
        <CclDrawer title={"Details"} open={props.open} onClose={props.onClose}>
            {isLoading ? (
                <Box>
                    <ComponentLoader msg="" />
                </Box>
            ) : (
                <Box>
                    <CclInfoTable
                        template={template}
                        data={generateTemplateData(props.event, data ?? null)}
                    />
                    {data == null ? (
                        <Alert severity="info" sx={{ mt: 3 }}>
                            No session is associated with this booking.
                        </Alert>
                    ) : null}
                </Box>
            )}
            <CclDrawerActionBar>
                <CclButton onClick={props.onClose} mode={"primary"}>
                    Close
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default CalendarEventDetailDrawer;
