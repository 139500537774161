import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import CclButton from "../cclButtons/cclButton";
import ReplayIcon from "@mui/icons-material/Replay";

interface CclTextOrDoubleTextSearchBarProps {
    initialFieldOneSearchTerm: string;
    fieldOneSearchLabel: string;
    initialFieldTwoSearchTerm: string;
    fieldTwoSearchLabel: string;
    initialFieldThreeSearchTerm: string;
    fieldThreeSearchLabel: string;
    executeSearch: (
        fieldOneSearchTerm: string,
        fieldTwoSearchTerm: string,
        fieldThreeSearchTerm: string
    ) => void;
    errorStateChange?: (error: boolean) => void;
    requireBothFields: boolean;
    suppressErrors: boolean;
    clearSearchTrigger?: () => void;
    showResetButton?: boolean;
}

const CclTextOrDoubleTextSearchBar: React.FC<CclTextOrDoubleTextSearchBarProps> = (props) => {
    const [lastFieldChanged, setLastFieldChanged] = React.useState<1 | 2 | 3>(1);
    const [fieldOneSearchString, setFieldOneSearchString] = React.useState<string>(
        props.initialFieldOneSearchTerm ?? ""
    );
    const [fieldTwoSearchString, setFieldTwoSearchString] = React.useState<string>(
        props.initialFieldTwoSearchTerm ?? ""
    );
    const [fieldThreeSearchString, setFieldThreeSearchString] = React.useState<string>(
        props.initialFieldThreeSearchTerm ?? ""
    );

    React.useEffect(() => {
        setFieldOneSearchString(props.initialFieldOneSearchTerm ?? "");
    }, [props.initialFieldOneSearchTerm]);

    React.useEffect(() => {
        setFieldTwoSearchString(props.initialFieldTwoSearchTerm ?? "");
    }, [props.initialFieldTwoSearchTerm]);

    React.useEffect(() => {
        setFieldThreeSearchString(props.initialFieldThreeSearchTerm ?? "");
    }, [props.initialFieldThreeSearchTerm]);

    const searchInputSx = {
        "& .MuiOutlinedInput-input": {
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
        flexGrow: 1,
    };

    const enableSearchButton = (): boolean => {
        if (lastFieldChanged === 1) {
            return fieldOneSearchString !== "";
        } else {
            return fieldTwoSearchString !== "" || fieldThreeSearchString !== "";
        }
    };

    const executeSearch = async (fieldNo: number) => {
        switch (fieldNo) {
            case 1: {
                if (fieldOneSearchString !== "") {
                    props.executeSearch(fieldOneSearchString, "", "");
                    setFieldTwoSearchString("");
                    setFieldThreeSearchString("");
                }
                break;
            }
            case 2:
            case 3: {
                if (fieldTwoSearchString !== "" || fieldThreeSearchString !== "") {
                    props.executeSearch("", fieldTwoSearchString, fieldThreeSearchString);
                    setFieldOneSearchString("");
                }
                break;
            }
        }
    };

    const handleSearchButtonClick = async () => {
        executeSearch(lastFieldChanged);
    };

    const handleKeywordSearchKeyPress = async (
        evt: React.KeyboardEvent<HTMLDivElement>,
        fieldNo: number
    ) => {
        if (evt.key === "Enter") {
            evt.preventDefault();
            executeSearch(fieldNo);
        }
    };

    const clearSearchArguments = () => {
        setFieldOneSearchString("");
        setFieldTwoSearchString("");
        setFieldThreeSearchString("");
        if (props.clearSearchTrigger) {
            props.clearSearchTrigger();
        }
    };

    return (
        <Box
            gap={2}
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <TextField
                value={fieldOneSearchString === "*" ? "" : fieldOneSearchString}
                sx={searchInputSx}
                id="outlined-adornment-keyword-search-field-one"
                label={props.fieldOneSearchLabel ?? "Field One Search Term"}
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                    e.preventDefault();
                    setLastFieldChanged(1);
                    setFieldOneSearchString(e.target.value);
                }}
                onKeyDown={(e) => handleKeywordSearchKeyPress(e, 1)}
            />
            <Typography sx={{ color: "text.secondary" }}>Or</Typography>
            <TextField
                value={fieldTwoSearchString === "*" ? "" : fieldTwoSearchString}
                sx={searchInputSx}
                id="outlined-adornment-keyword-search-field-two"
                label={props.fieldTwoSearchLabel ?? "Field Two Search Term"}
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                    e.preventDefault();
                    setLastFieldChanged(2);
                    setFieldTwoSearchString(e.target.value);
                }}
                onKeyDown={(e) => handleKeywordSearchKeyPress(e, 2)}
            />

            <TextField
                value={fieldThreeSearchString === "*" ? "" : fieldThreeSearchString}
                sx={searchInputSx}
                id="outlined-adornment-keyword-search-field-three"
                label={props.fieldThreeSearchLabel ?? "Field Three Search Term"}
                variant="outlined"
                fullWidth={true}
                onChange={(e) => {
                    e.preventDefault();
                    setLastFieldChanged(3);
                    setFieldThreeSearchString(e.target.value);
                }}
                onKeyDown={(e) => handleKeywordSearchKeyPress(e, 3)}
            />

            {props.showResetButton && (
                <CclButton
                    mode="secondary"
                    sx={{
                        borderRadius: "100%",
                        maxWidth: "15px",
                        minWidth: "10px",
                        pl: "20px",
                        pr: "20px",
                        lineHeight: "20px",
                        letterSpacing: "0.1px",
                        gap: "6px",
                        padding: "8px 4px",
                    }}
                    onClick={clearSearchArguments}
                >
                    <ReplayIcon fontSize="small" />
                </CclButton>
            )}

            <CclButton
                onClick={handleSearchButtonClick}
                aria-label="Search"
                size="small"
                sx={{ flexShrink: 0, whiteSpace: "nowrap" }}
                disabled={!enableSearchButton()}
                mode={"primary"}
            >
                Search
            </CclButton>
        </Box>
    );
};

export default CclTextOrDoubleTextSearchBar;
