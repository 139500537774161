import React, { useEffect } from "react";
import { AlertColor, Autocomplete, TextField, Box } from "@mui/material";
import { AssessmentListItem, DocumentList } from "../../../../../services/types/sessionDetailTypes";
import { useLazyGetAIKeybyDocKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclDrawerActionBarButtonGrouping from "../../../../../components/common/cclDrawer/cclDrawerActionBarButtonGrouping";

interface AddDocumentDrawerProps {
    open: boolean;
    esKey: number;
    onCancel: () => void;
    onAdd: (newItem: AssessmentListItem) => void;
    onViewDocument: (docId: number) => void;
    documentOptionsList: DocumentList[];
    showSnackbar: (message: string, severity: AlertColor) => void;
}

const AddDocumentDrawer: React.FC<AddDocumentDrawerProps> = (props) => {
    const [selectedDocumentValue, setSelectedDocumentValue] = React.useState<
        string | undefined | null
    >(props.documentOptionsList?.length > 0 ? props.documentOptionsList[0].documentName : null);
    const [getDocsAiKey] = useLazyGetAIKeybyDocKeyQuery();

    useEffect(() => {
        if (
            selectedDocumentValue == null &&
            props.documentOptionsList != null &&
            props.documentOptionsList.length > 0
        ) {
            setSelectedDocumentValue(props.documentOptionsList[0].documentName);
        }
    }, [props.documentOptionsList, selectedDocumentValue]);

    const textInputSx = {
        "& .MuiOutlinedInput-root": {
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
    };

    const getDefaultDocumentValue = (): string | null => {
        return props.documentOptionsList != null && props.documentOptionsList.length > 0
            ? props.documentOptionsList[0].documentName
            : null;
    };

    const getSelectedDocumentId = (): number => {
        if (props.documentOptionsList == null || props.documentOptionsList.length === 0) return -1;

        const docs = props.documentOptionsList.filter(
            (d) => d.documentName === selectedDocumentValue
        );

        return docs && docs.length > 0 ? docs[0].documentID : -1;
    };

    const close = () => {
        setSelectedDocumentValue(getDefaultDocumentValue());
        props.onCancel();
    };

    const onDocumentChange = (e: any, newValue: any) => {
        if (newValue == null) {
            setSelectedDocumentValue(getDefaultDocumentValue());
            return;
        }
        setSelectedDocumentValue(newValue);
    };

    const addDocument = () => {
        if (selectedDocumentValue) {
            const docs = props.documentOptionsList.filter(
                (d) => d.documentName === selectedDocumentValue
            );
            if (docs && docs.length > 0) {
                const doc = docs[0];
                getDocsAiKey(doc.documentID)
                    .unwrap()
                    .then((aidata) => {
                        if (aidata && aidata?.aI_Key > 0) {
                            let item: AssessmentListItem = {
                                esKey: props.esKey,
                                bPI_Key: 0,
                                bPI_Seq_Num: 1,
                                bPIT_Key: 2,
                                cclMasterKey: doc.documentID,
                                assessmentType: "Document",
                                instrumentTypeKey: 0,
                                cclMasterRaterKey: 0,
                                title: doc.documentName,
                                status: "Add",
                                groupReportRequested: false,
                                aggregateReportRequested: false,
                                aggregateReportAvailable: false,
                                groupReportAvailable: false,
                                docA_Path: "",
                                assetKey: aidata?.aI_Key,
                            };

                            props.onAdd(item);
                        } else {
                            props.showSnackbar("Assessments & Documents Update Failed", "error");
                        }
                    })
                    .catch(() => {
                        props.showSnackbar("Assessments & Documents Update Failed", "error");
                    });
            }
        }
    };

    return (
        <CclDrawer title={"Add Document(s)"} open={props.open} onClose={close}>
            <Box sx={{ paddingTop: 1 }}>
                <Autocomplete
                    disabled={props.documentOptionsList == null}
                    disablePortal={false}
                    id="combo-document"
                    value={selectedDocumentValue}
                    onChange={(e: any, newValue: any) => onDocumentChange(e, newValue)}
                    options={props.documentOptionsList.map((option) => option.documentName)}
                    sx={{ width: 1 }}
                    renderInput={(params) => (
                        <TextField {...params} sx={textInputSx} label="Document List" />
                    )}
                    disableClearable={
                        props.documentOptionsList[0] == null ||
                        selectedDocumentValue === getDefaultDocumentValue()
                    }
                />
            </Box>
            <CclDrawerActionBar>
                <CclDrawerActionBarButtonGrouping>
                    <CclButton
                        disabled={
                            selectedDocumentValue == null ||
                            selectedDocumentValue === props.documentOptionsList[0].documentName
                        }
                        onClick={addDocument}
                        mode={"primary"}
                    >
                        Add
                    </CclButton>
                    <CclButton
                        disabled={
                            selectedDocumentValue == null ||
                            selectedDocumentValue === props.documentOptionsList[0].documentName
                        }
                        onClick={() => props.onViewDocument(getSelectedDocumentId())}
                        mode={"primary"}
                    >
                        View Document
                    </CclButton>
                </CclDrawerActionBarButtonGrouping>
                <CclButton onClick={close} mode={"secondary"}>
                    Close
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default AddDocumentDrawer;
