import * as React from "react";
import { Alert, Card, CardHeader, Stack } from "@mui/material";
import {
    useGetCclEventByEventKeyQuery,
    useGetSessionOptionsByEventKeyQuery,
    useSetEventSessionOptionMutation,
} from "../../../../../services/cclTokenedSessionApi";
import {
    SessionEventOption,
    SetSessionEventOptionRequest,
} from "../../../../../services/types/sessionOptionsType";
import ComponentLoader from "../../../../../components/common/componentLoader";
import { UserClaimsService } from "../../../../../services/currentUserService/currentUserService";
import { useSendLookingGlassInvitationsMutation } from "../../../../../services/cclTokenedGrandCentralApi";
import { SendLookingGlassInvitationRequest } from "../../../../../services/types/rtkQueryTypes";
import { useGetCclParticipantsByEventKeyQuery } from "../../../../../services/cclTokenedEnterpriseParticipantApi";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import CclAlertSnackbar from "../../../../../components/common/cclAlertSnackbar";

interface ActivateLookingGlassCardProps {
    sessionId: string;
}

const ActivateLookingGlassCard: React.FC<ActivateLookingGlassCardProps> = (props) => {
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const [alertMsg, setAlertMsg] = React.useState<string>("Loading...");
    const [openSnackbar, setOpenSnackbar] = React.useState<boolean>(false);
    const { data: sessionDetails, isSuccess: detailsSuccess } = useGetCclEventByEventKeyQuery(
        props.sessionId,
        { skip: props.sessionId === "" }
    );
    const { data: sessionOptions, isSuccess: sessionOptionsSuccess } =
        useGetSessionOptionsByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });
    const [
        setLookingGlassOption,
        { isSuccess: isOptionSuccess, isLoading: isOptionLoading, isError: isOptionError },
    ] = useSetEventSessionOptionMutation();
    const [
        sendLookingGlassInvitations,
        {
            data: lookingGlassInvitationResponse,
            isSuccess: lookingGlassInvitationsIsSuccess,
            isLoading: lookingGlassInvitationsIsLoading,
            isError: lookingGlassInvitationsIsError,
        },
    ] = useSendLookingGlassInvitationsMutation();
    const { logEvent } = useLogAccessEvent();
    const { data: allParticipants } = useGetCclParticipantsByEventKeyQuery(props.sessionId, {
        skip: props.sessionId === "",
    });
    const userClaimsService = new UserClaimsService();

    React.useEffect(() => {
        if (
            lookingGlassInvitationsIsSuccess &&
            lookingGlassInvitationResponse != null &&
            lookingGlassInvitationResponse.numberFailed === 0
        )
            console.log("test");
        else if (
            lookingGlassInvitationsIsError ||
            (lookingGlassInvitationResponse != null &&
                lookingGlassInvitationResponse.numberFailed > 0)
        )
            console.log("test");
        // eslint-disable-next-line
    }, [
        lookingGlassInvitationsIsSuccess,
        lookingGlassInvitationsIsLoading,
        lookingGlassInvitationsIsError,
    ]);

    React.useEffect(() => {
        if (isOptionSuccess) {
            if (sessionDetails != null && allParticipants != null && allParticipants.length > 0) {
                const payload: SendLookingGlassInvitationRequest = {
                    recipients: allParticipants.map((p) => {
                        return {
                            email: p.emailAddress,
                            firstName: p.firstName,
                            lastName: p.lastName,
                        };
                    }),
                    disableEmail: true,
                    autoAccept: true,
                    ignorePendingInvites: true,
                };
                sendLookingGlassInvitations(payload);
            }
        }
        // eslint-disable-next-line
    }, [isOptionSuccess, isOptionLoading, isOptionError]);

    React.useEffect(() => {
        if (sessionOptionsSuccess && sessionOptions != null) {
            const lookingglassopt = sessionOptions.sessionEventOptions.find(
                (opt) => opt.optionId === 4
            );
            const active = lookingglassopt != null && lookingglassopt.active;
            setIsActive(active);
            setAlertMsg(active ? "Looking Glass is active" : "Looking Glass is not active");
        }
        // eslint-disable-next-line
    }, [sessionOptions]);

    const onSaveClick = () => {
        //safety, should never happen
        if (sessionDetails == null) return;

        const option: SessionEventOption = {
            optionId: 4, //Note this is not the digital tool key, but the event option type key, which is 4 for looking glass
            optionName: "Looking Glass",
            parentOptionId: null,
            active: true,
            scheduled: false,
            scheduledActivation: null,
            changedBy: userClaimsService.GetCurrentUserEmail(),
            changedDate: new Date(),
        };

        const request: SetSessionEventOptionRequest = {
            eskey: sessionDetails.sessionKey.toString(),
            eventOption: option,
        };

        setAlertMsg("Activating Looking Glass...");
        setLookingGlassOption(request)
            .unwrap()
            .then(() => {
                setOpenSnackbar(true);
                let tag: string = "LookingGlassSimulationActivated";
                const evtData: AccessEventSessionDetails = {
                    sessionID: sessionDetails?.sessionKey.toString() ?? 0,
                    projectCode: sessionDetails?.sessionCode ?? "",
                };

                logEvent(tag, evtData);
            });
    };

    if (detailsSuccess) {
        return (
            <Card sx={{ height: "100%", width: "100%", p: 2 }}>
                <CardHeader title={"Activate Looking Glass"} sx={{ p: 1 }} />
                <CclAlertSnackbar
                    open={openSnackbar}
                    onClose={() => setOpenSnackbar(false)}
                    severity={isOptionError ? "error" : "success"}
                    message={
                        isOptionError
                            ? "Looking Glass Activation Failed"
                            : "Looking Glass Activated"
                    }
                />
                <Stack direction="column" sx={{ alignItems: "center" }}>
                    <Alert severity={"info"} sx={{ width: "100%" }}>
                        {alertMsg}
                    </Alert>
                    <CclButton
                        disabled={isActive}
                        sx={{ m: 2, mr: "auto", alignItems: "center", width: "fit-content" }}
                        onClick={() => onSaveClick()}
                        mode={"primary"}
                    >
                        Activate Looking Glass
                    </CclButton>
                </Stack>
            </Card>
        );
    }

    return <ComponentLoader msg={"Loading Looking Glass Management Info"} />;
};

export default ActivateLookingGlassCard;
