import * as React from "react";
import { GridColumns, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { Participant } from "../../services/types/enterpriseParticipantApiTypes";
import { updateRegistrationGridState } from "../../app/slices/registrationListTableStateSlice";
import { CclPersistentDataGrid } from "../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { useSelector } from "react-redux";
import { addCustomDataGridFilters } from "../../components/common/customDataGridFilters/customDataGridFilters";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CclCommonLink from "../../components/common/cclCommonLink";

function getFullName(params: any) {
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

const COLUMNS = [
    {
        field: "esiKey",
        headerName: "Registration ID",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/registration/${params.row.esiKey}`}
            />
        ),
        width: 150,
    },
    {
        field: "projectCode",
        headerName: "Project ID",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/session/${params.row.esKey}`}
            />
        ),
        width: 150,
    },
    {
        field: "name",
        headerName: "Participant",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`/registration/${params.row.esiKey}`}
            />
        ),
        flex: 0.25,
        valueGetter: getFullName,
    },
    {
        field: "emailAddress",
        headerName: "Email",
        type: "string",
        renderCell: (params: GridRenderCellParams) => (
            <CclCommonLink
                text={params.value}
                keyVal={params.value}
                to={`mailto:${params.value}`}
                type={"email"}
            />
        ),
        flex: 0.5,
    },
    {
        field: "sessionType",
        headerName: "Type",
        type: "string",
        width: 175,
    },
    {
        field: "registrationStatus",
        headerName: "Status",
        width: 150,
        renderCell: (params: GridRenderCellParams) =>
            params.value === "Cancelled" ? (
                <>
                    Cancelled
                    <ErrorOutlineIcon color="error" fontSize="small" sx={{ pl: 1 }} />
                </>
            ) : (
                `${params.value}`
            ),
    },
    {
        field: "startDate",
        headerName: "Start",
        valueGetter: (params: any) => new Date(params.row.startDate),
        type: "date",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.value.toLocaleDateString()}</span>
        ),
        width: 125,
    },
    {
        field: "sessionHostPlatform",
        headerName: "Hosting Platform",
        renderCell: (params: GridRenderCellParams) => <span>{params.row.sessionHostPlatform}</span>,
        width: 200,
    },
    {
        field: "sessionAssessmentPlatform",
        headerName: "Assessment Platform",
        renderCell: (params: GridRenderCellParams) => (
            <span>{params.row.sessionAssessmentPlatform}</span>
        ),
        width: 200,
    },
    {
        field: "sessionHasCompassSku",
        headerName: "Compass",
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.sessionHasCompassSku ? "Yes" : "No"}`,
        width: 85,
    },
];

export interface RegistrationListProps {
    registrations: Participant[] | undefined;
    loading: boolean;
    hideToolbar?: boolean;
    showResultBar?: boolean;
    resultBarText: string;
}

export const RegistrationsDataGridPro: React.FC<RegistrationListProps> = (props) => {
    const stateSelector = useSelector((state: any) => state.registrationListTableState);

    return (
        <CclPersistentDataGrid
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            data={props.registrations ?? []}
            loading={props.loading}
            stateSelector={stateSelector}
            userPreferenceId={2}
            getRowIdFn={(row: Participant) => row.esiKey}
            actionCreator={updateRegistrationGridState}
            hideToolbar={props.hideToolbar === true}
            initState={JSON.stringify({
                sorting: { sortModel: [{ field: "startDate", sort: "desc" }] },
            })}
            resultBar={props.showResultBar ?? false}
            resultBarText={props.resultBarText}
            hideFooter={false}
        />
    );
};

export default RegistrationsDataGridPro;
