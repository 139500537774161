import * as React from "react";
import { Box, Card, CardHeader, List, Stack, Typography } from "@mui/material";
import StyledDropzone from "./styledDropzone";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import { PersonInfo } from "../../../../../models/personInfo";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import UploadFileListItem from "./uploadFileListItem";
import CclDrawerActionBar from "../../../../../components/common/cclDrawer/cclDrawerActionBar";

type FileUploadItem = {
    file: File;
    uploadAttempted: boolean;
    uploadSuccess: boolean;
};

export interface UploadFilesStepPanelProps {
    sessionId: string;
    projectCode: string;
    isSessionFile: boolean;
    people: PersonInfo[];
    personType: string;
    sessionCode: string;
    imKeys: number[];
    onFinalize: () => void;
    onPreviousPanel: () => void;
    onCancel: () => void;
}

export const UploadFilesStepPanel: React.FC<UploadFilesStepPanelProps> = (props) => {
    const [fileUploadItemsList, setFileUploadItemsList] = React.useState<FileUploadItem[]>([]);
    const { logEvent } = useLogAccessEvent();

    const enableUpload =
        props.imKeys.length <= 0
            ? false
            : fileUploadItemsList.filter((f) => !f.uploadAttempted).length <= 0;

    const assetProperties: { [key: string]: string } = {
        ProgramId: `${props.sessionId}`,
        ProgramCode: `${props.sessionCode}`,
    };

    const addFile = (acceptedFiles: File[]) => {
        const newuploaditems = acceptedFiles.map((f) => {
            return {
                file: f,
                id: "none", // will be a guid after first chunk uploaded
                uploadAttempted: false,
                uploadSuccess: false,
            };
        });
        setFileUploadItemsList((prev) => [...prev, ...newuploaditems]);
    };

    const removeFile = (file: File) => {
        const newFileItemsList = fileUploadItemsList.filter((item) => item.file.name !== file.name);
        setFileUploadItemsList(newFileItemsList);
    };

    const fileUploadResult = (file: File, status: boolean) => {
        const updatedUploadItems = fileUploadItemsList.map((ui) => {
            if (ui.file.name === file.name) {
                return { ...ui, uploadSuccess: status };
            }
            return ui;
        });
        setFileUploadItemsList(updatedUploadItems);
        startNextUpload(false);
    };

    const startNextUpload = (logUploadEvent: boolean) => {
        // get the next file to upload (alpabetical by those with attempted == false)
        const itemsToUpload = fileUploadItemsList
            .filter((ui) => !ui.uploadAttempted)
            .sort((a, b) => {
                return a.file.name.localeCompare(b.file.name);
            });

        if (itemsToUpload == null || itemsToUpload.length <= 0) return;

        const updatedUploadItems = fileUploadItemsList.map((ui) => {
            if (ui.file.name === itemsToUpload[0].file.name) {
                return { ...ui, uploadAttempted: true };
            }
            return ui;
        });

        setFileUploadItemsList(updatedUploadItems);
        // only log the button press, not individual uploads
        if (logUploadEvent) {
            const evtData: any = {
                projectCode: props.projectCode ?? "",
            };
            const tag = props.isSessionFile
                ? "IndividualFilesUploaded"
                : "ParticipantFilesUploaded";

            logEvent(tag, evtData);
        }
    };

    return (
        <>
            <Box width={1} sx={{ p: 2 }}>
                <Stack direction="column" spacing={2} width={1}>
                    <Card sx={{ width: 1 }}>
                        <CardHeader
                            title={"Select Files For Upload"}
                            sx={{ mt: 1, pt: 2, mb: 0, pb: 2 }}
                        />
                        <Stack
                            spacing={3}
                            sx={{ pl: 3, pr: 3, pt: 1, maxHeight: "calc(100%-10px)" }}
                        >
                            <StyledDropzone
                                disabled={false}
                                onAddFile={addFile}
                                maxFilesToUpload={10} //maxFilesToUpload}
                            />
                            <Stack
                                width={1}
                                direction="row"
                                display="flex"
                                justifyContent="space-between"
                                sx={{ pl: 2, pr: 2 }}
                            >
                                <CclButton
                                    size="small"
                                    onClick={() => startNextUpload(true)}
                                    disabled={enableUpload}
                                    mode={"primary"}
                                >
                                    Upload Files
                                </CclButton>
                                <CclButton
                                    disabled={fileUploadItemsList.length === 0}
                                    size="small"
                                    onClick={() => setFileUploadItemsList([])}
                                    mode={"secondary"}
                                >
                                    Clear Files
                                </CclButton>
                            </Stack>
                            <Typography variant="subtitle1" sx={{ pl: 2, pr: 2 }}>
                                Files To Upload
                            </Typography>
                            <List sx={{ mt: 0, pb: 0, borderBottom: "1px solid lightgray" }}>
                                {fileUploadItemsList &&
                                    fileUploadItemsList.length > 0 &&
                                    fileUploadItemsList.map((item) => (
                                        <UploadFileListItem
                                            eskey={props.sessionId}
                                            key={item.file.name}
                                            file={item.file}
                                            imkeys={props.imKeys}
                                            properties={assetProperties}
                                            makeAvailable={props.personType !== "participant"}
                                            startUpload={item.uploadAttempted}
                                            deleteFile={removeFile}
                                            onUploadResult={fileUploadResult}
                                            projectCode={props.projectCode}
                                        />
                                    ))}
                            </List>
                        </Stack>
                    </Card>
                </Stack>
            </Box>
            <CclDrawerActionBar>
                <CclButton
                    color="primary"
                    id="cancel-btn"
                    onClick={props.onCancel}
                    mode={"primary"}
                >
                    Close
                </CclButton>
                <CclButton
                    mode={"secondary"}
                    disabled={false}
                    sx={{ mr: 1 }}
                    onClick={props.onPreviousPanel}
                >
                    Back
                </CclButton>
            </CclDrawerActionBar>
        </>
    );
};

export default UploadFilesStepPanel;
