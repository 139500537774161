import * as React from "react";
import { Grid } from "@mui/material";
import ArchivedProductSalesDataGrid from "./archivedProductSalesDataGrid";
import ArchivedParticipantsDataGrid from "./archivedParticipantsDataGrid";
import CclGenericAccordian from "../../../../../components/common/cclGenericAccordian";
import FlexGrid from "../../../../../layouts/flexGrid";

interface ArchivedPanelProps {
    coachEmail: string;
    coachImKey: number;
    includeSessionParticipants: boolean;
}

const ArchivedPanel: React.FC<ArchivedPanelProps> = (props) => {
    return props.includeSessionParticipants ? (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <CclGenericAccordian headingText={"Archived Participants"} sx={{ height: "100%" }}>
                    <FlexGrid>
                        <ArchivedParticipantsDataGrid coachImKey={props.coachImKey} />
                    </FlexGrid>
                </CclGenericAccordian>
            </Grid>
            <Grid item xs={12} md={6}>
                <CclGenericAccordian headingText={"Archived Product Sales"} sx={{ height: "100%" }}>
                    <FlexGrid>
                        <ArchivedProductSalesDataGrid facilitatorEmail={props.coachEmail} />
                    </FlexGrid>
                </CclGenericAccordian>
            </Grid>
        </Grid>
    ) : (
        <FlexGrid>
            <ArchivedProductSalesDataGrid facilitatorEmail={props.coachEmail} />
        </FlexGrid>
    );
};

export default ArchivedPanel;
