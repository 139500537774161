import * as React from "react";
import {
    Card,
    CardHeader,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
} from "@mui/material";
import { PersonInfo } from "../../../../../models/personInfo";
import { Participant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import { SessionDetailAssignedCoach } from "../../../../../services/types/sessionDetailTypes";
import CclButton from "../../../../../components/common/cclButtons/cclButton";

interface NwaCoachAssignmentParticipantListProps {
    allParticipants: Participant[];
    assignedCoaches: SessionDetailAssignedCoach[];
    selectedCoach: PersonInfo | null;
    onPaxChange: (
        paxImKey: number,
        coachImKey: number,
        isPrimary: boolean,
        selected: boolean
    ) => void | undefined;
    assignAllToCoach?: (coach: PersonInfo) => void;
}

export type ParticipantListItem = {
    coachInfo: PersonInfo | null;
    otherResourcesInfo: PersonInfo[];
    info: PersonInfo;
};

const NwaCoachAssignmentParticipantList: React.FC<NwaCoachAssignmentParticipantListProps> = (
    props
) => {
    const showParticipantsAssignedToOthers = false;
    const [fullParticipantList, setFullParticipantList] = React.useState<ParticipantListItem[]>([]);
    const [filteredParticipantList, setFilteredParticipantList] = React.useState<
        ParticipantListItem[]
    >([]);
    const [selectedCoachParticipantCount, setSelectedCoachParticipantCount] =
        React.useState<number>(0);
    const [filter, setFilter] = React.useState<string>("");

    React.useEffect(() => {
        let paxlist: ParticipantListItem[] = [];

        if (props.allParticipants && props.allParticipants.length > 0) {
            paxlist = props.allParticipants.map((p) => {
                return {
                    coachInfo: null,
                    otherResourcesInfo: [],
                    info: { imKey: p.imKey, firstName: p.firstName, lastName: p.lastName },
                };
            });

            if (props.assignedCoaches && props.assignedCoaches.length > 0) {
                props.assignedCoaches.forEach((c) => {
                    let pax = paxlist.find((p) => p.info.imKey === c.paxImKey);
                    if (pax != null) {
                        pax.otherResourcesInfo.push({
                            imKey: c.imKey,
                            firstName: c.firstName,
                            lastName: c.lastName,
                        });
                    }
                });
            }
        }
        setFullParticipantList(
            paxlist.sort((a, b) => {
                return (
                    a.info.firstName.localeCompare(b.info.firstName) ||
                    a.info.lastName.localeCompare(b.info.lastName)
                );
            })
        );
        const paxCount: number = paxlist.filter(
            (p) => p.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) != null
        ).length;
        setSelectedCoachParticipantCount(paxCount);
        // eslint-disable-next-line
    }, [props.allParticipants, props.assignedCoaches]);

    React.useEffect(() => {
        const filteredParticipants =
            filter.length === 0
                ? fullParticipantList
                : fullParticipantList.filter(
                      (pax) =>
                          pax.info.lastName.toLowerCase().includes(filter) ||
                          pax.info.firstName.toLowerCase().includes(filter)
                  );

        if (showParticipantsAssignedToOthers) {
            setFilteredParticipantList(
                filteredParticipants.sort((a, b) => {
                    return (
                        a.info.firstName.localeCompare(b.info.firstName) ||
                        a.info.lastName.localeCompare(b.info.lastName)
                    );
                })
            );
            return;
        }

        const finalPartipantList = filteredParticipants.filter((pax) => {
            return (
                pax.otherResourcesInfo.length === 0 ||
                pax.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) !== null
            );
        });
        setFilteredParticipantList(finalPartipantList);

        setSelectedCoachParticipantCount(
            fullParticipantList.filter(
                (p) =>
                    p.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) != null
            ).length
        );

        // eslint-disable-next-line
    }, [filter, showParticipantsAssignedToOthers, fullParticipantList]);

    const filterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newfilter = evt.target.value.toLowerCase();
        setFilter(newfilter);
    };

    const handleCheckboxChange = (pax: ParticipantListItem) => {
        if (props.selectedCoach == null)
            // sanity check
            return;

        if (props.onPaxChange) {
            let isAssigned: boolean =
                pax.otherResourcesInfo.length > 0 &&
                pax.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) != null;

            props.onPaxChange(pax.info.imKey, props.selectedCoach?.imKey, false, !isAssigned);
        }
    };

    const shouldBeChecked = (pax: ParticipantListItem) => {
        return pax.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) != null;
    };

    const getPaxLabel = (pax: ParticipantListItem) => {
        const fullnames = pax.otherResourcesInfo
            .sort((a, b) => {
                return (
                    a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
                );
            })
            .map((p) => `${p.firstName} ${p.lastName}`);

        const coachlist: string = fullnames.join(", ");
        return pax.otherResourcesInfo.length === 0
            ? `${pax.info.firstName} ${pax.info.lastName}`
            : `${pax.info.firstName} ${pax.info.lastName} (${coachlist})`;
    };

    return (
        <Card sx={{ height: "100%", m: 2, p: 4, maxHeight: "100%", overflow: "auto" }}>
            <CardHeader
                title={`Participants${
                    props.selectedCoach == null
                        ? ""
                        : ` - ${props.selectedCoach.firstName} ${props.selectedCoach.lastName} (${selectedCoachParticipantCount})`
                }`}
                sx={{ p: 1, mb: 2 }}
            />
            <Grid container spacing={2} sx={{ width: 1 }}>
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        value={filter}
                        label="Search"
                        onChange={filterChange}
                        sx={{ width: 1, maxHeight: "57px" }}
                        id="participant-search"
                        variant="outlined"
                    />
                    {props.selectedCoach != null && (
                        <CclButton
                            size="small"
                            sx={{ mt: 4 }}
                            onClick={() => {
                                if (props.assignAllToCoach != null && props.selectedCoach != null)
                                    props.assignAllToCoach(props.selectedCoach);
                            }}
                            mode={"primary"}
                        >
                            {`Assign All To ${props.selectedCoach.firstName} ${props.selectedCoach.lastName}`}
                        </CclButton>
                    )}
                </Grid>
                <Grid item xs={12} sx={{ maxHeight: "100%", overflow: "auto" }}>
                    {filteredParticipantList && filteredParticipantList.length > 0 && (
                        <List
                            dense={true}
                            sx={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
                        >
                            {filteredParticipantList.map((pax, i) => (
                                <ListItem key={pax.info.imKey}>
                                    <ListItemButton onClick={() => handleCheckboxChange(pax)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                id={pax.info.imKey.toString()}
                                                // onChange={handleCheckboxChange}
                                                checked={shouldBeChecked(pax)}
                                                inputProps={{
                                                    "aria-labelledby": `pax-select-${pax.info.imKey}`,
                                                }}
                                                disabled={props.selectedCoach == null}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={getPaxLabel(pax)} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Grid>
            </Grid>
        </Card>
    );
};

export default NwaCoachAssignmentParticipantList;
