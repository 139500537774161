import React from "react";
import { AlertColor, Box, Stack, TextField } from "@mui/material";
import { useSaveJournalEntryMutation } from "../../../services/cclTokenedJournalEntryApi";
import { JournalEntry } from "../../../services/types/journalEntryTypes";
import CclErrorDialog from "../cclErrorDialog";
import CclButton from "../cclButtons/cclButton";
import CclDrawer from "../cclDrawer/cclDrawer";
import CclLoadingButton from "../cclButtons/cclLoadingButton";
import CclDrawerActionBar from "../cclDrawer/cclDrawerActionBar";
import CclAlertSnackbar from "../cclAlertSnackbar";

export type JournalEntryDrawerProps = {
    journalCategory: number;
    journalCategoryData: string;
    alert?: JSX.Element;
    alertColor?: AlertColor;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
    onSaveError: (msg: string) => void;
};

const JournalEntryDrawer: React.FC<JournalEntryDrawerProps> = (props) => {
    const [entryText, setEntryText] = React.useState<string>("");
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>("info");
    const [saveJournalEntry, { isLoading }] = useSaveJournalEntryMutation();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const handleClose = () => {
        setEntryText("");
        props.onClose();
    };

    const handleSave = () => {
        if (entryText.trim() === "") {
            setShowErrorDialog(true);
        } else {
            const entry: JournalEntry = {
                journalEntryId: undefined, // populated when created on server
                entry: entryText,
                categoryId: props.journalCategory,
                categoryData: props.journalCategoryData,
                categoryName: "", // not needed for saving entry
                createdByUser: "", // not needed for saving entry
                createdUtc: new Date(), // not really needed, just satisfy compiler
            };
            saveJournalEntry(entry)
                .unwrap()
                .then(() => {
                    setEntryText("");
                    ShowSnackbarElement("Journal Entry Created", "success");
                    props.onSave();
                })
                .catch(() => props.onSaveError("Failed to save journal entry."));
        }
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg="Journal entry cannot be blank or empty."
            />
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CclDrawer open={props.open} onClose={handleClose} title="Create Journal Entry">
                <Box component="form" noValidate autoComplete="off">
                    <Stack direction="column" spacing={2}>
                        <TextField
                            id="journal-input"
                            onChange={(evt) => setEntryText(evt.target.value)}
                            multiline
                            rows={8}
                            defaultValue=""
                            sx={{ width: 1 }}
                        >
                            {entryText}
                        </TextField>
                        <CclDrawerActionBar>
                            <CclLoadingButton
                                mode={"primary"}
                                onClick={handleSave}
                                disabled={entryText === ""}
                                loading={isLoading}
                            >
                                Save
                            </CclLoadingButton>
                            <CclButton onClick={props.onClose} mode={"secondary"}>
                                Cancel
                            </CclButton>
                        </CclDrawerActionBar>
                    </Stack>
                </Box>
            </CclDrawer>
        </>
    );
};

export default JournalEntryDrawer;
