import * as React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    SessionListConfigState,
    updateSessionSearchParams,
} from "../../app/slices/sessionListConfigSlice";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import { getDateStringAdjusted } from "../../utilities/dateHelpers";
import ActiveSessionsTabPanel from "./activeSessionsTabPanel";
import { UserClaimsService, eGroupIds } from "../../services/currentUserService/currentUserService";
import CclTextOrDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextOrDateSearchBar";

export const Sessions: React.FC = () => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.sessionListConfig);

    // local state
    const [localConfig, setLocalConfig] = useState<SessionListConfigState>({ ...config });

    const claimsService = new UserClaimsService();
    const isProgramManager = claimsService.IsUserInGroups([eGroupIds.eClientAdmin]);

    const getSessionsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            return;
        }
        const newLocalConfig = {
            StartDate: startDate.toISOString(),
            EndDate: endDate.toISOString(),
            Keyword: "*",
            IsDateSearchActive: true,
        };
        setLocalConfig(newLocalConfig);
        dispatch(updateSessionSearchParams(newLocalConfig));
    };

    const getSessionByKeyword = async (searchString: string) => {
        if (searchString == null || searchString === "" || searchString === "*") {
            return;
        }
        const newLocalConfig = {
            StartDate: getDateStringAdjusted(-2), // api requires some date but it won't be used in search
            EndDate: getDateStringAdjusted(2), // api requires some date but it won't be used in search
            Keyword: searchString.trim(),
            IsDateSearchActive: false,
        };
        setLocalConfig(newLocalConfig);
        dispatch(updateSessionSearchParams(newLocalConfig));
    };

    const getSessions = async (keyword: string, startDate: Date | null, endDate: Date | null) => {
        if (keyword === "") {
            getSessionsByDate(startDate, endDate);
        } else {
            getSessionByKeyword(keyword);
        }
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                {isProgramManager ? "My Sessions" : "Sessions"}
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Stack height={1} width={1}>
                {isProgramManager ? (
                    <Card
                        sx={{
                            height: 1,
                            width: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ActiveSessionsTabPanel isProgramManager={true} />
                    </Card>
                ) : (
                    <Card
                        sx={{
                            width: 1,
                            height: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "row", width: 1 }}>
                            <Box sx={{ flex: "1", paddingRight: 1 }}>
                                <CclTextOrDateSearchBar
                                    executeSearch={getSessions}
                                    initialTextFieldSearchTerm={
                                        localConfig.IsDateSearchActive ? "" : localConfig.Keyword
                                    }
                                    textFieldSearchLabel="Enter PR Code, ID, or Title Keyword"
                                    initialStartDate={
                                        localConfig.IsDateSearchActive
                                            ? new Date(localConfig.StartDate)
                                            : null
                                    }
                                    initialEndDate={
                                        localConfig.IsDateSearchActive
                                            ? new Date(localConfig.EndDate)
                                            : null
                                    }
                                    startDateLabel="Earliest Start Date"
                                    endDateLabel="Latest Start Date"
                                    allowNullDates={false}
                                    showResetButton={true}
                                    clearSearchTrigger={undefined}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: 1,
                                height: 1,
                                marginTop: 2,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <ActiveSessionsTabPanel isProgramManager={false} />
                        </Box>
                        {/* </Grid> */}
                    </Card>
                )}
            </Stack>
        </StandardLayout>
    );
};

export default Sessions;
