import * as React from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    TextField,
    Typography,
    Alert,
    AlertColor,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BiographySection } from "../../../services/types/bioApiTypes";
import { useSaveBiographySectionMutation } from "../../../services/cclTokenedEnterpriseCoachingBiographyApi";
import { AccessEventIndividualDetails } from "../../../services/types/accessEventTypes";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import CclAlertSnackbar from "../../common/cclAlertSnackbar";
import CclLoadingButton from "../../common/cclButtons/cclLoadingButton";

interface BiographySectionAccordianProps {
    expanded: boolean;
    editMode: boolean;
    bioSection: BiographySection;
    key: number;
    pKey: string;
    readonly?: boolean
    imKey?: string;
    email?: string;
    fromResources: boolean;
}

const BiographySectionAccordian: React.FC<BiographySectionAccordianProps> = (props) => {
    const claimservice = new UserClaimsService();
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const [contentValue, setContentValue] = React.useState<string | undefined>(
        props.bioSection.sectionContent
    );
    const [contentEdited, setContentEdited] = React.useState<boolean>(false);
    const [expanded, setExpanded] = React.useState<boolean>(props.expanded);
    const [saveBiographySection, { isLoading }] = useSaveBiographySectionMutation();
    const { logEvent } = useLogAccessEvent();

    const savedBioEvent = (imkey: string) => {
        const tag: string = !props.fromResources ? "UserSavedBio" : "BioEdited";
        const evtData: AccessEventIndividualDetails = {
            imKey: imkey,
            email: props.email ?? claimservice.GetCurrentUserEmail(),
        };

        logEvent(tag, evtData);
    };

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const saveBiographySectionClick = () => {
        let section: BiographySection = {
            id: props.bioSection.id,
            title: props.bioSection.title,
            sectionContent: contentValue ? contentValue : "",
            pKey: Number(props.pKey),
        };
        setContentEdited(false);
        saveBiographySection(section)
            .unwrap()
            .then(() => {
                ShowSnackbarElement("Bio Updated", "success");
                savedBioEvent(props.imKey ? props.imKey : claimservice.GetCurrentUserId());
            })
            .catch((error) => {
                ShowSnackbarElement("Bio Update Failed", "error");
            });
    };

    const handleAccordianChange = () => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? true : false);
    };

    const handleContentChange = (newText: string) => {
        setContentValue(newText);
        setContentEdited(newText !== props.bioSection.sectionContent);
    };

    const ariaControlId = "panel-content-" + props.bioSection.id;
    const accordianId = "panel-header-" + props.bioSection.id;

    return (
        <Accordion key={+expanded} expanded={expanded} onChange={handleAccordianChange()}>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={ariaControlId}
                id={accordianId}
            >
                <Typography sx={{ marginLeft: "15px", fontWeight: "bolder" }}>
                    {props.bioSection.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {!props.editMode && (
                    <span
                        dangerouslySetInnerHTML={{
                            __html:
                                props.bioSection.sectionContent &&
                                props.bioSection.sectionContent.length > 0
                                    ? props.bioSection.sectionContent.replace(/\n/g, "<br />")
                                    : "This section has no content",
                        }}
                    />
                )}
                {props.editMode && (
                    <Stack width={1} spacing={3}>
                        <TextField
                            inputProps={{ maxLength: 900 }}
                            onChange={(e) => handleContentChange(e.target.value)}
                            label={`Enter ${props.bioSection.title}`}
                            value={contentValue}
                            multiline={true}
                            rows={8}
                            fullWidth={true}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {contentValue
                                ? (900 - contentValue.length).toString() +
                                  " of 900 characters remaining"
                                : "900 characters remaining"}
                        </Typography>
                        <Stack direction={"row"} alignItems={"center"}>
                            {contentEdited && (
                                <Alert sx={{ width: "100%", mr: 2 }} severity="info">
                                    You have pending changes. Press Save to commit them.
                                </Alert>
                            )}
                            <CclLoadingButton
                                onClick={saveBiographySectionClick}
                                loading={isLoading}
                                disabled={!contentEdited}
                                sx={{ mr: 2, ml: "auto", height: "36px" }}
                                mode={"primary"}
                                size="small"
                            >
                                Save
                            </CclLoadingButton>
                        </Stack>
                    </Stack>
                )}
            </AccordionDetails>
        </Accordion>
    );
};

export default BiographySectionAccordian;
