import * as React from "react";
import { LoadingButtonProps, LoadingButton } from "@mui/lab";
import { getButtonStyle } from "./cclButtonStylings";

type CclLoadingButtonProps = LoadingButtonProps & {
    mode: "primary" | "secondary" | "tertiary";
};

export const CclLoadingButton: React.FC<CclLoadingButtonProps> = (props) => {
    const { mode, sx, children, ...passthroughprops } = props;

    let styling = { ...getButtonStyle(mode, passthroughprops.size === "small"), ...sx };

    return (
        <LoadingButton sx={{ ...styling }} {...passthroughprops}>
            {children}
        </LoadingButton>
    );
};

export default CclLoadingButton;
