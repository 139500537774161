import { Alert, AlertColor, AlertTitle, Box } from "@mui/material";
import * as React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!\
import timeGridPlugin from "@fullcalendar/timegrid";
import { EventClickArg, EventInput } from "@fullcalendar/core";
import { useGetCalendarEventsQuery } from "../../../services/cclTokenedEnterpriseCoachingCalendarApi";
import ComponentLoader from "../../common/componentLoader";
import {
    CalendarEvent,
    CalendarParams,
} from "../../../services/types/enterpriseCoachingCalendarApiTypes";

import "./resourceEventCalendar.css";
import CclAlertSnackbar from "../../common/cclAlertSnackbar";
import CalendarEventDetailDrawer from "../../../pages/my-access/accessDetail/myFilesTab/calendarEventDrawer/calendarEventDetailDrawer";

interface ResourceCalendarProps {
    imKey: string;
    pKey: string;
}

const ResourceEventCalendar: React.FC<ResourceCalendarProps> = (props) => {
    const params: CalendarParams = { pKey: props.pKey, imKey: props.imKey };
    const { data, isLoading, isError } = useGetCalendarEventsQuery(params);
    const [selectedEvent, setSelectedEvent] = React.useState<CalendarEvent | null>(null);
    const [showEventDetail, setShowEventDetail] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const calendarEvents = data
        ?.filter((x) => x.appointmentStatus !== "Canceled")
        .map((x) => {
            const e: EventInput = {};
            e.title = x.eventType;
            e.start = x.startDateTime;
            e.end = x.endDateTime;
            e.description = x.eventType;
            e.backgroundColor = "#2F5F95";
            e.id = x.id.toString();

            if (x.bookingType === "Hard Booking") {
                e.title = "(" + x.appointmentStatus + ") - " + x.eventTitle;
            } else if (x.bookingType === "Assignment") {
                e.title = "(Assignment) - " + x.eventTitle;
            }
            return e;
        });

    function showErrorAlert(msg: string | undefined) {
        return (
            <Alert severity="error" sx={{ fontSize: 14 }}>
                <AlertTitle sx={{ fontSize: 14 }}>Error</AlertTitle>
                {msg}
            </Alert>
        );
    }

    function eventClickHandler(info: EventClickArg) {
        const sessionEvent = data?.find((e) => e.id.toString() === info.event.id);
        if (sessionEvent != null) {
            setSelectedEvent(sessionEvent);
            setShowEventDetail(true);
        } else {
            ShowSnackbarElement("Error Loading Event Data", "warning");
        }
    }

    if (isLoading || isLoading) {
        return <ComponentLoader msg={"Loading"} />;
    }

    if (isError) {
        return showErrorAlert(`Error retrieving calendar`);
    }

    return (
        <Box sx={{ width: 1, height: 1 }}>
            {selectedEvent != null && showEventDetail ? (
                <CalendarEventDetailDrawer
                    event={selectedEvent}
                    open={true}
                    onClose={() => setShowEventDetail(false)}
                />
            ) : null}
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <FullCalendar
                height={1000}
                aspectRatio={1}
                plugins={[dayGridPlugin, timeGridPlugin]}
                headerToolbar={{
                    start: "today prev next",
                    center: "title",
                    end: "dayGridMonth dayGridWeek dayGridDay",
                }}
                initialView="dayGridMonth"
                events={calendarEvents}
                eventDisplay="block"
                eventClick={eventClickHandler}
                showNonCurrentDates={false}
                buttonText={{
                    today: "Today",
                    month: "Month",
                    week: "Week",
                    day: "Day",
                    list: "List",
                }}
            />
        </Box>
    );
};

export default ResourceEventCalendar;
