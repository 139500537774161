import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface ClientAccountExecSearchConfigState {
    AccountOwnerName: string;
    ParentKey: number;
    ClientName: string;
    FiscalKey: number;
    IsSearchWild: boolean;
};

// Define the initial state using that type
const initialState: ClientAccountExecSearchConfigState = {
    AccountOwnerName: "",
    ParentKey: 0,
    ClientName: "",
    FiscalKey: 0,
    IsSearchWild: true,
};

export const ClientAccountExecSearchConfigSlice = createSlice({
  name: 'clientAccountExecSearchListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateClientAccountExecSearchParams: (state, action: PayloadAction<ClientAccountExecSearchConfigState>) => {
      state.AccountOwnerName = action.payload.AccountOwnerName;
      state.ParentKey = action.payload.ParentKey;
      state.ClientName = action.payload.ClientName;
      state.FiscalKey = action.payload.FiscalKey;
      state.IsSearchWild = action.payload.IsSearchWild;
    }
  }
});

export const { updateClientAccountExecSearchParams } = ClientAccountExecSearchConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccountExecSearchListConfig = (state: RootState) => state;
export default ClientAccountExecSearchConfigSlice.reducer;
