import * as React from "react";
import { AlertColor, Box, Stack, TextField } from "@mui/material";
import { useAddCclApplicationMutation } from "../../../../services/cclTokenedGrandCentralApi";
import { generateGUID } from "../../../../utilities/generalUtilities";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventGCSystemDetails } from "../../../../services/types/accessEventTypes";

export interface AddApplicationDrawerProps {
    open: boolean;
    onClose: (severity?: AlertColor, msg?: string) => void;
    systemId: string;
    systemName: string;
}

export const AddApplicationDrawer: React.FC<AddApplicationDrawerProps> = (props) => {
    const [newAppName, setNewAppName] = React.useState<string>("");
    const [addApplication, { isLoading }] = useAddCclApplicationMutation();
    const { logEvent } = useLogAccessEvent();

    const onAddApplication = () => {
        addApplication({
            name: newAppName,
            applicationId: generateGUID(),
            systemId: props.systemId,
        })
            .unwrap()
            .then((res) => {
                if (!res) {
                    props.onClose("error", "Add Application Failed");
                } else {
                    const evtData: AccessEventGCSystemDetails = {
                        systemId: props.systemId,
                        systemName: props.systemName,
                    };
                    logEvent("GCSystemChanged", evtData);
                    props.onClose("success", "Application Added");
                }
            })
            .catch((err) => {
                props.onClose("error", "Add Application Failed");
            });
    };

    return (
        <CclDrawer
            open={props.open}
            onClose={() => props.onClose(undefined, undefined)}
            title="Add Application"
        >
            <Box component="form" noValidate autoComplete="off">
                <Stack direction="column" spacing={2}>
                    <TextField
                        required
                        id="new-application-name"
                        label="Application Name"
                        variant="outlined"
                        value={newAppName}
                        onChange={(e) => setNewAppName(e.target.value)}
                    />
                    <CclDrawerActionBar>
                        <CclLoadingButton
                            onClick={() => onAddApplication()}
                            disabled={newAppName === ""}
                            mode={"primary"}
                            loading={isLoading}
                        >
                            Save
                        </CclLoadingButton>
                        <CclButton
                            onClick={() => props.onClose(undefined, undefined)}
                            mode={"secondary"}
                        >
                            Cancel
                        </CclButton>
                    </CclDrawerActionBar>
                </Stack>
            </Box>
        </CclDrawer>
    );
};

export default AddApplicationDrawer;
