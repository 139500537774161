import * as React from "react";
import { Alert, Card, CardHeader } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

export interface Auth0ConnectionCardProps {
    applicationId: string;
    auth0Connections: string[];
}

export const Auth0ConnectionCard: React.FC<Auth0ConnectionCardProps> = (props) => {
    const data =
        props.auth0Connections && props.auth0Connections.length > 0
            ? props.auth0Connections.map((conn, index) => ({ id: index, conn }))
            : [];

    const COLUMNS = [
        {
            field: "conn",
            type: "string",
            flex: 1,
        },
    ];

    return (
        <Card variant="outlined" sx={{ height: 1 }}>
            <CardHeader title="Auth0 Connections" />
            {props.auth0Connections && props.auth0Connections.length > 0 ? (
                <DataGridPro
                    rows={data}
                    columns={COLUMNS}
                    autoHeight={true}
                    sx={{
                        "& .MuiDataGrid-columnHeaders": {
                            display: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            marginTop: "0px !important",
                        },
                    }}
                />
            ) : (
                <Alert severity="info">No Auth0 Connections Found</Alert>
            )}
        </Card>
    );
};

export default Auth0ConnectionCard;
