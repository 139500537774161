import { useContext, useState } from "react";
import { PeopleAlt } from "@mui/icons-material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AppsIcon from "@mui/icons-material/Apps";
import ArticleIcon from "@mui/icons-material/Article";
import EmailIcon from "@mui/icons-material/Email";
import GroupsIcon from "@mui/icons-material/Groups";
import HistoryIcon from "@mui/icons-material/History";
import HouseIcon from "@mui/icons-material/House";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { AllowedRoutesState } from "../../app/slices/allowedRoutesSlice";
import { useAuth0 } from "@auth0/auth0-react";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import { UserClaimsService } from "../../services/currentUserService/currentUserService";
import { FeatureFlag } from "../../featureFlags";

interface SideNavBarProps {
    open?: boolean;
}

export const SideNavBar: React.FC<SideNavBarProps> = (props) => {
    const [showLogoutSpinner, setShowLogoutSpinner] = useState<boolean>(false);
    const allowedRoutes: AllowedRoutesState = useSelector((state: any) => state.allowedRoutes);
    const claimsService = new UserClaimsService();
    var location = useLocation();
    const { logout } = useAuth0();
    const { logEvent } = useLogAccessEvent();
    const features = useContext(FeatureFlag);

    const logoutWithRedirect = () => {
        setShowLogoutSpinner(true);
        logEvent("UserLoggedOut", null);
        claimsService.ClearToken();
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    const iconColor = "#3B3B3B";
    const aboveDividerMenuItems = [
        {
            show: allowedRoutes.routeFlags.allowHome,
            label: "Home",
            to: "/home",
            selected: location.pathname === "/home",
            icon: <HouseIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowMyAccess,
            label: "My Access",
            to: "/myaccess",
            selected: location.pathname.startsWith("/myaccess"),
            icon: <PersonIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show:
                allowedRoutes.routeFlags.allowClients &&
                features?.chtTabs?.active &&
                claimsService.IsUserInAppRole(9),
            label: "Clients",
            to: "/clients",
            selected: location.pathname.startsWith("/client"),
            icon: <PeopleAlt fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowSessions,
            label: "Sessions",
            to: "/sessions",
            selected: location.pathname.startsWith("/session"),
            icon: <GroupsIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowRegistrations,
            label: "Registrations",
            to: "/registrations",
            selected: location.pathname.startsWith("/registration"),
            icon: <PeopleIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowAssessments,
            label: "Assessments",
            to: "/assessments",
            selected: location.pathname.startsWith("/assessment"),
            icon: <ArticleIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowResources,
            label: "Resources",
            to: "/resources",
            selected:
                location.pathname.startsWith("/resource") ||
                location.pathname.startsWith("/work-pool"),
            icon: <PeopleAlt fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowApplications,
            label: "Applications",
            to: "/applications",
            selected:
                location.pathname.startsWith("/application") ||
                location.pathname.startsWith("/system"),
            icon: <AppsIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowUsers,
            label: "Users",
            to: "/users",
            selected: location.pathname.startsWith("/user"),
            icon: <AccountBoxIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowNotifications,
            label: "Emails",
            to: "/notifications",
            selected:
                location.pathname.startsWith("/notification") ||
                location.pathname.startsWith("/email"),
            icon: <EmailIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: allowedRoutes.routeFlags.allowLogs,
            label: "Events",
            to: "/events",
            selected: location.pathname.startsWith("/event"),
            icon: <HistoryIcon fontSize={"small"} htmlColor={iconColor} />,
        },
    ];

    const belowDividerMenuItems = [
        {
            show: true,
            label: "Support",
            onClick: () => {
                window.open("https://support.ccl.org", "_blank");
            },
            selected: false,
            icon: <HelpIcon fontSize={"small"} htmlColor={iconColor} />,
        },
        {
            show: true,
            label: "Logout",
            onClick: () => logoutWithRedirect(),
            selected: false,
            icon: <LogoutIcon fontSize={"small"} htmlColor={iconColor} />,
        },
    ];

    const menuItemCount =
        aboveDividerMenuItems.filter((item) => item.show).length +
        belowDividerMenuItems.filter((item) => item.show).length;
    const menuBoxMaxHeight = `${menuItemCount * 50 + 9}px`;

    return (
        <Box
            className="navsidebar"
            sx={{
                borderRightStyle: "solid",
                borderColor: "rgba(0, 0, 0, 0.13)",
                borderRightWidth: "1px",
                backgroundColor: "#FFF",
                color: "#3B3B3B",
                height: "100%",
                fontSize: "14px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "150%",
                display: "flex",
                width: 1,
                flexDirection: "column",
                flex: "1 0 0",
                padding: 3,
                paddingLeft: 1,
            }}
        >
            {showLogoutSpinner ? (
                <Dialog open={true}>
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            ) : null}
            <Box
                sx={{
                    display: "flex",
                    padding: "4px 16px 16px 16px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "8px",
                    alignSelf: "stretch",
                }}
            >
                <img
                    src="https://coreprodeuscclstg.blob.core.windows.net/public-web-assets/logos/Access/Access%20Logo_Color.png"
                    alt="CCL logo - small, color"
                    width={"129px"}
                    height={"83px"}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: menuBoxMaxHeight,
                    flexGrow: 1,
                }}
            >
                <List
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flexGrow: 1,
                    }}
                >
                    {aboveDividerMenuItems
                        .filter((item) => item.show)
                        .map((menuItem) => (
                            <ListItemButton
                                key={menuItem.label}
                                component={RouterLink}
                                to={menuItem.to}
                                selected={menuItem.selected}
                                dense
                            >
                                {/* <ListItemIcon>{menuItem.icon}</ListItemIcon> */}
                                <ListItemText
                                    primary={menuItem.label}
                                    sx={{ opacity: props.open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        ))}
                    <Divider sx={{ my: 1 }} />
                    {belowDividerMenuItems
                        .filter((item) => item.show)
                        .map((menuItem) => (
                            <ListItemButton
                                key={menuItem.label}
                                onClick={menuItem.onClick}
                                sx={{ cursor: "pointer" }}
                                dense
                            >
                                {/* <ListItemIcon>{menuItem.icon}</ListItemIcon> */}
                                <ListItemText
                                    primary={menuItem.label}
                                    sx={{ opacity: props.open ? 1 : 0, color: "black" }}
                                />
                            </ListItemButton>
                        ))}
                </List>
            </Box>
            <Box sx={{ marginTop: "auto", paddingTop: 3, marginBottom: 1 }}>
                <Typography
                    sx={{
                        color: "#3b3b3b",
                        fontSize: "11px",
                        fontWeight: "400",
                        textAlign: "center",
                        height: "3em",
                        whiteSpace: "pretty",
                        textWrap: "wrap",
                    }}
                >
                    &copy; {new Date().getFullYear()} Center for Creative Leadership
                </Typography>
            </Box>
        </Box>
    );
};

export default SideNavBar;
