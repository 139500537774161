import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import * as React from "react";
import { DetailsTabPanel } from "../../../../components/common/detailTabPanel/detailTabPanel";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../services/currentUserService/currentUserService";
import ResourceProductSalesPanel from "../../../resources/resourceDetail/participants/productsales/resourceProductSalesPanel";
import ResourceRegistrationsPanel from "../../../resources/resourceDetail/participants/registrations/resourceRegistrationsPanel";
import UserFilesPanel from "../../../users/userDetail/files/userFilePanel";
import ParticipantAssessmentStatusSubPanel from "./participantAssessmentStatusSubPanel";
import SessionDetailDrawer from "./sessionsPopupDetails/sessionDetailDrawer";
import ArchivedPanel from "./archivedPanel/archivedPanel";

interface MyFilesTabProps {}

const MyFilesTab: React.FC<MyFilesTabProps> = () => {
    const claimsService = new UserClaimsService();
    const imkey = claimsService.GetCurrentUserId();
    const email = claimsService.GetCurrentUserEmail();

    const [alignment, setAlignment] = React.useState<number>(0);
    const [showSessionPopup, setShowSessionPopup] = React.useState<boolean>(false);
    const [sessionKey, setSessionKey] = React.useState<string>();
    const [selectedParticipantKey, setSelectedParticipantKey] = React.useState<number | undefined>(
        undefined
    );

    const handleTabChange = (event: React.SyntheticEvent, newValue: number | null) => {
        if (newValue != null) setAlignment(newValue);
    };

    const registrationsEventClickCallback = (esKey: string) => {
        setSessionKey(esKey);
        setShowSessionPopup(!showSessionPopup);
    };

    const popupOnClose = () => {
        setShowSessionPopup(false);
        setSessionKey("");
    };

    let name = claimsService.GetCurrentUserName();
    name = name.replace(/\s/g, "");

    const handleViewPaxAssessmentStatus = (esiKey: number) => {
        setSelectedParticipantKey(esiKey);
        var tabIndex = tablist.findIndex((t) => t[0] === "Participant Assessment Status");
        setAlignment(tabIndex);
    };

    const isFacilitator = claimsService.IsUserInGroups([eGroupIds.eClientFacilitators]);

    // Tablist
    let tablist: [string, React.ReactNode][] = [
        [
            "Participant Files",
            <ResourceRegistrationsPanel
                imkey={+imkey}
                alternateEventOnClickHandler={registrationsEventClickCallback}
                handleViewPaxAssessmentStatus={handleViewPaxAssessmentStatus}
                isUserDetail={false}
            />,
        ],
        [
            "Participant Assessment Status",
            <ParticipantAssessmentStatusSubPanel
                resourceImKey={+imkey}
                selectedParticipantKey={selectedParticipantKey}
            />,
        ],
        [
            "Product Sale Assessments",
            <ResourceProductSalesPanel email={email} isUserDetail={false} />,
        ],
        [
            "Archived",
            <ArchivedPanel
                coachEmail={email}
                coachImKey={+imkey}
                includeSessionParticipants={!isFacilitator}
            />,
        ],
        [
            "My Files",
            <UserFilesPanel imKey={+imkey} fullName={name} email={email} allowDelete={true} />,
        ],
    ];

    if (isFacilitator) {
        tablist = tablist.filter((t) => t[0] === "Product Sale Assessments" || t[0] === "Archived");
    }

    return (
        <>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    m: 2,
                    marginTop: 2,
                    borderWidth: 0,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    sx={{ borderWidth: 0, flexGrow: 1 }}
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleTabChange}
                        sx={{ mb: 3 }}
                    >
                        {tablist.map((tab, i) => (
                            <ToggleButton
                                key={i}
                                id={`resource-detail-participants-tab-${i}`}
                                value={i}
                            >
                                {tab[0]}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
                {tablist.map((tab, i) => (
                    <DetailsTabPanel
                        detailType={"resource-participants"}
                        key={tab[0]}
                        value={alignment}
                        index={i}
                    >
                        {tab[1]}
                    </DetailsTabPanel>
                ))}
            </Box>
            {showSessionPopup && sessionKey && (
                <SessionDetailDrawer
                    sessionId={sessionKey}
                    open={showSessionPopup}
                    onClose={popupOnClose}
                />
            )}
        </>
    );
};

export default MyFilesTab;
