import * as React from "react";
import { Box, Drawer, Stack, Typography } from "@mui/material";

export interface CclDrawerProps {
    title: string;
    open: boolean;
    onClose: () => void;
    children?: React.ReactElement | React.ReactElement[] | undefined;
    width?: "wide" | "standard" | "narrow";
}

export const CclDrawer: React.FC<CclDrawerProps> = (props) => {
    const returnWidth = () => {
        switch(props.width) {
            case "wide":
                return "50%";
            case "standard":
                return "35%";
            case "narrow":
                return "20%";
            default:
                return "35%";
        }
    }
    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={props.open}
            onClose={props.onClose}
            sx={{
                display: "block",
                borderRadius: "16px 0px 0px 16px",
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: returnWidth(),
                    top: "60px",
                    height: "calc(100% - 60px)",
                    borderRadius: "16px 0px 0px 16px",
                },
            }}
        >
            <Stack direction="column" spacing={3} sx={{ padding: "6px" }}>
                <Typography
                    variant={"h6"}
                    align={"left"}
                    sx={{
                        padding: "16px 24px",
                        borderBottom: "1px solid gray",
                        fontWeight: "bold",
                    }}
                >
                    {props.title}
                </Typography>
                <Box
                    sx={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    {props.children}
                </Box>
            </Stack>
        </Drawer>
    );
};

export default CclDrawer;
