import * as React from "react";
import { Stack } from "@mui/material";

export interface CclDrawerActionBarButtonGroupingProps {
    children?: React.ReactElement | React.ReactElement[] | undefined;
}

export const CclDrawerActionBarButtonGrouping: React.FC<CclDrawerActionBarButtonGroupingProps> = (
    props
) => {
    return (
        <Stack direction={"row"} spacing={3}>
            {props.children}
        </Stack>
    );
};

export default CclDrawerActionBarButtonGrouping;
