import * as React from "react";
import {
    DataGridPro,
    GridRenderCellParams,
    GridRowId,
    GridSelectionModel,
    GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { ApplicationAccount } from "../../../services/types/rtkQueryTypes";
import CclDataGridToolbar from "../../common/cclDataGridToolbar/cclDataGridToolbar";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { UserClaimsService } from "../../../services/currentUserService/currentUserService";

export interface ApplicationAccountsDataGridProProps {
    accounts: ApplicationAccount[] | undefined;
    isSuperAdmin?: boolean;
    onEditClick?: () => void;
    enableAll?: () => void;
    disableAll?: () => void;
    deleteUser?: (user: ApplicationAccount) => void;
    updateSelected?: (selection: (ApplicationAccount | undefined)[]) => void;
    enableButton?: boolean;
    disableButton?: boolean;
    hideCustomButtons?: boolean;
    disableInProgress?: boolean;
    enableInProgress?: boolean;
    deleteInProgress?: boolean;
}

export const ApplicationAccountsDataGridPro: React.FC<ApplicationAccountsDataGridProProps> = (
    props
) => {
    const COLUMNS = [
        {
            field: "application",
            headerName: "Application",
            type: "string",
            flex: 0.75,
        },
        {
            field: "enabled",
            headerName: "Enabled",
            type: "string",
            valueGetter: (params: GridValueGetterParams) => `${params.value ? "Yes" : "No"}`,
            renderCell: (params: GridRenderCellParams) =>
                params.value === "Yes" ? (
                    "Yes"
                ) : (
                    <>
                        No
                        <ErrorOutlineIcon color="error" fontSize="small" sx={{ ml: 1 }} />
                    </>
                ),
            flex: 0.5,
        },
        {
            field: "expiration",
            headerName: "Expiration",
            valueGetter: (params: any) => {
                return params.row.expiration == null ? null : new Date(params.row.expiration);
            },
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value == null ? "N/A" : params.value.toLocaleDateString()}</span>
            ),
            flex: 0.5,
        },
        {
            field: "groups",
            headerName: "Groups",
            type: "string",
            valueGetter: (params: any) => {
                return params.row.groups.length > 0 ? params.row.groups.join(", ") : "";
            },
            renderCell: (params: GridRenderCellParams) => <span>{params.value}</span>,
            flex: 0.9,
        },
    ];

    const claimservice = new UserClaimsService();
    const isSuperAdmin = claimservice.IsUserSuperAdmin();
    //eslint-disable-next-line
    const [selectedRowData, setSelectedRowData] =
        React.useState<(ApplicationAccount | undefined)[]>();
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

    const handleSelectionChange = (ids: GridSelectionModel) => {
        if (!props.accounts || props.accounts === undefined || props.accounts === null) {
            return;
        }
        const selectedRowsData = ids.map((id) =>
            props.accounts?.find((row) => row.applicationId === id)
        );
        setSelectedRowData(selectedRowsData);
        if (props.updateSelected) {
            props.updateSelected(selectedRowsData);
        }
    };

    const handleButtonClick = (buttonName: string) => {
        if (buttonName === "disable-all" && props.disableAll) {
            props.disableAll();
        }
        if (buttonName === "enable-all" && props.enableAll) {
            props.enableAll();
        }
        if (buttonName === "edit" && props.onEditClick) {
            props.onEditClick();
        }
        if (buttonName === "delete" && props.deleteUser) {
            if (selectedRowData !== undefined && selectedRowData.length === 1) {
                props.deleteUser(selectedRowData[0] as ApplicationAccount);
            }
        }
        return;
    };

    return (
        <DataGridPro
            getRowId={(row) => row.applicationId}
            rows={props.accounts == null ? [] : props.accounts}
            columns={COLUMNS}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={
                isSuperAdmin && !props.hideCustomButtons
                    ? {
                          toolbar: {
                              showSaveState: false,
                              hideSettingsButtons: true,
                              miscButtons: [
                                  {
                                      id: "disable-all",
                                      caption: "Disable All",
                                      label: "Disable All",
                                      disable: !props.disableButton,
                                      returnSelected: true,
                                      restricted: true,
                                      onClick: () => handleButtonClick("disable-all"),
                                      loadingBtn: true,
                                      isLoading: props.disableInProgress,
                                  },
                                  {
                                      id: "enable-all",
                                      caption: "Enable All",
                                      label: "Enable All",
                                      disable: !props.enableButton,
                                      returnSelected: true,
                                      restricted: true,
                                      onClick: () => handleButtonClick("enable-all"),
                                      loadingBtn: true,
                                      isLoading: props.enableInProgress,
                                  },
                                  {
                                      id: "edit",
                                      caption: "Edit Access",
                                      label: "Edit Access",
                                      disable:
                                          selectedRowData === undefined ||
                                          selectedRowData.length > 1 ||
                                          selectedRowData.length === 0,
                                      returnSelected: true,
                                      restricted: true,
                                      onClick: () => handleButtonClick("edit"),
                                  },
                                  {
                                      id: "delete",
                                      caption: "Delete Access",
                                      label: "Delete Access",
                                      disable:
                                          selectedRowData === undefined ||
                                          selectedRowData.length !== 1,
                                      returnSelected: true,
                                      restricted: true,
                                      onClick: () => handleButtonClick("delete"),
                                      loadingBtn: true,
                                      isLoading: props.deleteInProgress,
                                  },
                              ],
                              btnClick: (buttonName: string) => handleButtonClick(buttonName),
                          },
                      }
                    : {
                          toolbar: {
                              showSaveState: false,
                              hideSettingsButtons: true,
                          },
                      }
            }
            checkboxSelection={!props.hideCustomButtons}
            disableMultipleSelection={true}
            disableSelectionOnClick
            selectionModel={selectionModel}
            onSelectionModelChange={(id: GridSelectionModel) => {
                if (id.length > 1) {
                    const selectionSet = new Set(selectionModel);
                    const result = id.filter((s) => !selectionSet.has(s));
                    setSelectionModel(result);
                    handleSelectionChange(result);
                } else {
                    setSelectionModel(id);
                    handleSelectionChange(id);
                }
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: "application", sort: "asc" }],
                },
            }}
            autoHeight
        />
    );
};

export default ApplicationAccountsDataGridPro;
