import * as React from "react";
import {
    DataGridPro,
    GridRenderCellParams,
    GridRowId,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { AccountCEResponse } from "../../../../services/types/clientTypes";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";

export interface AccountManagerDataGridProProps {
    accountCELists: AccountCEResponse[] | undefined;
    showToolbar: boolean;
    OnSelectDetails: (mapperID: number) => void;
    isLoading: boolean;
    OnAccountExecEdit: (mapperID: number) => void;
    OnAccountExecDelete: (mapperID: number) => void;
}

export const AccountManagerDataGridPro: React.FC<AccountManagerDataGridProProps> = (
    props
) => {

    const COLUMNS = [
        {
            field: "fiscalYear",
            headerName: "Fiscal Year",
            type: "string",
            width: 100,
        }, 
        {
            field: "parentKey",
            headerName: "Parent Key",
            type: "string",
            width: 100,
        },
        {
            field: "parentName",
            headerName: "Client Name",
            type: "string",
            flex: 1,
        }, 
        {
            field: "accountOwnerName",
            headerName: "Account Owner Name",
            type: "string",
            flex: 1,
        },
        {
            field: "newClientIndicator",
            headerName: "New Client Indicator",
            type: "number",
            width: 150,
        },
        {
            field: "effectiveStartDate",
            headerName: "Effective Start Date",
            valueGetter: (params: any) => new Date(params.row.effectiveStartDate),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 150,
        },
        {
            field: "effectiveEndDate",
            headerName: "Effective End Date",
            valueGetter: (params: any) => new Date(params.row.effectiveEndDate),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 150,
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

    const handleSelection = (ids: GridSelectionModel) => {
        if (!ids || !props.accountCELists) {
            return;
        }
        const selectedRowsData = ids.map((id) => props.accountCELists?.find((row) => row.mapperID=== id));
        setSelectedRows(selectedRowsData);
    };

    const handleSelect = (id: string) => {
        switch (id) {
            case "select-executive":
                props.OnSelectDetails(selectedRows[0].mapperID);
                break;
            case "edit":
                props.OnAccountExecEdit(selectedRows[0].mapperID);
                break;
            case "delete":
                props.OnAccountExecDelete(selectedRows[0].mapperID);
                break;
            default:
                break;
        }
    }

    return (
        <DataGridPro
            getRowId={(row) => row.mapperID}
            rows={props.accountCELists == null ? [] : props.accountCELists}
            columns={COLUMNS}
            loading={props.isLoading}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    showSaveState: true,
                    savestate: null,
                    clearstate: null,
                    miscButtons: 
                        [
                            {
                                id: "select-executive",
                                caption: `Select`,
                                label: `Select`,
                                returnSelected: false,
                                restricted: false,
                                disable: selectedRows.length !== 1,
                                onClick: () => {
                                    props.OnSelectDetails(selectedRows[0].mapperID);
                                }
                            },
                            {
                                id: "edit",
                                caption: `Edit`,
                                label: `Edit`,
                                returnSelected: false,
                                restricted: false,
                                disable: selectedRows.length !== 1,
                                onClick: () => {
                                    props.OnAccountExecEdit(selectedRows[0].mapperID);
                                }
                            },
                            {
                                id: "delete",
                                caption: `Delete`,
                                label: `Delete`,
                                returnSelected: false,
                                restricted: false,
                                disable: selectedRows.length !== 1,
                                onClick: () => {
                                    props.OnAccountExecDelete(selectedRows[0].mapperID);
                                }
                            }
                        ],
                        btnClick: ((id: string) => handleSelect(id)),
                        hideSettingsButtons: true,
                        anySelected: null,
                    }
            }}
            disableMultipleSelection
            disableSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [{ field: "mapperID", sort: "asc" }],
                },
            }}
            checkboxSelection
            autoHeight
            selectionModel={selectionModel}
            onSelectionModelChange={(id: GridSelectionModel) => {
                if (id.length > 1) {
                    const selectionSet = new Set(selectionModel);
                    const result = id.filter((s) => !selectionSet.has(s));
                    handleSelection(result);
                    setSelectionModel(result);
                } else {
                    handleSelection(id);
                    setSelectionModel(id);
                }
            }}
        />       
    );
};

export default AccountManagerDataGridPro;
