import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";

const components = {
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: {
                disableRipple: false,
                background: "#EBF3F6",
                color: "#000000",
                fontFamily: "Noto Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.1px",
                border: "2px solid #FFFFFF",
                "&.Mui-selected": {
                    background: "#9BC5CF",
                    color: "#000000",
                },
            },
        },
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: {
                "& .MuiToggleButtonGroup-firstButton": {
                    borderTopLeftRadius: "100px",
                    borderBottomLeftRadius: "100px",
                    borderRight: "2px solid #FFFFFF",
                },
                "& .MuiToggleButtonGroup-lastButton": {
                    borderTopRightRadius: "100px",
                    borderBottomRightRadius: "100px",
                    borderLeft: "2px solid #FFFFFF",
                },
                "& .MuiToggleButtonGroup-middleButton": {
                    borderRight: "2px solid #FFFFFF",
                    borderLeft: "2px solid #FFFFFF",
                },
                "& .MuiToggleButtonGroup-selected": {
                    background: "#9BC5CF",
                    color: "#000000",
                },
            },
        },
    },
    MuiSwitch: {
        styleOverrides: {
            root: {
                width: "48px",
                height: "28px",
                padding: 0,
                marginRight: 12,
                marginLeft: 4,
                "& .MuiSwitch-switchBase": {
                    padding: 1,
                    "&.Mui-checked": {
                        transform: "translateX(21px)",
                        color: "#FFFFFF",
                        "& + .MuiSwitch-track": {
                            backgroundColor: "#358CA2",
                            opacity: 1,
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                            backgroundColor: "#9BC5CF",
                            opacity: 1,
                        },
                    },
                    "&.Mui-disabled": {
                        color: "#F0F1F3",
                    },
                },
                "& .MuiSwitch-thumb": {
                    transform: "translateX(1px)",
                    marginTop: "2px",
                    width: "22px",
                    height: "22px",
                },
                "& .MuiSwitch-track": {
                    borderRadius: 28 / 2,
                    backgroundColor: "#C3C6CF",
                    opacity: 1,
                },
            },
            sizeSmall: {
                width: "34px",
                height: "20px",
                "& .MuiSwitch-switchBase": {
                    padding: 1,
                    "&.Mui-checked": {
                        transform: "translateX(14px)",
                        color: "#FFFFFF",
                        "& + .MuiSwitch-track": {
                            backgroundColor: "#358CA2",
                            opacity: 1,
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                            backgroundColor: "#9BC5CF",
                            opacity: 1,
                        },
                    },
                    "&.Mui-disabled": {
                        color: "#F0F1F3",
                    },
                },
                "& .MuiSwitch-thumb": {
                    marginTop: "1px",
                    width: "16px",
                    height: "16px",
                },
                "& .MuiSwitch-track": {
                    borderRadius: 20 / 2,
                    backgroundColor: "#C3C6CF",
                    opacity: 1,
                },
            },
        },
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: 30,
                color: "white",
            },
        },
    },
    MuiLink: {
        defaultProps: {
            underline: "hover",
        },
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                "&.Mui-selected": {
                    backgroundColor: "#105da0",
                    fontWeight: "400",
                },
            },
        },
    },
    MuiCardHeader: {
        defaultProps: {
            titleTypographyProps: {
                variant: "subtitle1",
                fontWeight: "bolder",
            },
        },
    },
    MuiCard: {
        styleOverrides: {
            root: {
                padding: "4px",
                borderRadius: "6px",
                boxShadow:
                    "0px 3px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
                backgroundImage: "none",
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            root: {
                backgroundImage: "none",
            },
        },
    },
    MuiPickersDay: {
        styleOverrides: {
            day: {
                fontWeight: "300",
            },
        },
    },
    MuiPickersYear: {
        styleOverrides: {
            root: {
                height: "64px",
            },
        },
    },
    MuiPickersCalendar: {
        styleOverrides: {
            transitionContainer: {
                marginTop: "6px",
            },
        },
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            iconButton: {
                backgroundColor: "transparent",
                "& > *": {
                    backgroundColor: "transparent",
                },
            },
            switchHeader: {
                marginTop: "2px",
                marginBottom: "4px",
            },
        },
    },
    MuiPickersClock: {
        styleOverrides: {
            container: {
                margin: `32px 0 4px`,
            },
        },
    },
    MuiPickersClockNumber: {
        styleOverrides: {
            clockNumber: {
                left: `calc(50% - 16px)`,
                width: "32px",
                height: "32px",
            },
        },
    },
    MuiPickerDTHeader: {
        styleOverrides: {
            dateHeader: {
                "& h4": {
                    fontSize: "2.125rem",
                    fontWeight: 400,
                },
            },
            timeHeader: {
                "& h3": {
                    fontSize: "3rem",
                    fontWeight: 400,
                },
            },
        },
    },
    MuiPickersTimePicker: {
        styleOverrides: {
            hourMinuteLabel: {
                "& h2": {
                    fontSize: "3.75rem",
                    fontWeight: 300,
                },
            },
        },
    },
    MuiPickersToolbar: {
        styleOverrides: {
            toolbar: {
                "& h4": {
                    fontSize: "2.125rem",
                    fontWeight: 400,
                },
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                borderRadius: "6px",
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                fontSize: "14px",
                fontWeight: 500,
            },
        },
    },
    MuiDataGrid: {
        defaultProps: {
            density: "compact",
        },
        styleOverrides: {
            toolbarContainer: {
                "& .MuiBadge-badge": {
                    backgroundColor: "#307E92",
                },
            },
            panelHeader: {
                "& .MuiFormLabel-root.Mui-focused": {
                    color: "#307E92",
                },
            },
            panelFooter: {
                "& .MuiButtonBase-root": {
                    color: "#307E92",
                },
            },
            filterForm: {
                "& .MuiFormLabel-root.Mui-focused": {
                    color: "#307E92",
                },
            },
        },
    },
    MuiFormHelperText: {
        root: {
            height: 0,
        },
    },
    MuiAccordionSummary: {
        root: {
            fontWeight: "bold",
        },
        content: {
            fontWeight: "bold",
        },
    },
    MuiAlert: {
        defaultProps: {
            iconMapping: {
                success: React.createElement(CheckCircleIcon),
                warning: React.createElement(WarningIcon),
                error: React.createElement(ErrorIcon),
                info: React.createElement(InfoIcon),
            },
            variant: "outlined",
        },
    },
    MuiSnackbar: {
        styleOverrides: {
            root: { backgroundColor: "white" },
        },
    },
};

export default components;
