import * as React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { HomeIndustriesList } from "../../../../services/types/clientTypes";

export interface HomeIndustriesSubDataGridProProps {
    homeIdustriesList: HomeIndustriesList[] | undefined;
    isLoading: boolean;
}

export const HomeIndustriesSubDataGridPro: React.FC<HomeIndustriesSubDataGridProProps> = (
    props
) => {
    const COLUMNS = [
        {
            field: "parentName",
            headerName: "Parent Name",
            type: "string",
            flex: 0.25,
        },
        {
            field: "parentKey",
            headerName: "Parent Key",
            type: "string",
            flex: 0.25,
        },
        {
            field: "childName",
            headerName: "Child Name",
            type: "string",
            flex: 0.25,
        },
        {
            field: "childKey",
            headerName: "Child Key",
            type: "string",
            flex: 0.25,
        },
        {
            field: "source",
            headerName: "Source",
            type: "string",
            flex: 0.25,
        },
        {
            field: "sectorName",
            headerName: "Industry",
            type: "string",
            flex: 0.25,
        },
        {
            field: "orgSize",
            headerName: "Size",
            type: "string",
            flex: 0.25,
        },
        {
            field: "cE_AccountID",
            headerName: "CE_AccountID",
            type: "string",
            flex: 0.25,
        },
        {
            field: "ent_OM_Key",
            headerName: "Ent_OM_Key",
            type: "string",
            flex: 0.25,
        },
        {
            field: "fO_CustomerID",
            headerName: "FO_CustomerID",
            type: "string",
            flex: 0.25,
        },
    ];
    return (
        <DataGridPro
            getRowId={(row) => row.parentKey}
            rows={props.homeIdustriesList ?? []}
            columns={COLUMNS}
            loading={props.isLoading}
            hideFooter
            hideFooterPagination
            hideFooterRowCount
            disableMultipleSelection
            disableSelectionOnClick
            autoHeight
            initialState={{
                sorting: {
                    sortModel: [{ field: "parentKey", sort: "asc" }],
                },
            }}
        />
    );
};

export default HomeIndustriesSubDataGridPro;
