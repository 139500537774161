import * as React from "react";
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    TextField,
} from "@mui/material";
import { PersonInfo } from "../../../../../models/personInfo";
import { Participant } from "../../../../../services/types/enterpriseParticipantApiTypes";
import { SessionDetailAssignedCoach } from "../../../../../services/types/sessionDetailTypes";
import CclButton from "../../../../../components/common/cclButtons/cclButton";

interface CoachAssignmentParticipantListProps {
    allParticipants: Participant[];
    assignedCoaches: SessionDetailAssignedCoach[];
    selectedCoach: PersonInfo | null;
    onPaxChange: (
        paxImKey: number,
        coachImKey: number,
        isPrimary: boolean,
        selected: boolean
    ) => void | undefined;
    assignAllToCoach?: (coach: PersonInfo) => void;
    forPrimaryCoaches: boolean;
}

export type ParticipantListItem = {
    coachInfo: PersonInfo | null;
    otherResourcesInfo: PersonInfo[];
    info: PersonInfo;
};

const CoachAssignmentParticipantList: React.FC<CoachAssignmentParticipantListProps> = (props) => {
    const [fullParticipantList, setFullParticipantList] = React.useState<ParticipantListItem[]>([]);
    const [filteredParticipantList, setFilteredParticipantList] = React.useState<
        ParticipantListItem[]
    >([]);
    const [showParticipantsAssignedToOthers, setShowParticipantsAssignedToOthers] =
        React.useState<boolean>(false);
    const [selectedCoachParticipantCount, setSelectedCoachParticipantCount] =
        React.useState<number>(0);
    const [filter, setFilter] = React.useState<string>("");

    React.useEffect(() => {
        let paxlist: ParticipantListItem[] = [];

        if (props.allParticipants && props.allParticipants.length > 0) {
            paxlist = props.allParticipants.map((p) => {
                return {
                    coachInfo: null,
                    otherResourcesInfo: [],
                    info: { imKey: p.imKey, firstName: p.firstName, lastName: p.lastName },
                };
            });

            if (props.assignedCoaches && props.assignedCoaches.length > 0) {
                props.assignedCoaches.forEach((c) => {
                    let pax = paxlist.find((p) => p.info.imKey === c.paxImKey);
                    if (pax != null) {
                        if (props.forPrimaryCoaches) {
                            pax.coachInfo = {
                                imKey: c.imKey,
                                firstName: c.firstName,
                                lastName: c.lastName,
                            };
                        } else {
                            pax.otherResourcesInfo.push({
                                imKey: c.imKey,
                                firstName: c.firstName,
                                lastName: c.lastName,
                            });
                        }
                    }
                });
            }
        }
        setFullParticipantList(
            paxlist.sort((a, b) => {
                return (
                    a.info.firstName.localeCompare(b.info.firstName) ||
                    a.info.lastName.localeCompare(b.info.lastName)
                );
            })
        );
        const paxCount: number = props.forPrimaryCoaches
            ? paxlist.filter((p) => p.coachInfo?.imKey === props.selectedCoach?.imKey).length
            : paxlist.filter(
                  (p) =>
                      p.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) !=
                      null
              ).length;
        setSelectedCoachParticipantCount(paxCount);
        // eslint-disable-next-line
    }, [props.allParticipants, props.assignedCoaches]);

    React.useEffect(() => {
        const filteredParticipants =
            filter.length === 0
                ? fullParticipantList
                : fullParticipantList.filter(
                      (pax) =>
                          pax.info.firstName.toLowerCase().includes(filter) ||
                          pax.info.lastName.toLowerCase().includes(filter)
                  );

        if (showParticipantsAssignedToOthers) {
            setFilteredParticipantList(
                filteredParticipants.sort((a, b) => {
                    return (
                        a.info.firstName.localeCompare(b.info.firstName) ||
                        a.info.lastName.localeCompare(b.info.lastName)
                    );
                })
            );
            return;
        }

        const finalPartipantList = filteredParticipants.filter((pax) => {
            if (props.forPrimaryCoaches) {
                return pax.coachInfo === null || pax.coachInfo.imKey === props.selectedCoach?.imKey;
            } else {
                return (
                    pax.otherResourcesInfo.length === 0 ||
                    pax.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) !==
                        null
                );
            }
        });
        setFilteredParticipantList(finalPartipantList);
        // eslint-disable-next-line
    }, [filter, showParticipantsAssignedToOthers, fullParticipantList]);

    React.useEffect(() => {
        setSelectedCoachParticipantCount(
            fullParticipantList.filter((p) => p.coachInfo?.imKey === props.selectedCoach?.imKey)
                .length
        );
        // eslint-disable-next-line
    }, [props.selectedCoach]);

    const filterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const newfilter = evt.target.value.toLowerCase();
        setFilter(newfilter);
    };

    const handleCheckboxChange = (pax: ParticipantListItem) => {
        if (props.selectedCoach == null)
            // sanity check
            return;

        if (props.onPaxChange) {
            let isAssigned: boolean;

            if (props.forPrimaryCoaches) {
                if (pax.coachInfo?.imKey === props.selectedCoach.imKey) {
                    isAssigned = true;
                } else if (pax.coachInfo == null) {
                    isAssigned = false;
                } else {
                    return; // coach is assigned to someone else, don't change here
                }
            } else {
                isAssigned =
                    pax.otherResourcesInfo.length > 0 &&
                    pax.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) !=
                        null;
            }

            props.onPaxChange(
                pax.info.imKey,
                props.selectedCoach?.imKey,
                props.forPrimaryCoaches,
                !isAssigned
            );
        }
    };

    const shouldBeChecked = (pax: ParticipantListItem) => {
        if (props.forPrimaryCoaches) {
            return pax.coachInfo != null;
        } else {
            return (
                pax.otherResourcesInfo.find((r) => r.imKey === props.selectedCoach?.imKey) != null
            );
        }
    };

    const getPaxLabel = (pax: ParticipantListItem) => {
        if (props.forPrimaryCoaches) {
            return pax.coachInfo == null
                ? `${pax.info.firstName} ${pax.info.lastName}`
                : `${pax.info.firstName} ${pax.info.lastName} (${pax.coachInfo.firstName} ${pax.coachInfo.lastName})`;
        } else {
            const fullnames = pax.otherResourcesInfo
                .sort((a, b) => {
                    return (
                        a.lastName.localeCompare(b.lastName) ||
                        a.firstName.localeCompare(b.firstName)
                    );
                })
                .map((p) => `${p.firstName} ${p.lastName}`);

            const coachlist: string = fullnames.join(", ");
            return pax.otherResourcesInfo.length === 0
                ? `${pax.info.firstName} ${pax.info.lastName}`
                : `${pax.info.firstName} ${pax.info.lastName} (${coachlist})`;
        }
    };

    return (
        <Box sx={{ height: "100%", m: 2, p: 4, maxHeight: "100%", overflow: "auto" }}>
            <Grid container spacing={2} sx={{ width: 1 }}>
                <Grid item xs={props.forPrimaryCoaches ? 8 : 12}>
                    <TextField
                        value={filter}
                        label="Search"
                        onChange={filterChange}
                        sx={{ width: 1, maxHeight: "57px" }}
                        id="participant-search"
                        variant="outlined"
                    />
                    {props.selectedCoach != null && !props.forPrimaryCoaches && (
                        <CclButton
                            size="small"
                            sx={{ mt: 2 }}
                            onClick={() => {
                                if (props.assignAllToCoach != null && props.selectedCoach != null)
                                    props.assignAllToCoach(props.selectedCoach);
                            }}
                            mode={"primary"}
                        >
                            {`Assign All To ${props.selectedCoach.firstName} ${props.selectedCoach.lastName}`}
                        </CclButton>
                    )}
                </Grid>
                {props.forPrimaryCoaches && (
                    <Grid item xs={4}>
                        <FormControlLabel
                            value="Show Assigned"
                            control={
                                <Switch
                                    size="small"
                                    color="primary"
                                    onChange={(e) =>
                                        setShowParticipantsAssignedToOthers(e.target.checked)
                                    }
                                />
                            }
                            label="Show Assigned"
                            labelPlacement="start"
                        />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ maxHeight: "100%", overflow: "auto" }}>
                    {filteredParticipantList && filteredParticipantList.length > 0 && (
                        <List
                            dense={true}
                            sx={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
                        >
                            {filteredParticipantList.map((pax, i) => (
                                <ListItem key={pax.info.imKey}>
                                    <ListItemButton onClick={() => handleCheckboxChange(pax)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                id={pax.info.imKey.toString()}
                                                // onChange={handleCheckboxChange}
                                                checked={shouldBeChecked(pax)}
                                                inputProps={{
                                                    "aria-labelledby": `pax-select-${pax.info.imKey}`,
                                                }}
                                                disabled={
                                                    props.forPrimaryCoaches &&
                                                    (props.selectedCoach == null ||
                                                        (pax.coachInfo?.imKey !==
                                                            props.selectedCoach?.imKey &&
                                                            pax.coachInfo != null))
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={getPaxLabel(pax)} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default CoachAssignmentParticipantList;
