import * as React from "react";
import { AlertColor, Box, Grid } from "@mui/material";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblRegistrationGeneralTemplate } from "./cclInfoTblRegistrationGeneralTemplate";
import {
    Participant,
    SetParticipantExpirationPayload,
    SetParticipantStatusPayload,
} from "../../../../services/types/enterpriseParticipantApiTypes";
import {
    useSetParticipantExpirationDateMutation,
    useSetParticipantStatusMutation,
} from "../../../../services/cclTokenedEnterpriseParticipantApi";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";
import { AccessEventRegistrationDetails } from "../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import ParticipantAssignedResourcesDataGrid from "./participantAssignedResourcesDataGrid";

interface RegistrationGeneralPanelProps {
    registration: Participant | undefined;
    isNwaSession: boolean;
}

const RegistrationGeneralPanel: React.FC<RegistrationGeneralPanelProps> = (props) => {
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [generalTemplate, setGeneralTemplate] = React.useState<CclInfoTableRowTemplate[]>(
        CclInfoTblRegistrationGeneralTemplate
    );
    const [registration, setRegistration] = React.useState<Participant | undefined>(
        props.registration
    );

    const [saveExpiration, { isLoading: expirationIsLoading }] =
        useSetParticipantExpirationDateMutation();
    const [saveStatus, { isLoading: statusIsLoading }] = useSetParticipantStatusMutation();
    const { logEvent } = useLogAccessEvent();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const saveParticipantDetails = (e: any) => {
        for (let i = 0; i < e.length; i++) {
            if (e[i].name === "expirationDate") {
                if (props.registration) {
                    let expirationArgs: SetParticipantExpirationPayload = {
                        esiKey: props.registration?.esiKey,
                        payload: {
                            ExpirationDate: e[i].value.toISOString(),
                        },
                    };
                    saveExpiration(expirationArgs)
                        .unwrap()
                        .then(() => {
                            const evtData: AccessEventRegistrationDetails = {
                                esiKey: props.registration?.esiKey.toString() ?? "",
                                imKey: props.registration?.imKey.toString() ?? "",
                                email: props.registration?.emailAddress ?? "",
                            };

                            logEvent("RegistrationDetailsEdited", evtData);
                            ShowSnackbarElement("Registration Updated", "success");
                        })
                        .catch(() => {
                            ShowSnackbarElement("Registration Update Failed", "error");
                        });
                }
            } else if (e[i].name === "isComplete") {
                if (props.registration) {
                    let statusArgs: SetParticipantStatusPayload = {
                        esiKey: props.registration?.esiKey,
                        payload: {
                            Completed: e[i].value,
                        },
                    };
                    saveStatus(statusArgs)
                        .unwrap()
                        .then(() => {
                            const evtData: AccessEventRegistrationDetails = {
                                esiKey: props.registration?.esiKey.toString() ?? "",
                                imKey: props.registration?.imKey.toString() ?? "",
                                email: props.registration?.emailAddress ?? "",
                            };

                            logEvent("RegistrationDetailsEdited", evtData);
                            ShowSnackbarElement("Registration Updated", "success");
                        })
                        .catch(() => {
                            ShowSnackbarElement("Registration Update Failed", "error");
                        });
                }
            }
        }
    };

    React.useEffect(() => {
        // a bit of a hack here modifying the template. Need to fix in the future with better info table implementation
        if (props.registration != null) {
            let pax = props.registration;
            pax.sessionStatus = pax.session?.status;
            pax.sessionTitle = pax.session?.title;
            pax.sessionCode = pax.session?.sessionCode;
            pax.sessionKey = pax.session?.sessionKey;
            setRegistration(pax);
            let tmplt = [...generalTemplate];
            let row = tmplt.find((r) => r.id === "fullName");
            if (row != null) {
                row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                row.valueField.url = `/user?email=${encodeURI(
                    props.registration.emailAddress
                )}&imkey=${props.registration.imKey}`;
            }

            row = tmplt.find((r) => r.id === "sessionCode");
            if (row != null) {
                row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                row.valueField.url = `/session/${props.registration.session.sessionKey}`;
            }

            row = tmplt.find((r) => r.id === "sessionKey");
            if (row != null) {
                row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                row.valueField.url = `/session/${props.registration.session.sessionKey}`;
            }

            row = tmplt.find((r) => r.id === "imKey");
            if (row != null) {
                row.valueField.editFieldType = eInfoTableValueFieldEditType.Link;
                row.valueField.url = `/user?email=${props.registration.emailAddress}&imkey=${props.registration.imKey}&firstName=${props.registration.firstName}&lastName=${props.registration.lastName}`;
            }

            if (!pax.isCoachingParticipant) {
                tmplt = tmplt.filter((r) => r.id !== "expirationDate" && r.id !== "isComplete");
            }
            setGeneralTemplate(tmplt);
        }
        // eslint-disable-next-line
    }, [props.registration]);

    if (registration != null) {
        return (
            <Grid container spacing={2} height={"content"}>
                <Grid item xs={12} sm={6}>
                    {showSnackbar ? (
                        <CclAlertSnackbar
                            open={true}
                            onClose={() => setShowSnackbar(false)}
                            message={snackbarMessage}
                            severity={snackbarSeverity}
                        />
                    ) : null}
                    <CclGenericAccordian headingText={"Participant"} sx={{ height: "100%" }}>
                        <Box
                            sx={{
                                m: 2,
                            }}
                        >
                            <CclInfoTable
                                onSave={saveParticipantDetails}
                                template={generalTemplate}
                                data={registration}
                                saving={expirationIsLoading || statusIsLoading}
                            />
                        </Box>
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CclGenericAccordian headingText={"Assigned Resources"} sx={{ height: "100%" }}>
                        <ParticipantAssignedResourcesDataGrid
                            registration={registration}
                            isNwaSession={props.isNwaSession}
                        />
                    </CclGenericAccordian>
                </Grid>
            </Grid>
        );
    }

    return <span>No Registration Data Loaded</span>;
};

export default RegistrationGeneralPanel;
