import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CclButton from "../../../components/common/cclButtons/cclButton";

interface InformationProps {}

const Information: React.FC<InformationProps> = () => {
    return (
        <Box
            sx={{
                display: "flex",
                w: 1,
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
                paddingTop: "5px",
                paddingLeft: "8px",
                paddingBottom: "5px",
            }}
        >
            <Stack
                direction={"row"}
                sx={{
                    width: 1,
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h6"
                    fontWeight={"bold"}
                    width={"12.5%"}
                    sx={{ marginLeft: "6px" }}
                >
                    Applications
                </Typography>
                <Box sx={{ marginLeft: "17px" }}>
                    <CclButton
                        mode="tertiary"
                        onClick={() => {
                            window.open(
                                "https://cclonline.crm.dynamics.com/main.aspx?appid=2939dc9a-0db8-ea11-a813-000d3a5a7503",
                                "_blank"
                            );
                        }}
                        sx={{ width: "122px", m: 1, padding: 2 }}
                    >
                        D365 PSA
                    </CclButton>
                    <CclButton
                        mode="tertiary"
                        onClick={() => {
                            window.open("https://ias.ccl.org/", "_blank");
                        }}
                        sx={{ width: "122px", m: 1, padding: 2 }}
                    >
                        IAS
                    </CclButton>
                    <CclButton
                        mode="tertiary"
                        onClick={() => {
                            window.open("https://www.datasltn.com/wss/CCLLogin.cfm", "_blank");
                        }}
                        sx={{ width: "122px", m: 1, padding: 2 }}
                    >
                        DSI
                    </CclButton>
                    <CclButton
                        mode="tertiary"
                        onClick={() => {
                            window.open("https://cclaccess.ccl.org/", "_blank");
                        }}
                        sx={{ width: "122px", m: 1, padding: 2 }}
                    >
                        CCL Scoring
                    </CclButton>
                    <CclButton
                        mode="tertiary"
                        onClick={() => {
                            window.open("http://vgsoweb01/ccltoolbox/", "_blank");
                        }}
                        sx={{ width: "122px", m: 1, padding: 2 }}
                    >
                        CCL Toolbox
                    </CclButton>
                    <CclButton
                        mode="tertiary"
                        onClick={() => {
                            window.open("http://vgsoweb01/GroupReports/Views/home.aspx", "_blank");
                        }}
                        sx={{ width: "122px", m: 1, padding: 2 }}
                    >
                        Group Reports
                    </CclButton>
                </Box>
            </Stack>
        </Box>
    );
};

export default Information;
