import * as React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ProfileContract } from "../../../services/types/enterpriseCoachingApiTypes";
import CclPersistentDataGrid from "../../../components/common/cclPersistentDataGrid/cclPersistentDataGrid";
import { useSelector } from "react-redux";
import { updateContractsGridState } from "../../../app/slices/resourceContractsSearchListSlice";
import CclCommonLink from "../../../components/common/cclCommonLink";
import { FileDownloadService } from "../../../services/fileDownloadService/fileDownloadService";
import { AlertColor } from "@mui/material";
import CclAlertSnackbar from "../../../components/common/cclAlertSnackbar";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";

function getFullName(params: any) {
    return `${params.row.firstName || "unknown"} ${params.row.lastName || ""}`;
}

function getDateFromTimestamp(params: any) {
    return new Date(params.row.dateSigned).toLocaleDateString();
}

function getTitleFromParams(params: any) {
    let title = params.row.contract.title;
    return title;
}

function getYearFromParams(params: any) {
    let year = params.row.contract.year;
    return year;
}

function checkEmail(params: any) {
    return `${params.row.email || "unknown"}`;
}

export interface ContractSearchListProps {
    contracts: ProfileContract[];
    loading: boolean;
}

export const ContractSearchDataGridPro: React.FC<ContractSearchListProps> = (props) => {
    const [showStatusDialog, setShowStatusDialog] = React.useState<boolean>(false);
    const [statusMessage, setStatusMessage] = React.useState<string>("");
    const [statusSeverity, setStatusSeverity] = React.useState<AlertColor>("success");
    const [loading, setLoading] = React.useState<boolean>(false);
    const { logEvent } = useLogAccessEvent();

    const COLUMNS = [
        {
            field: "name",
            headerName: "Resource",
            type: "string",
            valueGetter: getFullName,
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`/resource/${params.row.profileKey}/0`}
                />
            ),
            flex: 0.6,
        },
        {
            field: "email",
            headerName: "Email",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={checkEmail(params)}
                    keyVal={params.row.email}
                    to={`mailto:${params.row.email}`}
                    type={"email"}
                />
            ),
            type: "string",
            flex: 0.8,
        },
        {
            field: "year",
            headerName: "Year",
            valueGetter: getYearFromParams,
            type: "string",
            flex: 0.3,
        },
        {
            field: "status",
            headerName: "Status",
            type: "string",
            valueGetter: (params: GridRenderCellParams) => {
                let s = params.row.status;
                if (s === 0) return "Pending";
                if (s === 1) return "Signed";
                if (s === 2) return "Declined";
                else return "";
            },
            flex: 0.3,
        },
        {
            field: "title",
            headerName: "Title",
            valueGetter: getTitleFromParams,
            type: "string",
            flex: 1.5,
        },
        {
            field: "dateSigned",
            headerName: "Date Signed",
            valueGetter: getDateFromTimestamp,
            flex: 0.4,
        },
    ];

    const downloadService = new FileDownloadService();
    const stateSelector = useSelector((state: any) => state.contractsSearchTableState);

    const handleDownload = (rows?: any[]) => {
        if (rows == null || rows?.length <= 0) {
            setShowStatusDialog(true);
            setStatusMessage("Download Failed");
            setStatusSeverity("error");
            return;
        }

        let signedContracts = rows?.filter((r) => r.status === 1);
        if (signedContracts.length <= 0) {
            setShowStatusDialog(true);
            setStatusMessage("Download Failed");
            setStatusSeverity("error");
            return;
        }

        let aikeys: number[] = signedContracts.filter((r) => r.aiKey != null).map((r) => r.aiKey);
        aikeys.push(...signedContracts.filter((r) => r.aiKey == null).map((r) => r.contract.aiKey));
        const uniqueAiKeys = [...new Set(aikeys.filter((x) => x !== null))];

        let filename = "Contracts.zip";
        if (uniqueAiKeys.length === 1) {
            const c = props.contracts.find(
                (c) =>
                    c.aiKey === uniqueAiKeys[0] ||
                    (c.aiKey == null && c.contract.aiKey === uniqueAiKeys[0])
            );
            filename =
                c == null || c.contract?.title == null || c.contract?.title === ""
                    ? "Contract.pdf"
                    : c.contract.title;
        }
        setLoading(true);
        downloadService
            .DownloadAssets({ fname: filename, aikeys: uniqueAiKeys, flatzip: false })
            .then(() => {
                logEvent("ContractDownloaded", null);
                setShowStatusDialog(true);
                setStatusMessage("Download Completed");
                setStatusSeverity("success");
                setLoading(false);
            })
            .catch((error) => {
                setShowStatusDialog(true);
                setStatusMessage("Download Failed");
                setStatusSeverity("error");
                setLoading(false);
            });
    };

    return (
        <React.Fragment>
            <CclAlertSnackbar
                open={showStatusDialog}
                severity={statusSeverity}
                message={statusMessage}
                onClose={() => setShowStatusDialog(false)}
            />
            <CclPersistentDataGrid
                columns={COLUMNS}
                data={props.contracts.filter(
                    (contract, index, self) =>
                        index ===
                        self.findIndex((t) => t.profileContractId === contract.profileContractId)
                )}
                loading={props.loading}
                stateSelector={stateSelector}
                userPreferenceId={15}
                getRowIdFn={(row: ProfileContract) => row.profileContractId}
                actionCreator={updateContractsGridState}
                initState={JSON.stringify({
                    sorting: { sortModel: [{ field: "name", sort: "asc" }] },
                })}
                miscButtons={[
                    {
                        id: "download-contracts-button",
                        caption: "Download Contract(s)",
                        onClick: handleDownload,
                        returnSelected: true,
                        restricted: false,
                        loadingBtn: true,
                        isLoading: loading,
                        enableOnSelect: true,
                    },
                ]}
                hideSettingsButtons={true}
                showCheckboxes={true}
                disableSelectionOnClick={true}
                hideFooter={false}
            />
        </React.Fragment>
    );
};

export default ContractSearchDataGridPro;
