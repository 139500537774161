import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { GcSystem } from "../../../services/types/cclGrandCentralApiTypes";
import { CclInfoTblSystemTemplate } from "./cclInfoTblSystemTemplate";
import CclInfoTable, {
    CclInfoTableFormValue,
} from "../../../components/common/cclInfoTable/cclInfoTable";
import { useUpdateCclSystemMutation } from "../../../services/cclTokenedGrandCentralApi";
import CclErrorDialog from "../../../components/common/cclErrorDialog";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { AccessEventGCSystemDetails } from "../../../services/types/accessEventTypes";

export type SystemDetailsPanelProps = {
    system: GcSystem;
    readonly: boolean;
};

export const SystemDetailsPanel: React.FC<SystemDetailsPanelProps> = (props) => {
    const [showErrorDialog, setShowErrorDialog] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [updateSystem] = useUpdateCclSystemMutation();
    const { logEvent } = useLogAccessEvent();

    const save = (changes: CclInfoTableFormValue[]) => {
        if (changes.length !== 1) return;

        updateSystem({ name: changes[0].value, systemId: props.system.systemId })
            .unwrap()
            .then(() => {
                const evtData: AccessEventGCSystemDetails = {
                    systemId: props.system.systemId,
                    systemName: props.system.systemName,
                };
                logEvent("GCSystemChanged", evtData);
            })
            .catch((error) => {
                setErrorMessage(`Error updating system: ${error.data}`);
                setShowErrorDialog(true);
            });
    };

    return (
        <>
            <CclErrorDialog
                open={showErrorDialog}
                onOk={() => setShowErrorDialog(false)}
                msg={errorMessage}
                title="Error Updating System Information"
            />

            <Card variant="outlined" sx={{ width: 1, height: 1 }}>
                <CardHeader title={"System"} />
                <CardContent>
                    <CclInfoTable
                        template={CclInfoTblSystemTemplate}
                        data={props.system}
                        onSave={(changes: CclInfoTableFormValue[]) => save(changes)}
                        readonly={props.readonly}
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default SystemDetailsPanel;
