import * as React from "react";
import { Stack, Card, Grid, AlertColor, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    ProductSaleListConfigState,
    updateProductSaleSearchParams,
} from "../../../app/slices/productSaleListConfigSlice";
import CclErrorDialog from "../../../components/common/cclErrorDialog";
import {
    useChangeProductSalesFacilitatorMutation,
    useGetProductSaleParticipantsByDateMutation,
    useLazyGetParticipantCompassUrlQuery,
    useLazyGetProductSaleParticipantsByFacilitatorOrParticipantEmailQuery,
    useReleaseProductSalesMutation,
} from "../../../services/cclTokenedEnterpriseParticipantApi";
import {
    ChangeProductSalesFacilitatorPayload,
    GetCompassUrlResponse,
    ProductSaleParticipant,
} from "../../../services/types/enterpriseParticipantApiTypes";
import ProductSaleAssessmentList from "./productSaleAssessmentList";
import { useSendCompassInvitationsMutation } from "../../../services/cclTokenedGrandCentralApi";
import {
    SendCompassInvitationRequest,
    SendInvitationResponse,
} from "../../../services/types/rtkQueryTypes";
import { FileDownloadService } from "../../../services/fileDownloadService/fileDownloadService";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import CclSearchDefault from "../../../components/common/cclSearchDefault";
import CclAlertSnackbar from "../../../components/common/cclAlertSnackbar";
import CclEnterNewFacilitatorDrawer from "./cclEnterNewFacilitatorDrawer";
import { useSearchParams } from "react-router-dom";
import CclTextOrDateSearchBar from "../../../components/common/cclLandingPageSearchBars/cclTextOrDateSearchBar";

export type ProductSalesCardProps = {
    searchparam: string;
};

const ProductSalesCard: React.FC = () => {
    const { logEvent } = useLogAccessEvent();
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.productSaleListConfig);
    const [localConfig, setLocalConfig] = React.useState<ProductSaleListConfigState>({ ...config });

    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [currentEvent, setCurrentEvent] = React.useState<string>("");
    const [reportsToReplace, setReportsToReplace] = React.useState<string[]>([]);
    const [errorOpen, setErrorOpen] = React.useState<boolean>(false);
    const [productSales, setProductSales] = React.useState<ProductSaleParticipant[] | undefined>(
        undefined
    );
    const [dataLoading, setDataLoading] = React.useState<boolean>(false);
    const [openFacilitatorEmailDialog, setOpenFacilitatorEmailDialog] =
        React.useState<boolean>(false);

    const [runSearchByDate] = useGetProductSaleParticipantsByDateMutation();
    const [runSearchByEmail] =
        useLazyGetProductSaleParticipantsByFacilitatorOrParticipantEmailQuery();
    const [sendChangeFacilitator, { isSuccess: changeFacilitatorSuccess }] =
        useChangeProductSalesFacilitatorMutation();
    const [releaseReports] = useReleaseProductSalesMutation();
    const [sendCompassInvitations] = useSendCompassInvitationsMutation();
    const [getCompassUrl] = useLazyGetParticipantCompassUrlQuery();
    const downloadService = new FileDownloadService();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const getAssessmentsByEmail = async (email?: string) => {
        if (!email) return;

        const newLocalConfig = {
            StartDate: "",
            EndDate: "",
            Email: email.trim(),
            IsEmailSearchActive: true,
        };
        setLocalConfig(newLocalConfig);
        setDataLoading(true);
        runSearchByEmail(email.trim())
            .unwrap()
            .then((results: ProductSaleParticipant[]) => {
                setDataLoading(false);
                setProductSales(results);
            })
            .catch((error) => {
                setDataLoading(false);
                setErrorOpen(true);
                setProductSales(undefined);
            });
        dispatch(updateProductSaleSearchParams(newLocalConfig));
    };

    const getAssessmentsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            return;
        }
        const newLocalConfig = {
            StartDate: startDate.toISOString(),
            EndDate: endDate.toISOString(),
            Email: "",
            IsEmailSearchActive: false,
        };
        setLocalConfig(newLocalConfig);
        setDataLoading(true);
        runSearchByDate({
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        })
            .unwrap()
            .then((results: ProductSaleParticipant[]) => {
                setDataLoading(false);
                setProductSales(results);
            })
            .catch((error) => {
                setDataLoading(false);
                setErrorOpen(true);
                setProductSales(undefined);
            });

        dispatch(updateProductSaleSearchParams(newLocalConfig));
    };

    const getAssessments = async (email: string, startDate: Date | null, endDate: Date | null) => {
        if (email === "") {
            getAssessmentsByDate(startDate, endDate);
        } else {
            getAssessmentsByEmail(email);
        }
    };

    React.useEffect(() => {
        if (!params.get("searchparam")) {
            return;
        }
        getAssessmentsByEmail(params.get("searchparam") ?? "");
        //eslint-disable-next-line
    }, [params]);

    React.useEffect(() => {
        if (!changeFacilitatorSuccess) return;

        if (localConfig.IsEmailSearchActive) {
            getAssessmentsByEmail(localConfig.Email.trim());
        } else {
            getAssessmentsByDate(new Date(localConfig.StartDate), new Date(localConfig.EndDate));
        }
        // eslint-disable-next-line
    }, [changeFacilitatorSuccess]);

    const handleChangeFacilitator = (email: string) => {
        if (reportsToReplace?.length > 0 && email !== "") {
            setCurrentEvent("change-facilitator");

            let payload: ChangeProductSalesFacilitatorPayload = {
                facilitatorEmail: email,
                reportIds: reportsToReplace,
            };
            sendChangeFacilitator(payload)
                .unwrap()
                .then(() => {
                    logEvent("FacilitatorChanged", null);
                    ShowSnackbarElement("Facilitator Updated", "success");
                    setOpenFacilitatorEmailDialog(false);
                    setCurrentEvent("");
                })
                .catch((error) => {
                    ShowSnackbarElement("Facilitator Update Failed", "error");
                    setOpenFacilitatorEmailDialog(false);
                    setCurrentEvent("");
                });
        } else {
            ShowSnackbarElement("Facilitator Update Failed", "error");
            setOpenFacilitatorEmailDialog(false);
            setCurrentEvent("");
        }
    };

    const changeFacilitator = (rows?: any[]) => {
        if (rows != null && rows?.length > 0) {
            var reportIds: string[] = rows.map((r) => r.reportId);
            setReportsToReplace([...reportIds]);
            setOpenFacilitatorEmailDialog(true);
        } else {
            setReportsToReplace([]);
            setOpenFacilitatorEmailDialog(false);
        }
    };

    const handleDownloadReports = (rows?: any[]) => {
        if (rows === undefined || rows.length <= 0) {
            return;
        }

        setCurrentEvent("download");
        const reportIds = rows.map((r) => r.reportId as string);
        let filename = "ProductSales.zip";
        if (rows.length === 1) {
            if (rows[0] == null) return; // shouldn't happen but just in case
            filename = `${rows[0].fullName} ${rows[0].assessmentType}.pdf`;
        }

        downloadService
            .DownloadProductSaleReports({
                fname: filename,
                reportids: reportIds,
            })
            .then(() => {
                logEvent("UserDownloadedProductSale", null);
                ShowSnackbarElement("Download Completed", "success");
                setCurrentEvent("");
            })
            .catch((error) => {
                ShowSnackbarElement("Download Failed", "error");
                setCurrentEvent("");
            });
    };

    const handleActivateCompass = (rows?: any[]) => {
        if (rows === undefined || rows.length <= 0) {
            return;
        }

        setCurrentEvent("activate");
        const reportIds = rows.map((r) => r.reportId as string);
        releaseReports(reportIds)
            .unwrap()
            .then((releaseResults) => {
                if (releaseResults === undefined || releaseResults.length <= 0) {
                    ShowSnackbarElement("Compass Activation Failed", "error");
                    setCurrentEvent("");
                    return;
                }

                const releasedReportIds: string[] =
                    releaseResults?.filter((r) => r.success).map((r) => r.reportId) ?? [];

                if (releasedReportIds != null && releasedReportIds.length > 0) {
                    const prodSaleParticipants: ProductSaleParticipant[] =
                        productSales?.filter((ps) =>
                            releasedReportIds.some((r) => r === ps.reportId)
                        ) ?? [];
                    const sendInvitesPayload: SendCompassInvitationRequest = {
                        recipients: prodSaleParticipants.map((p) => {
                            return {
                                email: p.emailAddress,
                                firstName: p.firstName,
                                lastName: p.lastName,
                            };
                        }),
                        disableEmail: false,
                        autoAccept: false,
                        ignorePendingInvites: false,
                    };
                    //refresh product sale search results
                    if (localConfig.IsEmailSearchActive) {
                        getAssessmentsByEmail(localConfig.Email);
                    } else {
                        getAssessmentsByDate(
                            new Date(localConfig.StartDate),
                            new Date(localConfig.EndDate)
                        );
                    }
                    sendCompassInvitations(sendInvitesPayload)
                        .unwrap()
                        .then((response: SendInvitationResponse) => {
                            if (response.numberInivitationsSent > 0) {
                                logEvent("UserActivatedProductSales", null);
                                ShowSnackbarElement("Compass Activation Completed", "success");
                            } else if (response.numberFailed > 0) {
                                ShowSnackbarElement("Compass Activation Failed", "error");
                            }
                            setCurrentEvent("");
                        })
                        .catch((error) => {
                            ShowSnackbarElement("Compass Activation Failed", "error");
                            setCurrentEvent("");
                        });
                } else {
                    ShowSnackbarElement("Compass Activation Failed", "error");
                    setCurrentEvent("");
                }
            })
            .catch((error) => {
                ShowSnackbarElement("Compass Activation Failed", "error");
                setCurrentEvent("");
            });
    };

    const viewCompass = (imkey: number) => {
        // get the url
        getCompassUrl(imkey)
            .unwrap()
            .then((response: GetCompassUrlResponse) => {
                window.open(response.tempCompassUrl);
                logEvent("UserViewedCompass", null);
            })
            .catch((error) => {
                ShowSnackbarElement("Error Opening Compass", "error");
            });
    };

    return (
        <Stack height={1} width={1}>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            <CclEnterNewFacilitatorDrawer
                open={openFacilitatorEmailDialog}
                onOk={(email: string) => handleChangeFacilitator(email)}
                onCancel={() => setOpenFacilitatorEmailDialog(false)}
                saving={currentEvent === "change-facilitator"}
            />
            <CclErrorDialog
                open={errorOpen}
                title={"Error Retrieving Product Sale Assessments"}
                msg={"Make sure the date range for your search is not larger than one month."}
                onOk={() => setErrorOpen(false)}
            ></CclErrorDialog>
            <Card
                sx={{
                    width: 1,
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ flex: "1", paddingRight: 1 }}>
                        <CclTextOrDateSearchBar
                            executeSearch={getAssessments}
                            initialTextFieldSearchTerm={
                                localConfig.IsEmailSearchActive ? localConfig.Email : ""
                            }
                            textFieldSearchLabel="Enter Participant or Facilitator Email"
                            initialStartDate={
                                localConfig.IsEmailSearchActive || localConfig.StartDate === ""
                                    ? null
                                    : new Date(localConfig.StartDate)
                            }
                            initialEndDate={
                                localConfig.IsEmailSearchActive || localConfig.EndDate === ""
                                    ? null
                                    : new Date(localConfig.EndDate)
                            }
                            startDateLabel="Earliest Scored Date"
                            endDateLabel="Latest Scored Date"
                            allowNullDates={false}
                            showResetButton={true}
                            clearSearchTrigger={undefined}
                        />
                    </Box>
                </Box>
                {productSales != null ? (
                    <Grid
                        sx={{
                            height: 1,
                            width: 1,
                            padding: 2,
                            paddingTop: 0,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <ProductSaleAssessmentList
                            loading={dataLoading}
                            productSales={productSales ?? []}
                            changeFacilitator={(rows?: any[]) => changeFacilitator(rows)}
                            downloadReports={(rows?: any[]) => handleDownloadReports(rows)}
                            activateCompass={(rows?: any[]) => handleActivateCompass(rows)}
                            viewCompass={viewCompass}
                            currentEvent={currentEvent}
                        />
                    </Grid>
                ) : (
                    <CclSearchDefault
                        line1="Start searching for Product Sales"
                        line2="Enter Participant or Facilitator Email or enter a Date Range and click Search"
                    />
                )}
            </Card>
        </Stack>
    );
};

export default ProductSalesCard;
