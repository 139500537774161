import { CclInfoTableRowTemplate } from "../../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclInfoTblSessionInfoTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "status",
        fieldType: "string",
        editable: false,
        label: "Status",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
        sx: { fontWeight: "bold" },
    },
    {
        id: "sessionKey",
        fieldType: "number",
        editable: false,
        label: "Session ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "sessionCode",
        fieldType: "string",
        editable: false,
        label: "Project ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "title",
        fieldType: "string",
        editable: false,
        label: "Session Title",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "customer",
        fieldType: "string",
        editable: false,
        label: "Customer",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "accountManager",
        fieldType: "string",
        editable: false,
        label: "Account Manager",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "accountManagerEmail",
        fieldType: "string",
        editable: false,
        label: "Account Manager Email",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: "mailto:@@@",
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "projectManager",
        fieldType: "string",
        editable: false,
        label: "Project Manager",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "projectManagerEmail",
        fieldType: "string",
        editable: false,
        label: "Project Manager Email",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: "mailto:@@@",
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "type",
        fieldType: "string",
        editable: false,
        label: "Type",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "code",
        fieldType: "string",
        editable: false,
        label: "Code",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "sessionFormat",
        fieldType: "string",
        editable: false,
        label: "Session Format",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "managingLocation",
        fieldType: "string",
        editable: false,
        label: "Managing Location",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "deliveryLocation",
        fieldType: "string",
        editable: false,
        label: "Delivery Location",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "networkPartner",
        fieldType: "string",
        editable: false,
        label: "Network Partner",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "hostingPlatform",
        fieldType: "string",
        editable: false,
        label: "Hosting Platform",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "assessmentPlatform",
        fieldType: "string",
        editable: false,
        label: "Assessment Platform",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: 0,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "hasCompassSku",
        fieldType: "string",
        editable: false,
        label: "Compass",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.YesNoLabel,
            url: null,
            hoverText: null,
            checked: null,
            id: "",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
