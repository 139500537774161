import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Stack, TextField } from "@mui/material";
import { StoreAccessEventPayload } from "../../../../services/types/accessEventTypes";
import { useStoreAccessEventMutation } from "../../../../services/cclTokenedAccessEventsApi";
import FlexGrid from "../../../../layouts/flexGrid";
import {
    useAddUpdateTargetRevenueMutation,
    useDeleteTargetRevenueMutation,
    useGetCeSystemUsersQuery,
    useSearchTargetRevenueMutation,
} from "../../../../services/cclTokenedCustomerHierarchyApi";
import {
    ClientSearchListConfigState,
    updateClientSearchParams,
} from "../../../../app/slices/clientSearchListConfigSlice";
import {
    AddTargetRevenue,
    TargetRevenueRequest,
    TargetRevenueResponse,
} from "../../../../services/types/clientTypes";
import CclErrorDialog from "../../../../components/common/cclErrorDialog";
import ComponentLoader from "../../../../components/common/componentLoader";
import TargetRevenueDataGridPro from "./targetRevenueDataGridPro";
import AddTargetRevenueDialog from "./addTargetRevenueDialog";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

interface TargetRevenuePanelProps {}

const TargetRevenuePanel: React.FC<TargetRevenuePanelProps> = () => {
    // redux state
    const dispatch = useDispatch();
    const { data: CESystemOwnerData } = useGetCeSystemUsersQuery();
    const config: ClientSearchListConfigState = useSelector(
        (state: any) => state.userSearchListConfig
    );
    const [searchPayload, setSearchPayload] = React.useState<TargetRevenueRequest>({
        fiscal_year: 0,
        account_name: "",
        wildcardSearch: true,
    });
    const [searchFiscalYear, setSearchFiscalYear] = React.useState<number | undefined>(
        config.FiscalYear
    );
    const [fiscalSearchError, setFiscalSearchError] = React.useState<boolean>(false);
    const [searchAccountName, setSearchAccountName] = React.useState<string>(config.AccountName);
    /*   const [wildSearch, setWildSearch] = React.useState<boolean>(config.WildSearch);
    const [searchResultString, setSearchResultString] = React.useState<string>(""); */
    const [searchUsers, { data: targetRevenueData, isLoading, isSuccess }] =
        useSearchTargetRevenueMutation();
    const [deleteData] = useDeleteTargetRevenueMutation();
    const [
        triggerCreateNewRevenue,
        {
            data: newTargetRevenueData,
            isLoading: addTargetRevenueLoading,
            isError: addTargetRevenueIsError,
            isSuccess: addTargetRevenueSuccess,
        },
    ] = useAddUpdateTargetRevenueMutation();
    const [showSearchToBroad, setShowSearchToBroad] = React.useState<boolean>(false);
    const [showAddtargetRevenueDialog, setShowAddtargetRevenueDialog] =
        React.useState<boolean>(false);
    const [showAlert, setShowAlert] = React.useState<boolean>(false);
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
    const [storeAccessEvent] = useStoreAccessEventMutation();
    const [fiscalYearEdit, setfiscalYearEdit] = React.useState<number>();
    const [accountOwnerEdit, setAccountOwnerEdit] = React.useState<string>("");
    const [revenueTargetEdit, setRevenueTargetEdit] = React.useState<number>();
    const [addUpdateEdit, setAddUpdateEdit] = React.useState<boolean>(false);

    const [targetRevenueNewData, setTargetRevenueNewData] =
        React.useState<TargetRevenueResponse[]>();

    React.useEffect(() => {
        if (isSuccess && targetRevenueData && targetRevenueData.length >= 30000)
            setShowSearchToBroad(true);
        if (isSuccess) setTargetRevenueNewData(targetRevenueData);
    }, [targetRevenueData, isSuccess]);

    React.useEffect(() => {
        if (addTargetRevenueSuccess && newTargetRevenueData === true) {
            const payload: StoreAccessEventPayload = {
                accessEventTypeId: 0,
                accessEventData: JSON.stringify("Added Traget Revenue"),
            };
            storeAccessEvent(payload);
        }
        // eslint-disable-next-line
    }, [addTargetRevenueSuccess, newTargetRevenueData]);

    React.useEffect(() => {
        setShowSpinner(addTargetRevenueLoading);
    }, [addTargetRevenueLoading]);

    React.useEffect(() => {
        if (
            searchPayload.account_name &&
            searchPayload.account_name.length === 0 &&
            searchPayload.fiscal_year &&
            searchPayload.fiscal_year <= 0
        )
            return;

        searchUsers(searchPayload);
        dispatch(
            updateClientSearchParams({
                FiscalYear: searchPayload.fiscal_year ?? 0,
                AccountName: searchPayload.account_name ?? "",
                WildSearch: true,
            })
        );
        // eslint-disable-next-line
    }, [searchPayload]);

    const getTargetRevenue = async () => {
        if (searchFiscalYear && searchFiscalYear === 0) {
            setFiscalSearchError(true);
            return;
        }
        setFiscalSearchError(false);
        setSearchPayload({
            fiscal_year: searchFiscalYear ?? 0,
            account_name: searchAccountName ?? "",
            wildcardSearch: true,
        });
    };

    const handleSearchKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            evt.preventDefault();
            getTargetRevenue();
        }
    };

    const addTargetRevenue = async (
        fiscalYear: number,
        ceSystem: string,
        revenueAmount: number,
        updateRevenueData: boolean
    ) => {
        var CEsystemID =
            CESystemOwnerData?.filter((c) => c.account_Name === ceSystem)[0].systemUserId ?? "";
        const payload: AddTargetRevenue = {
            fiscalYear: fiscalYear,
            cESystemID: CEsystemID,
            revenueValue: revenueAmount,
            updateData: updateRevenueData,
        };
        triggerCreateNewRevenue(payload)
            .unwrap()
            .then(() => {
                setShowAlert(true);
            });
        setShowAddtargetRevenueDialog(false);
        const updatedTargetRevenueData = targetRevenueNewData?.map((a) => {
            if (a.cesystemUserId !== CEsystemID) {
                return a;
            } else {
                return {
                    ...a,
                    fiscal_year: fiscalYear,
                    revenue: revenueAmount,
                };
            }
        });
        setTargetRevenueNewData(updatedTargetRevenueData);
        setfiscalYearEdit(0);
        setAccountOwnerEdit("");
        setRevenueTargetEdit(0);
        setAddUpdateEdit(false);
    };

    const editTargetRevenue = async (targetRevenueID: number) => {
        var targetRevenueEdit = targetRevenueNewData?.filter(
            (c) => c.revenueId === targetRevenueID
        )[0];
        if (targetRevenueEdit) {
            setfiscalYearEdit(targetRevenueEdit?.fiscalYear);
            setAccountOwnerEdit(targetRevenueEdit?.accountOwnerName);
            setRevenueTargetEdit(targetRevenueEdit?.revenue);
            setAddUpdateEdit(true);
        }
        setShowAddtargetRevenueDialog(true);
    };

    const deleteTargetRevenue = async (targetRevenueID: number) => {
        deleteData(targetRevenueID);
        var targetRevenueEdit = targetRevenueNewData?.filter(
            (c) => c.revenueId !== targetRevenueID
        );
        setTargetRevenueNewData(targetRevenueEdit);
    };

    return (
        <Stack height={1} width={1}>
            {showSpinner && <ComponentLoader msg={"Updating"} />}
            <CclErrorDialog
                open={showSearchToBroad}
                title={"Too Many Results"}
                msg={
                    "There are too many results for this search. Consider adjusting your search parameters and searching again."
                }
                onOk={() => setShowSearchToBroad(false)}
            />

            <CclAlertSnackbar 
                open={showAlert} 
                message={addTargetRevenueIsError ? "Adding new target revenue failed" : "Target revenue added"} 
                severity={addTargetRevenueIsError ? "error" : "success"}
                onClose={function (): void {
                    setShowAlert(false);
                }}            
            />

            <AddTargetRevenueDialog
                open={showAddtargetRevenueDialog}
                onOk={addTargetRevenue}
                onCancel={() => {
                    setfiscalYearEdit(0);
                    setAccountOwnerEdit("");
                    setRevenueTargetEdit(0);
                    setAddUpdateEdit(false);
                    setShowAddtargetRevenueDialog(false);
                }}
                CESystemData={CESystemOwnerData ?? []}
                accountOwnerValue={accountOwnerEdit}
                fiscalYearValue={fiscalYearEdit ?? 0}
                addUpdateValue={addUpdateEdit}
                revenueTargetValue={revenueTargetEdit ?? 0}
            />

            <Grid item xs={12} md={5}>
                <Box
                    sx={{
                        m: 2,
                        width: 1,
                    }}
                >
                    <Grid container component="form" sx={{alignItems: "center"}}>
                        <Grid item xs={6} md={5} sx={{ p: 1 }}>
                            <TextField
                                value={searchFiscalYear}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-email-search"
                                label="Enter Fiscal Year"
                                variant="outlined"
                                error={fiscalSearchError}
                                helperText={
                                    fiscalSearchError
                                        ? "Target Revenue should contain only numbers"
                                        : ""
                                }
                                onChange={(e) => {
                                    e.preventDefault();
                                    e.target.value.replace(/\D/g, "") !== ""
                                        ? setSearchFiscalYear(parseInt(e.target.value))
                                        : setSearchFiscalYear(undefined);
                                    e.target.value === ""
                                        ? setFiscalSearchError(false)
                                        : setFiscalSearchError(
                                              e.target.value.replace(/\D/g, "") === ""
                                          );
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={5} sx={{ p: 1 }}>
                            <TextField
                                value={searchAccountName}
                                sx={{ width: 1, maxHeight: "57px" }}
                                id="outlined-adornment-email-search"
                                label="Enter Account Name"
                                variant="outlined"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setSearchAccountName(e.target.value);
                                }}
                                onKeyPress={(e) => handleSearchKeyPress(e)}
                            />
                        </Grid>
                        <Grid item xs={6} md={1} sx={{ p: 1 }}> 
                            <CclButton
                                onClick={() => getTargetRevenue()}
                                aria-label="Search by Email"
                                size='small'
                                sx={{ width: 1, height: 1, maxHeight: "57px", marginLeft: "10px" }}
                                mode={"primary"} >
                                Search
                            </CclButton>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} md={5}>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                    <CclButton
                        onClick={(evt) => {
                            evt.stopPropagation();
                            setShowAddtargetRevenueDialog(true);
                            setAddUpdateEdit(false);
                        } }
                        sx={{
                            m: 1,
                            mr: 2,        
                            padding: "5px",
                            float: "right",
                        }} mode={"primary"}>
                        Add New Target Revenue
                    </CclButton>
                </Stack>
            </Grid>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    m: 2,
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <FlexGrid>
                    <TargetRevenueDataGridPro
                        targetRevenueList={targetRevenueNewData ?? []}
                        isLoading={isLoading}
                        showToolbar={!isLoading}
                        OnTargetRevenueDelete={deleteTargetRevenue}
                        OnTargetRevenueEdit={editTargetRevenue}
                    />
                </FlexGrid>
            </Box>
        </Stack>
    );
};

export default TargetRevenuePanel;
