import * as React from "react";
import { Alert, Grid, Stack } from "@mui/material";
import {
    useGetCclGCUserAccountDetailQuery,
    useGetCclUserAccountDetailQuery,
    useGetCclUserAuth0DetailQuery,
} from "../../../../services/cclTokenedGrandCentralApi";
import ComponentLoader from "../../../../components/common/componentLoader";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import GrandCentralIdentitiesCard from "./grandCentralIdentitiesCard";
import GrandCentralEmailsCard from "./grandCentralEmailsCard";
import Auth0DetailsCard from "./auth0DetailCard";
import UserDetailsCard from "./userDetailsCard";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { Profile } from "../../../../services/types/enterpriseCoachingApiTypes";
import { flattenObject } from "../../../../utilities/flattenObject";
import { CclInfoTblResourceInfoTemplate } from "../../../resources/resourceDetail/info/cclInfoTblResourceInfoTemplate";
import { useGetResourceByEmailQuery } from "../../../../services/cclTokenedResourceApi";

interface UserGeneralPanelProps {
    emailAddress: string;
    imkey: number;
    profile: Profile;
}

const UserGeneralPanel: React.FC<UserGeneralPanelProps> = (props) => {
    const [modTemplate, setModTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);

    const { data: enterpriseData } = useGetCclUserAccountDetailQuery(props.emailAddress, {
        skip: props.emailAddress == null || props.emailAddress === "",
    });
    const { data: resource } = useGetResourceByEmailQuery(props.emailAddress, {
        skip: props.emailAddress == null || props.emailAddress === "",
    });

    const claimsService = new UserClaimsService();
    const isUserAdmin = claimsService.IsUserSuperAdmin();

    const {
        data: auth0Data,
        isLoading: auth0Loading,
        isSuccess: auth0Success,
    } = useGetCclUserAuth0DetailQuery(encodeURI(props.emailAddress), {
        skip: props.emailAddress == null || props.emailAddress === "",
    });
    const { data: grandCentralData, isLoading: grandCentralLoading } =
        useGetCclGCUserAccountDetailQuery(props.emailAddress, {
            skip: props.emailAddress == null || props.emailAddress === "",
        });

    React.useEffect(() => {
        if (!props.profile || !props.profile.email) return;
        const enterpriseDetail = enterpriseData?.find(
            (e) => e.email.toLowerCase() === props.profile.email.toLowerCase()
        );

        var newTemplate = CclInfoTblResourceInfoTemplate;
        var rowitem = newTemplate.find((r) => r.id === "ceContactId");
        if (typeof rowitem !== "undefined" && rowitem !== null) {
            rowitem.valueField.value = enterpriseDetail?.ceContactId ?? "";
        }

        rowitem = newTemplate.find((r) => r.id === "bookableresourceid");
        if (typeof rowitem !== "undefined" && rowitem !== null && resource) {
            rowitem.valueField.value =
                resource[0]?.bookableresourceid ?? "This user has no bookable resource id";
        }

        setModTemplate([...newTemplate]);
    }, [props.profile, enterpriseData, resource]);

    const handleSave = () => {
        return;
    };

    const handleChange = () => {
        return;
    };

    if (auth0Loading || grandCentralLoading) {
        return <ComponentLoader msg={"Loading User Data"} />;
    }

    if (auth0Success) {
        return (
            <Stack height={1} width={1}>
                <Grid container spacing={2}>
                    {enterpriseData != null && !auth0Loading && (
                        <Grid item xs={6}>
                            <UserDetailsCard
                                gcDetail={grandCentralData ?? null}
                                enterpriseDetails={enterpriseData ?? null}
                                gcAccountEmail={props.emailAddress}
                                imKey={props.imkey}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <CclGenericAccordian headingText={"D365"}>
                            {!props.profile.email && (
                                <Alert severity="info">
                                    No D365 Bookable Resource Record exists for this user{" "}
                                </Alert>
                            )}
                            {props.profile.email && (
                                <CclInfoTable
                                    template={modTemplate}
                                    data={flattenObject(props.profile)}
                                    onSave={handleSave}
                                    onChange={handleChange}
                                />
                            )}
                        </CclGenericAccordian>
                    </Grid>
                    {auth0Data != null && (
                        <Grid item xs={6}>
                            <Auth0DetailsCard
                                gcAccountEmail={props.emailAddress}
                                auth0Detail={auth0Data}
                                isUserAdmin={isUserAdmin}
                                imKey={props.imkey.toString()}
                            />
                        </Grid>
                    )}
                    {grandCentralData != null && (
                        <Grid item xs={6}>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <GrandCentralIdentitiesCard
                                    gcAccountId={grandCentralData.accountId}
                                    gcIdentities={grandCentralData.identities}
                                    gcAccountEmail={props.emailAddress}
                                    isUserAdmin={isUserAdmin}
                                    tenantId={grandCentralData.users[0]?.tenantId ?? ""}
                                    imKey={props.imkey.toString()}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <GrandCentralEmailsCard
                                    gcAccountId={grandCentralData.accountId}
                                    gcEmails={grandCentralData.emails}
                                    gcAccountEmail={props.emailAddress}
                                    isUserAdmin={isUserAdmin}
                                    imKey={props.imkey.toString()}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Stack>
        );
    }

    return <span>No User Account Data Loaded</span>;
};

export default UserGeneralPanel;
