import * as React from "react";
import {
    DataGridPro,
    GridRowId,
    GridSelectionModel,
} from "@mui/x-data-grid-pro";
import { HomeIndustriesList, OrganizationSizeList, SectorList } from "../../../../services/types/clientTypes";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import HomeIndustriesEditDialog from "./homeIndustriesEditDialog";

export interface HomeIndustriesDataGridProProps {
    homeIdustriesList: HomeIndustriesList[] | undefined;
    sectorList : SectorList[];
    orgSizeList : OrganizationSizeList[];
    showToolbar: boolean;
    OnSelectDetails: (parentKey: number, childKey: number) => void;
    OnSectorChange: (childKey: number, sectorNmae: string) => void;
    OnOrgChange: (childKey: number, OrgName: string) => void;
    OnAddressSelect: (parentKey: number, childName: string) => void;
    isLoading: boolean;
    parentOrChild: boolean;
}

export const HomeIndustriesDataGridPro: React.FC<HomeIndustriesDataGridProProps> = (
    props
) => {
    const COLUMNS = [
        {
            field: "parentName",
            headerName: "Parent Name",
            type: "string",
            flex: 1,
        }, 
        {
            field: "parentKey",
            headerName: "Parent Key",
            type: "string",
            width: 100,
        },
        {
            field: "childName",
            headerName: "Child Name",
            type: "string",
            flex: 1,
        }, 
        {
            field: "childKey",
            headerName: "Child Key",
            type: "string",
            width: 100,
        },
        {
            field: "sectorName",
            headerName: "Sector",
            type: "string",
            width: 150,
        },
        {
            field: "orgSize",
            headerName: "Org Size",
            type: "string",
            width: 150,
        },
        {
            field: "source",
            headerName: "Source",
            type: "string",
            width: 150,
        },
        {
            field: "ceAccountId",
            headerName: "CE_AccountID",
            type: "string",
            width: 150,
        }, 
        {
            field: "entOmKey",
            headerName: "Ent_OM_Key",
            type: "string",
            width: 80,
        },
        {
            field: "foCustomerId",
            headerName: "FO_CustomerID",
            type: "string",
            width: 120,
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

    const[open, setOpen] = React.useState<boolean>(false);

    const handleSelection = (ids: GridSelectionModel) => {
        if (!ids || !props.homeIdustriesList) {
            return;
        }
        const selectedRowsData = ids.map((id) => props.homeIdustriesList?.find((row) => row.childKey=== id || row.parentKey === id));
        setSelectedRows(selectedRowsData);
    };

    const handleSelect = (id: string) => {
        if (!selectedRows || selectedRows.length !== 1) {
            return;
        }
        switch(id) {
            case ("select-industry"):
                props.OnSelectDetails(selectedRows[0].parentKey, selectedRows[0].childKey);
                break;
            case ("view-address"):
                props.OnAddressSelect(selectedRows[0].childKey, selectedRows[0].childName);
                break;
            case("edit"):
                setOpen(true);
                break;
            default:
                break;
        }
        props.OnSelectDetails(selectedRows[0].parentKey, selectedRows[0].childKey);
    }

    return (
        <>
            <HomeIndustriesEditDialog 
                open={open} 
                onOk={() => setOpen(false)} 
                onCancel={() => setOpen(false)} 
                data={selectedRows[0]}
                OnSectorChange={props.OnSectorChange} 
                OnOrgChange={props.OnOrgChange} 
                orgSizeList={props.orgSizeList} 
                sectorList={props.sectorList} 
                Isloading={false}      
            />
            <DataGridPro
                getRowId={(row) => row.parentKey}
                rows={props.homeIdustriesList? props.homeIdustriesList?? [] : [] }
                columns={COLUMNS}
                loading={props.isLoading}
                components={{ Toolbar: CclDataGridToolbar }}
                componentsProps={{
                    toolbar: {
                        showSaveState: true,
                        savestate: null,
                        clearstate: null,
                        miscButtons: 
                            [
                                {
                                    id: "select-industry",
                                    caption: `Select ${props.parentOrChild ? "Parent" : "Child"}`,
                                    label: `Select ${props.parentOrChild ? "Parent" : "Child"}`,
                                    returnSelected: false,
                                    restricted: false,
                                    disable: selectedRows.length !== 1,
                                    onClick: () => {
                                        handleSelect("select-industry");
                                    }
                                },
                                {
                                    id: "view-address",
                                    caption: "View Address",
                                    label: "View Address",
                                    returnSelected: false,
                                    restricted: false,
                                    disable: selectedRows.length !== 1,
                                    onClick: () => {
                                        handleSelect("view-address");
                                    }
                                },
                                {
                                    id: "edit",
                                    caption: "Edit",
                                    label: "Edit",
                                    returnSelected: false,
                                    restricted: false,
                                    disable: selectedRows.length !== 1,
                                    onClick: () => {
                                        handleSelect("edit");
                                    }
                                }
                            ],
                            btnClick: ((id: string) => handleSelect(id)),
                            hideSettingsButtons: false,
                            anySelected: null,
                        }
                }}
                disableMultipleSelection
                checkboxSelection
                disableSelectionOnClick
                autoHeight
                initialState={{
                    sorting: {
                        sortModel: [{ field: "parentName", sort: "asc" }],
                    },
                }}
                selectionModel={selectionModel}
                onSelectionModelChange={(id: GridSelectionModel) => {
                    if (id.length > 1) {
                        const selectionSet = new Set(selectionModel);
                        const result = id.filter((s) => !selectionSet.has(s));
                        handleSelection(result);
                        setSelectionModel(result);
                    } else {
                        handleSelection(id);
                        setSelectionModel(id);
                    }
                }}
            />       
        </>
    );
};

export default HomeIndustriesDataGridPro;
