import * as React from "react";
import {
    DataGridPro,
} from "@mui/x-data-grid-pro";
import { AccountCEResponse } from "../../../../services/types/clientTypes";

export interface CESystemUserSubDataGridProProps {
    accountCELists: AccountCEResponse[] | undefined;
    isLoading: boolean;
}

export const CESystemUserSubDataGridPro: React.FC<CESystemUserSubDataGridProProps> = (
    props
) => {
    const COLUMNS = [
       {
            field: "ccL_Departmentname",
            headerName: "Department Name",
            type: "string",
            flex: 1,
        }, 
        {
            field: "businessUnitIdName",
            headerName: "Business Unit Name",
            type: "string",
            flex: 1,
        },
        {
            field: "firstName",
            headerName: "First Name",
            type: "string",
            flex: 1,
        }, 
        {
            field: "lastName",
            headerName: "Last Name",
            type: "string",
            flex: 1,
        },
        {
            field: "internalEmailAddress",
            headerName: "Email Address",
            type: "string",
            flex: 1,
        },
    ];
    return (
        <DataGridPro
            getRowId={(row) => row.mapperID}
            rows={props.accountCELists == null ? [] : props.accountCELists}
            columns={COLUMNS}
            loading={props.isLoading}
            hideFooter
            hideFooterPagination
            hideFooterRowCount
            disableMultipleSelection
            disableSelectionOnClick
            autoHeight
            initialState={{
                sorting: {
                    sortModel: [{ field: "mapperID", sort: "asc" }],
                },
            }}
        />       
    );
};

export default CESystemUserSubDataGridPro;
