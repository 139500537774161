import * as React from "react";
import { Alert, AlertColor, Grid, Stack } from "@mui/material";
import ComponentLoader from "../../../../components/common/componentLoader";
import ApplicationAccountsDataGridPro from "../../../../components/shared/applicationAccountsDataGridPro/applicationAccountsDataGridPro";
import InvitationsCardContent from "../../../../components/shared/invitationCardContent/invitationsCardContent";
import {
    useDisableAccessAppllicationMutation,
    useEnableAccessAppllicationMutation,
    useGetCclGCUserAccountDetailQuery,
    useGetCclUserAccountDetailQuery,
    useGetCclUserApplicationAccountsQuery,
    useLazyGetGroupsByTenantIdQuery,
    useUpdateUserAccountMutation,
    useDeleteApplicationUserMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import {
    AccessEventApplicationDetails,
    AccessEventIndividualDetails,
} from "../../../../services/types/accessEventTypes";
import {
    ApplicationAccount,
    UpdateUserAccountRequest,
    UpdateUserAccountRequestPayload,
} from "../../../../services/types/rtkQueryTypes";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import UserDetailsCard from "../general/userDetailsCard";
import CclInfoTable, {
    CclInfoTableRowTemplate,
} from "../../../../components/common/cclInfoTable/cclInfoTable";
import { flattenObject } from "../../../../utilities/flattenObject";
import { Profile } from "../../../../services/types/enterpriseCoachingApiTypes";
import { CclInfoTblResourceInfoTemplate } from "../../../resources/resourceDetail/info/cclInfoTblResourceInfoTemplate";
import { useGetResourceByEmailQuery } from "../../../../services/cclTokenedResourceApi";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import UserEditApplicationDrawer from "./userEditApplicationDrawer";
import SendInvitationDrawer from "./sendInvitationDrawer";

interface UserApplicationsPanelProps {
    emailAddress: string;
    firstName: string;
    lastName: string;
    imKey: string;
    profile: Profile;
}

const UserApplicationsPanel: React.FC<UserApplicationsPanelProps> = (props) => {
    const claimsService = new UserClaimsService();
    const isUserSuperAdmin = claimsService.IsUserSuperAdmin();
    const [enableAccessAppllication, { isLoading: enableAllLoading }] =
        useEnableAccessAppllicationMutation();
    const [disableAccessAppllication, { isLoading: disableAllLoading }] =
        useDisableAccessAppllicationMutation();
    const [updateUserAccount, { isLoading: updateIsLoading }] = useUpdateUserAccountMutation();
    const [getGroupbyTenantID, { data: groupsData }] = useLazyGetGroupsByTenantIdQuery();
    const { logEvent } = useLogAccessEvent();
    const { data: accounts, isLoading } = useGetCclUserApplicationAccountsQuery(
        props.emailAddress,
        {
            skip: props.emailAddress === "",
        }
    );
    const { data: enterpriseData } = useGetCclUserAccountDetailQuery(props.emailAddress, {
        skip: props.emailAddress == null || props.emailAddress === "",
    });
    const { data: grandCentralData, isLoading: gcLoading } = useGetCclGCUserAccountDetailQuery(
        props.emailAddress,
        {
            skip: props.emailAddress == null || props.emailAddress === "",
        }
    );
    const [deleteUser, { isLoading: deleteInProgress }] = useDeleteApplicationUserMutation();

    const [accountUserApp, setAccountUserApp] = React.useState<ApplicationAccount[]>();
    const [enableButton, setEnableButton] = React.useState<boolean>();
    const [disableButton, setDisableButton] = React.useState<boolean>();
    const [showEditDrawer, setshowEditDrawer] = React.useState<boolean>(false);
    const [userAccount, setUserAccount] = React.useState<ApplicationAccount | null>(null);
    const [accountId, setAccountId] = React.useState<string>("");
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [sendInvitationOpen, setSendInvitationOpen] = React.useState<boolean>(false);
    const [modTemplate, setModTemplate] = React.useState<CclInfoTableRowTemplate[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<ApplicationAccount>();
    const { data: resource } = useGetResourceByEmailQuery(props.emailAddress, {
        skip: props.emailAddress == null || props.emailAddress === "",
    });

    React.useEffect(() => {
        if (accounts) {
            setAccountUserApp(accounts);
        }
    }, [accounts]);

    React.useEffect(() => {
        if (!props.profile || !props.profile.email) return;
        const enterpriseDetail = enterpriseData?.find(
            (e) => e.email.toLowerCase() === props.profile.email.toLowerCase()
        );

        var newTemplate = CclInfoTblResourceInfoTemplate;
        var rowitem = newTemplate.find((r) => r.id === "ceContactId");
        if (typeof rowitem !== "undefined" && rowitem !== null) {
            rowitem.valueField.value = enterpriseDetail?.ceContactId ?? "";
        }

        rowitem = newTemplate.find((r) => r.id === "bookableresourceid");
        if (typeof rowitem !== "undefined" && rowitem !== null && resource) {
            rowitem.valueField.value =
                resource[0]?.bookableresourceid ?? "This user has no bookable resource id";
        }

        setModTemplate([...newTemplate]);
    }, [props.profile, enterpriseData, resource]);

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    React.useEffect(() => {
        if (accountUserApp) {
            if (accountUserApp.filter((a) => a.enabled === true).length > 0) {
                setDisableButton(true);
            } else {
                setDisableButton(false);
            }
            if (accountUserApp.filter((a) => a.enabled === false).length > 0) {
                setEnableButton(true);
            } else {
                setEnableButton(false);
            }
        }
    }, [accountUserApp]);

    const openEditApplicationDrawer = () => {
        if (!selectedRow) return;
        var tentantId = selectedRow.applicationId;
        getGroupbyTenantID(tentantId).unwrap();
        var acc = accountUserApp?.filter((a) => a.applicationId === tentantId);
        if (acc && acc?.length > 0) {
            setUserAccount(acc[0]);
            setAccountId(acc[0].accountId);
            setshowEditDrawer(true);
        }
    };

    const updateUserApplication = (
        enabled: boolean,
        newGroups: string[] | undefined,
        expirationDate: Date | null
    ) => {
        if (userAccount === null) return;
        const addGroups: string[] =
            newGroups?.filter((g) => !userAccount.groups?.includes(g)) ?? [];
        const addGroupIds: string[] =
            addGroups.length === 0
                ? []
                : groupsData
                      ?.filter((g) => addGroups.some((ag) => ag === g.name))
                      .map((g) => g.groupId) ?? [];
        const removeGroups: string[] =
            userAccount.groups?.filter((g) => !newGroups?.includes(g)) ?? [];
        const removeGroupIds: string[] =
            removeGroups.length === 0
                ? []
                : groupsData
                      ?.filter((g) => removeGroups.some((ag) => ag === g.name))
                      .map((g) => g.groupId) ?? [];

        let payload: UpdateUserAccountRequestPayload = {
            accountId: accountId,
            enabled: enabled,
            expirationDate: expirationDate,
            addToGroups: addGroupIds,
            removeFromGroups: removeGroupIds,
        };

        let request: UpdateUserAccountRequest = {
            userId: userAccount.userId,
            payload: payload,
        };

        updateUserAccount(request)
            .unwrap()
            .then(() => {
                const evtData: AccessEventApplicationDetails = {
                    applicationId: userAccount.applicationId,
                    applicationName: userAccount.application,
                    imKey: +props.imKey,
                    email: props.emailAddress,
                };

                logEvent("UserApplicationAccountEdited", evtData);

                const accountDataEnable = accountUserApp?.map((a) => {
                    if (a.userId !== userAccount.userId) {
                        return a;
                    } else {
                        return {
                            ...a,
                            enabled: enabled,
                            groups: newGroups !== undefined ? newGroups : [],
                            expiration: expirationDate,
                        };
                    }
                });
                setAccountUserApp(accountDataEnable);
                ShowSnackbarElement("Account Updated", "success");
                setshowEditDrawer(false);
            })
            .catch(() => {
                ShowSnackbarElement("Account Update Failed", "error");
                setshowEditDrawer(false);
            });
    };

    const enableUserAccess = () => {
        if (accounts) {
            var userId: string[] = [];
            accounts.forEach((usr) => {
                userId.unshift(usr.userId);
            });
            enableAccessAppllication(userId)
                .then(() => {
                    const evtData: AccessEventIndividualDetails = {
                        imKey: props.imKey,
                        email: props.emailAddress,
                    };

                    logEvent("UserApplicationAccessEnabled", evtData);
                    ShowSnackbarElement("Accounts Updated", "success");
                })
                .catch(() => {
                    ShowSnackbarElement("Accounts Update Failed", "error");
                });
            const accountDataEnable = accountUserApp?.map((a) => {
                if (a.enabled === true) {
                    return a;
                } else {
                    return { ...a, enabled: true };
                }
            });
            setAccountUserApp(accountDataEnable);
        }
    };

    const disableUserAccess = () => {
        if (accounts) {
            var userId: string[] = [];
            accounts.forEach((usr) => {
                userId.unshift(usr.userId);
            });
            disableAccessAppllication(userId)
                .then(() => {
                    const evtData: AccessEventIndividualDetails = {
                        imKey: props.imKey,
                        email: props.emailAddress,
                    };

                    logEvent("UserApplicationAccessDisabled", evtData);
                    ShowSnackbarElement("Accounts Updated", "success");
                })
                .catch(() => {
                    ShowSnackbarElement("Accounts Update Failed", "error");
                });

            const accountDataEnable = accountUserApp?.map((a) => {
                if (a.enabled === false) {
                    return a;
                } else {
                    return { ...a, enabled: false };
                }
            });
            setAccountUserApp(accountDataEnable);
        }
    };

    const deleteApplicationUser = (user: ApplicationAccount) => {
        if (isUserSuperAdmin) {
            deleteUser(user.userId)
                .unwrap()
                .then((rsp) => {
                    const evtData: AccessEventApplicationDetails = {
                        applicationId: user.applicationId,
                        applicationName: user.application,
                        imKey: +props.imKey,
                        email: props.emailAddress,
                    };

                    logEvent("UserApplicationAccountDeleted", evtData);
                    ShowSnackbarElement("Account Deleted", "success");
                    setAccountUserApp(accountUserApp?.filter((a) => a.userId !== user.userId));
                })
                .catch((err) => {
                    ShowSnackbarElement("Delete Application Account Failed", "error");
                });
        } else {
            ShowSnackbarElement("Delete Application Account Failed", "error");
        }
    };

    const closeSendInvitationDialog = (result: boolean | null) => {
        if (result === true) {
            ShowSnackbarElement("Send Invitation Completed", "success");
        } else if (result === false) {
            ShowSnackbarElement("Send Invitation Failed", "error");
        }
        setSendInvitationOpen(false);
    };

    const handleSave = () => {
        return;
    };

    const handleChange = () => {
        return;
    };

    const handleSelectionChange = (selection: (ApplicationAccount | undefined)[]) => {
        setSelectedRow(selection[0]);
        return;
    };

    const startSendInvitationFlow = () => {
        setSendInvitationOpen(true);
    };

    if (isLoading) {
        return <ComponentLoader msg={"Loading User Application Accounts..."} />;
    }

    return (
        <Stack height={1} width={1}>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            {groupsData !== undefined && userAccount != null ? (
                <UserEditApplicationDrawer
                    emailAddress={props.emailAddress}
                    userAccount={userAccount}
                    open={showEditDrawer}
                    onOk={updateUserApplication}
                    onCancel={() => setshowEditDrawer(false)}
                    tenantGroups={
                        [...groupsData!]?.sort((a, b) => {
                            return a.name > b.name ? 1 : -1;
                        })!
                    }
                    updating={updateIsLoading}
                />
            ) : null}
            <Grid container spacing={2}>
                {enterpriseData != null && !gcLoading && (
                    <Grid item xs={6}>
                        <UserDetailsCard
                            gcDetail={grandCentralData ?? null}
                            enterpriseDetails={enterpriseData ?? null}
                            gcAccountEmail={props.emailAddress}
                            imKey={+props.imKey}
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <CclGenericAccordian headingText={"D365"}>
                        {!props.profile.email && (
                            <Alert severity="info">
                                No D365 Bookable Resource Record exists for this user{" "}
                            </Alert>
                        )}
                        {props.profile.email && (
                            <CclInfoTable
                                template={modTemplate}
                                data={flattenObject(props.profile)}
                                onSave={handleSave}
                                onChange={handleChange}
                            />
                        )}
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12}>
                    <CclGenericAccordian headingText="Applications">
                        <ApplicationAccountsDataGridPro
                            isSuperAdmin={isUserSuperAdmin}
                            onEditClick={openEditApplicationDrawer}
                            accounts={accountUserApp}
                            enableButton={enableButton}
                            disableButton={disableButton}
                            enableAll={enableUserAccess}
                            disableAll={disableUserAccess}
                            deleteUser={deleteApplicationUser}
                            updateSelected={handleSelectionChange}
                            disableInProgress={disableAllLoading}
                            enableInProgress={enableAllLoading}
                            deleteInProgress={deleteInProgress}
                        />
                    </CclGenericAccordian>
                </Grid>
                <Grid item xs={12}>
                    <SendInvitationDrawer
                        firstName={props.firstName}
                        lastName={props.lastName}
                        email={props.emailAddress}
                        open={sendInvitationOpen}
                        onClose={(result) => closeSendInvitationDialog(result)}
                        imKey={props.imKey}
                    />
                    <CclGenericAccordian headingText="Invitations">
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            width={1}
                        ></Stack>
                        <InvitationsCardContent
                            showInvitationId={true}
                            email={props.emailAddress}
                            sendInvitation={() => startSendInvitationFlow()}
                        />
                    </CclGenericAccordian>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default UserApplicationsPanel;
