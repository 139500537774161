import * as React from "react";
import {
    DataGridPro,
    GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { AccountCEResponse } from "../../../../services/types/clientTypes";


export interface AccountManagerSubDataGridProProps {
    accountCELists: AccountCEResponse[] | undefined;
    isLoading: boolean;
}

export const AccountManagerSubDataGridPro: React.FC<AccountManagerSubDataGridProProps> = (
    props
) => { 
    const COLUMNS = [
        {
            field: "fiscalYear",
            headerName: "Fiscal Year",
            type: "string",
            width: 100,      
        },  
        {
            field: "parentKey",
            headerName: "Parent Key",
            type: "string",
            width: 100,
        },
        {
            field: "parentName",
            headerName: "Client Name",
            type: "string",
            width: 100,
        }, 
        {
            field: "accountOwnerName",
            headerName: "Account Owner Name",
            type: "string",
            width: 150,
        },
        {
            field: "newClientIndicator",
            headerName: "New Client Indicator",
            type: "number",
            width: 150,
        },
        {
            field: "effectiveStartDate",
            headerName: "Effective Start Date",
            valueGetter: (params: any) => new Date(params.row.effectiveStartDate),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 150,
            editable: true,
        },
        {
            field: "effectiveEndDate",
            headerName: "Effective End Date",
            valueGetter: (params: any) => new Date(params.row.effectiveEndDate),
            type: "date",
            renderCell: (params: GridRenderCellParams) => (
                <span>{params.value.toLocaleDateString()}</span>
            ),
            width: 150,
            editable: true,
        },        
    ];
    return (        
        <DataGridPro
            getRowId={(row) => row.mapperID}
            rows={props.accountCELists == null ? [] : props.accountCELists}
            columns={COLUMNS}
            loading={props.isLoading}
            hideFooter
            hideFooterPagination
            hideFooterRowCount
            disableMultipleSelection
            disableSelectionOnClick
            autoHeight
            initialState={{
                sorting: {
                    sortModel: [{ field: "mapperID", sort: "asc" }],
                },
            }}
        />       
    );
};

export default AccountManagerSubDataGridPro;
