import * as React from "react";
import { Card, CardHeader, CardContent, Stack, Alert, AlertColor } from "@mui/material";
import { GcSystem } from "../../../../services/types/cclGrandCentralApiTypes";
import SystemTenantDetail from "./systemTenantDetail";
import AddTenantDrawer from "./addTenantDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";

export type SystemTenantsProps = {
    system: GcSystem;
    readonly: boolean;
};

export const SystemTenants: React.FC<SystemTenantsProps> = (props) => {
    const [showAddTenant, setShowAddTenant] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const onDrawerClose = (snackbarMsg?: string, snackBarSeverity?: AlertColor) => {
        setShowAddTenant(false);
        if (snackBarSeverity && snackbarMsg) {
            setSnackbarSeverity(snackBarSeverity);
            setSnackbarMessage(snackbarMsg);
            setShowSnackbar(true);
        }
    };

    return (
        <>
            {showAddTenant ? (
                <AddTenantDrawer
                    open={true}
                    onClose={(msg, severity) => onDrawerClose(msg, severity)}
                    systemId={props.system.systemId}
                    systemName={props.system.systemName}
                />
            ) : null}
            <Card variant="outlined" sx={{ width: 1, height: 1 }}>
                <CclAlertSnackbar
                    open={showSnackbar}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
                <CardHeader
                    title={"Tenants"}
                    action={
                        props.readonly ? null : (
                            <CclButton onClick={() => setShowAddTenant(true)} mode={"primary"}>
                                Add Tenant
                            </CclButton>
                        )
                    }
                />
                <CardContent>
                    <Stack spacing={1}>
                        {props.system.tenants && props.system.tenants.length > 0 ? (
                            props.system.tenants.map((tnt, idx) => (
                                <SystemTenantDetail
                                    systemId={props.system.systemId}
                                    systemName={props.system.systemName}
                                    key={tnt.tenantId}
                                    tenant={tnt}
                                    readonly={props.readonly}
                                    expanded={idx === 0}
                                />
                            ))
                        ) : (
                            <Alert severity="warning">No Tenants Found</Alert>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
};

export default SystemTenants;
