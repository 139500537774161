import * as React from "react";
import { Alert, AlertColor, Portal, Snackbar } from "@mui/material";

export type CclAlertSnackbarProps = {
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
    onClose: () => void;
};

const CclAlertSnackbar: React.FC<CclAlertSnackbarProps> = (props) => {
    return (
        <Portal>
            <Snackbar
                open={props.open}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
                autoHideDuration={5000}
                onClose={props.onClose}
            >
                <Alert
                    severity={props.severity ?? "info"}
                    sx={{ width: "100%" }}
                    onClose={props.onClose}
                >
                    {props.message}
                </Alert>
            </Snackbar>
        </Portal>
    );
};

export default CclAlertSnackbar;
