import * as React from "react";
import { AlertColor, Card, Stack, Typography } from "@mui/material";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import ApplicationsDataGridPro from "./applicationsList";
import {
    useGetCclAllApplicationsQuery,
    useSendInvitationsMutation,
} from "../../services/cclTokenedGrandCentralApi";
import {
    IndividualInvitationResponseStatus,
    SendInvitationsRequest,
} from "../../services/types/rtkQueryTypes";
import { CSVLink } from "react-csv";
import Link from "react-csv/components/Link";
import { AccessEventApplicationDetails } from "../../services/types/accessEventTypes";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import AddSystemDrawer from "./addSystemDrawer";
import CclAlertSnackbar from "../../components/common/cclAlertSnackbar";
import SendBatchInvitationsDrawer from "./sendBatchInvitations/sendBatchInvitationsDrawer";

export const Applications: React.FC = () => {
    const csvLink = React.useRef<HTMLAnchorElement & Link & { link: HTMLAnchorElement }>(null);

    const [addSystemDialogOpen, setAddSystemDialogOpen] = React.useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [sendInvitationsOpen, setSendInvitationsOpen] = React.useState<boolean>(false);
    const [sendInvitationRequest] = useSendInvitationsMutation();
    const { data, isLoading } = useGetCclAllApplicationsQuery();
    const [sendResults, setSendResults] = React.useState<IndividualInvitationResponseStatus[]>([]);
    const { logEvent } = useLogAccessEvent();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Applications
            </Typography>
        </SimpleBreadcrumbs>
    );

    const openSendInvitationsPopup = () => {
        setSendInvitationsOpen(true);
    };

    const closeSendInvitationPopup = (request: SendInvitationsRequest | null) => {
        if (request === null) {
            setSendInvitationsOpen(false);
            return;
        }

        sendInvitationRequest(request)
            .unwrap()
            .then((response) => {
                if (response == null) {
                    ShowSnackbarElement("Send Invitations Failed", "error");
                    setSendInvitationsOpen(false);
                    return;
                }

                if (response.numberFailed === 0) {
                    const app = data?.find((a) => a.applicationId === request.applicationId);
                    const evtData: AccessEventApplicationDetails = {
                        applicationId: request.applicationId,
                        applicationName: app?.name ?? "",
                    };
                    logEvent("BulkUserApplicationInvitationSent", evtData);

                    ShowSnackbarElement("Send Invitations Completed", "success");
                } else {
                    ShowSnackbarElement("Send Invitations Failed", "error");
                }
                setSendResults(response.results);
                setTimeout(() => {
                    csvLink.current?.link.click();
                });
                setSendInvitationsOpen(false);
            })
            .catch(() => {
                ShowSnackbarElement("Send Invitations Failed", "error");
                setSendInvitationsOpen(false);
            });
    };

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <React.Fragment>
                <CSVLink
                    data={sendResults}
                    filename="batch-invitation-results.csv"
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                />
                {showSnackbar ? (
                    <CclAlertSnackbar
                        open={true}
                        onClose={() => setShowSnackbar(false)}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                ) : null}
                {addSystemDialogOpen ? (
                    <AddSystemDrawer
                        open={addSystemDialogOpen}
                        onClose={() => setAddSystemDialogOpen(false)}
                        showSnackbar={ShowSnackbarElement}
                    />
                ) : null}
                {sendInvitationsOpen ? (
                    <SendBatchInvitationsDrawer
                        open={true}
                        onClose={closeSendInvitationPopup}
                        applications={data ?? []}
                    />
                ) : null}
                <Stack height={1} width={1}>
                    <Card
                        sx={{
                            width: 1,
                            height: 1,
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ApplicationsDataGridPro
                            loading={isLoading}
                            applications={data ? data : []}
                            onSendInvitationClick={openSendInvitationsPopup}
                            onAddSystemClick={() => setAddSystemDialogOpen(true)}
                        />
                    </Card>
                </Stack>
            </React.Fragment>
        </StandardLayout>
    );
};

export default Applications;
