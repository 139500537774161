import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

// Define a type for the slice state
export interface ClientSearchListConfigState {
  FiscalYear: number,
  AccountName: string,
  WildSearch: boolean,
};

// Define the initial state using that type
const initialState: ClientSearchListConfigState = {
  FiscalYear: 0,
  AccountName: '',
  WildSearch: true
};

export const clientSearchListConfigSlice = createSlice({
  name: 'clientSearchListConfig',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateClientSearchParams: (state, action: PayloadAction<ClientSearchListConfigState>) => {
      state.FiscalYear = action.payload.FiscalYear;
      state.AccountName = action.payload.AccountName;
      state.WildSearch = action.payload.WildSearch;
    }
  }
});

export const { updateClientSearchParams } = clientSearchListConfigSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectClientSearchListConfig = (state: RootState) => state;
export default clientSearchListConfigSlice.reducer;
