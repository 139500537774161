import * as React from "react";
import { DataGridPro, GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclCommonLink from "../../../../components/common/cclCommonLink";
import { Participant } from "../../../../services/types/enterpriseParticipantApiTypes";
import { useGetParticipantsAssignedCoachesQuery } from "../../../../services/cclTokenedEnterpriseCoachingApi";

export interface ParticipantAssignedResourcesDataGridProps {
    registration: Participant | undefined;
    isNwaSession: boolean;
}

type dataGridResourceData = {
    id: string;
    pKey: number;
    resource: string;
    email: string;
    type: string;
};

export const ParticipantAssignedResourcesDataGrid: React.FC<
    ParticipantAssignedResourcesDataGridProps
> = (props) => {
    const [assignedResources, setAssignedResources] = React.useState<dataGridResourceData[]>([]);
    //const claimsService = new UserClaimsService();
    //const showLinks = claimsService.ShouldSeeAccessInternalLinks();
    const {
        data: resources,
        isSuccess,
        isLoading,
    } = useGetParticipantsAssignedCoachesQuery(props.registration?.esiKey.toString() ?? "", {
        skip: !props.registration,
    });

    React.useEffect(() => {
        if (isSuccess && resources && resources.coaches.length > 0) {
            const resourceInfo: dataGridResourceData[] = resources.coaches.map((r) => {
                const resourceType = props.isNwaSession
                    ? "Assigned Resource"
                    : r.isPrimary
                    ? "Primary Resource"
                    : "Other Assigned Resource";

                return {
                    id: props.isNwaSession ? `${r.imKey}` : `${r.pKey}-${r.isPrimary}`,
                    pKey: r.pKey,
                    resource: `${r.firstName} ${r.lastName}`,
                    email: r.email,
                    type: resourceType,
                };
            });
            setAssignedResources(resourceInfo);
        } else {
            setAssignedResources([]);
        }
    }, [isSuccess, props.isNwaSession, resources]);

    const COLUMNS = [
        {
            field: "resource",
            headerName: "Resource",
            renderCell: (params: GridRenderCellParams) =>
                props.isNwaSession ? (
                    params.value
                ) : (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/resource/${params.row.pKey}/0`}
                    />
                ),
            type: "string",
            flex: 3,
        },
        {
            field: "email",
            headerName: "Email",
            type: "string",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            flex: 4,
        },
        {
            field: "type",
            headerName: "Resource Type",
            type: "string",
            flex: 3,
        },
    ];

    return (
        <DataGridPro
            getRowId={(row) => row.id}
            rows={assignedResources}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            loading={isLoading}
            initialState={{ sorting: { sortModel: [{ field: "resource", sort: "asc" }] } }}
            autoHeight
        />
    );
};

export default ParticipantAssignedResourcesDataGrid;
