import * as React from "react";
import { Box, Stack, TextField } from "@mui/material";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../components/common/cclDrawer/cclDrawerActionBar";

export interface UpdateLoginApplicationCallbackDrawerProps {
    open: boolean;
    onClose: () => void;
    url: string;
    loginApplicationCallbackUrlId: string;
    onSave: (url: string, callbackUrlId: string) => void;
}

export const UpdateLoginApplicationCallbackDrawer: React.FC<
    UpdateLoginApplicationCallbackDrawerProps
> = (props) => {
    const [newURL, setNewUrl] = React.useState<string>(props.url ?? "");

    const onUpdateCallback = () => {
        if (newURL === "") return;

        props.onSave(newURL, props.loginApplicationCallbackUrlId);
        props.onClose();
    };

    var verbage = props.loginApplicationCallbackUrlId === "" ? "Add" : "Edit";
    return (
        <CclDrawer open={props.open} onClose={props.onClose} title={`${verbage} Callback URL`}>
            <Box component="form" noValidate autoComplete="off">
                <Stack direction="column" spacing={2}>
                    <TextField
                        required
                        id="edit-callback-url"
                        label="Callback URL"
                        variant="outlined"
                        value={newURL}
                        onChange={(e) => setNewUrl(e.target.value)}
                    />
                    <CclDrawerActionBar>
                        <CclButton
                            onClick={() => onUpdateCallback()}
                            mode={"primary"}
                            disabled={newURL === ""}
                        >
                            Save
                        </CclButton>
                        <CclButton onClick={props.onClose} mode={"secondary"}>
                            Cancel
                        </CclButton>
                    </CclDrawerActionBar>
                </Stack>
            </Box>
        </CclDrawer>
    );
};

export default UpdateLoginApplicationCallbackDrawer;
