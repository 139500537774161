import React from "react";
import { Breadcrumbs } from "@mui/material";

type SimpleBreadcrumbsProps = {
    children?: React.ReactElement | React.ReactElement[] | undefined;
};

export const SimpleBreadcrumbs: React.FC<SimpleBreadcrumbsProps> = ({ children }) => {
    return <Breadcrumbs aria-label="breadcrumb">{children}</Breadcrumbs>;
};
