import React, { useEffect } from "react";
import { Stack, TextField, Box, AlertColor } from "@mui/material";
import { SessionProgramManager } from "../../../../../services/types/sessionDetailTypes";
import { usePutProgramManagerSessionMutation } from "../../../../../services/cclTokenedSessionApi";
import { AccessEventSessionDetails } from "../../../../../services/types/accessEventTypes";
import useLogAccessEvent from "../../../../../hooks/useLogAccessEvent";
import CclDrawer from "../../../../../components/common/cclDrawer/cclDrawer";
import CclDrawerActionBar from "../../../../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../../../../components/common/cclButtons/cclButton";
import CclLoadingButton from "../../../../../components/common/cclButtons/cclLoadingButton";

interface AddEditProgramManagerDrawerProps {
    open: boolean;
    esKey: number;
    programCode: string;
    onCancel: () => void;
    managerToEdit: SessionProgramManager | null;
    showSnackbar: (message: string, severity: AlertColor) => void;
}

const AddEditProgramManagerDrawer: React.FC<AddEditProgramManagerDrawerProps> = (props) => {
    const [sessionManager, setSessionManager] = React.useState<SessionProgramManager>(
        props.managerToEdit ?? {
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            programId: props.esKey,
            individualId: 0,
            status: "Add",
        }
    );
    const [firstNameError, setFirstNameError] = React.useState(false);
    const [lastNameError, setLastNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);
    const [saveProgramManager, { isLoading: saving }] = usePutProgramManagerSessionMutation();
    const { logEvent } = useLogAccessEvent();

    const textInputSx = {
        "& .MuiOutlinedInput-root": {
            paddingTop: "0px!important",
            paddingBottom: "0px!important",
        },
        "& .MuiInputLabel-root": {
            marginTop: "-8px!important",
        },
        "& .MuiInputLabel-shrink": {
            marginTop: "auto!important",
        },
    };

    useEffect(() => {
        if (props.managerToEdit != null) {
            setSessionManager(props.managerToEdit);
        } else {
            setSessionManager({
                email: "",
                firstName: "",
                lastName: "",
                phone: "",
                programId: props.esKey,
                individualId: 0,
                status: "Add",
            });
        }
    }, [props.esKey, props.managerToEdit]);

    const isProgramManagerValid = (): boolean => {
        setEmailError(sessionManager?.email === "");
        setFirstNameError(sessionManager?.firstName === "");
        setLastNameError(sessionManager?.lastName === "");
        setPhoneError(sessionManager?.phone === "");

        return areAllFieldsFilled();
    };

    const areAllFieldsFilled = (): boolean => {
        return (
            sessionManager?.email !== "" &&
            sessionManager?.firstName !== "" &&
            sessionManager?.lastName !== "" &&
            sessionManager?.phone !== ""
        );
    };

    const evtData: AccessEventSessionDetails = {
        sessionID: props.esKey.toString(),
        projectCode: props.programCode ?? "",
    };

    const updateProgramManager = () => {
        if (props.managerToEdit == null) {
            props.showSnackbar("Client Administrator Addition Failed", "error");
            return;
        }
        if (!isProgramManagerValid()) {
            props.showSnackbar("Client Administrator Addition Failed", "error");
            return;
        }
        var payload: SessionProgramManager = { ...props.managerToEdit };
        payload.programId = props.esKey;
        payload.status = "Remove";
        saveProgramManager(payload)
            .unwrap()
            .then(() => {
                logEvent("SessionSetupSaved", evtData);
                // don't display success here, will do that in add routine below
                addProgramManager();
            })
            .catch(() => {
                props.showSnackbar("Client Administrator Addition Failed", "error");
            });
    };

    const addProgramManager = () => {
        if (!isProgramManagerValid()) {
            props.showSnackbar("Client Administrator Addition Failed", "error");
            return;
        }

        var payload = { ...sessionManager };
        payload.programId = props.esKey;
        payload.status = "Add";
        saveProgramManager(payload)
            .unwrap()
            .then(() => {
                logEvent("SessionSetupSaved", evtData);
                props.showSnackbar("Client Administrator Added", "success");
                props.onCancel();
            })
            .catch(() => {
                props.showSnackbar("Client Administrator Addition Failed", "error");
            });
    };

    return (
        <CclDrawer
            title={props.managerToEdit ? "Update Client Admin" : "Add Client Admin"}
            open={props.open}
            onClose={props.onCancel}
        >
            <Box sx={{ paddingTop: "5px !important" }}>
                <Stack spacing={2}>
                    <TextField
                        size="small"
                        error={emailError}
                        helperText={emailError ? "Enter an Email Address" : ""}
                        onChange={(e: any) => {
                            e.preventDefault();
                            let updateSessionManager = { ...sessionManager };
                            updateSessionManager.email = e.target.value;
                            setSessionManager(updateSessionManager);
                        }}
                        value={sessionManager?.email}
                        id="txtEmail"
                        label="Email"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={textInputSx}
                    />
                    <TextField
                        size="small"
                        error={firstNameError}
                        helperText={firstNameError ? "Enter a First Name" : ""}
                        onChange={(e: any) => {
                            e.preventDefault();
                            let updateSessionManager = { ...sessionManager };
                            updateSessionManager.firstName = e.target.value;
                            setSessionManager(updateSessionManager);
                        }}
                        value={sessionManager?.firstName}
                        id="txtFirstName"
                        label="First Name"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={textInputSx}
                    />
                    <TextField
                        size="small"
                        error={lastNameError}
                        helperText={lastNameError ? "Enter a Last Name" : ""}
                        onChange={(e: any) => {
                            e.preventDefault();
                            let updateSessionManager = { ...sessionManager };
                            updateSessionManager.lastName = e.target.value;
                            setSessionManager(updateSessionManager);
                        }}
                        value={sessionManager?.lastName}
                        id="txtLastName"
                        label="Last Name"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={textInputSx}
                    />
                    <TextField
                        size="small"
                        error={phoneError}
                        helperText={phoneError ? "Enter a Phone Number" : ""}
                        onChange={(e: any) => {
                            e.preventDefault();
                            let updateSessionManager = { ...sessionManager };
                            updateSessionManager.phone = e.target.value;
                            setSessionManager(updateSessionManager);
                        }}
                        value={sessionManager?.phone}
                        id="txtPhone"
                        label="Phone"
                        variant="outlined"
                        fullWidth={true}
                        inputProps={textInputSx}
                    />
                </Stack>
            </Box>
            <CclDrawerActionBar>
                <CclLoadingButton
                    onClick={() =>
                        props.managerToEdit ? updateProgramManager() : addProgramManager()
                    }
                    mode={"primary"}
                    loading={saving}
                    disabled={!areAllFieldsFilled()}
                >
                    Save
                </CclLoadingButton>
                <CclButton onClick={props.onCancel} mode={"secondary"}>
                    Cancel
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default AddEditProgramManagerDrawer;
