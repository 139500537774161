import * as React from "react";
import { Stack, Typography, Card, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SimpleBreadcrumbs } from "../../components/navigation/simpleBreadcrumbs";
import StandardLayout from "../../layouts/standard";
import {
    useGetAccessEventsByDateMutation,
    useLazyGetAccessEventsByEmailQuery,
} from "../../services/cclTokenedAccessEventsApi";
import { AccessEvent } from "../../services/types/accessEventTypes";
import AccessLogsDataGridPro from "./accessLogsDataGridPro";
import CclSearchDefault from "../../components/common/cclSearchDefault";
import CclTextOrDateSearchBar from "../../components/common/cclLandingPageSearchBars/cclTextOrDateSearchBar";
import {
    AccessEventListConfigState,
    updateAccessEventSearchParams,
} from "../../app/slices/accessEventListConfigSlice";

const AccessLogs: React.FC = (props) => {
    // redux state
    const dispatch = useDispatch();
    const config = useSelector((state: any) => state.accessEventListConfig);
    const [localConfig, setLocalConfig] = React.useState<AccessEventListConfigState>({ ...config });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [actionEvents, setActionEvents] = React.useState<AccessEvent[] | undefined>(undefined);

    const [searchByDate] = useGetAccessEventsByDateMutation();
    const [searchByEmail] = useLazyGetAccessEventsByEmailQuery();

    const getLogsByDate = async (startDate: Date | null, endDate: Date | null) => {
        if (startDate == null || endDate == null) {
            return;
        }
        const newLocalConfig = {
            StartDate: startDate.toISOString(),
            EndDate: endDate.toISOString(),
            Email: "",
            IsEmailSearchActive: false,
        };
        setLocalConfig(newLocalConfig);
        setIsLoading(true);
        searchByDate({
            keyword: null,
            start: startDate.toISOString(),
            end: endDate.toISOString(),
        })
            .unwrap()
            .then((events: AccessEvent[]) => {
                setIsLoading(false);
                setActionEvents(events);
            })
            .catch((error) => {
                setIsLoading(false);
                setActionEvents(undefined);
            });
        dispatch(updateAccessEventSearchParams(newLocalConfig));
    };

    const getLogsByEmail = async (email: string) => {
        if (email == null || email.trim() === "") {
            return;
        }
        const newLocalConfig = {
            StartDate: "",
            EndDate: "",
            Email: email.trim(),
            IsEmailSearchActive: true,
        };
        setLocalConfig(newLocalConfig);
        setIsLoading(true);
        searchByEmail(email.trim())
            .unwrap()
            .then((events: AccessEvent[]) => {
                setIsLoading(false);
                setActionEvents(events);
            })
            .catch((error) => {
                setIsLoading(false);
                setActionEvents(undefined);
            });
        dispatch(updateAccessEventSearchParams(newLocalConfig));
    };

    const getLogs = async (email: string, startDate: Date | null, endDate: Date | null) => {
        if (email === "") {
            getLogsByDate(startDate, endDate);
        } else {
            getLogsByEmail(email);
        }
    };

    const breadcrumbs = (
        <SimpleBreadcrumbs>
            <Typography variant="h6" color="text.primary">
                Events
            </Typography>
        </SimpleBreadcrumbs>
    );

    return (
        <StandardLayout breadcrumbs={breadcrumbs}>
            <Stack height={1} width={1}>
                <Card
                    sx={{
                        width: 1,
                        height: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "row", width: 1 }}>
                        <Box sx={{ flex: "1", paddingRight: 1 }}>
                            <CclTextOrDateSearchBar
                                executeSearch={getLogs}
                                initialTextFieldSearchTerm={
                                    localConfig.IsEmailSearchActive ? localConfig.Email : ""
                                }
                                textFieldSearchLabel="Enter User Email"
                                initialStartDate={
                                    localConfig.IsEmailSearchActive || localConfig.StartDate === ""
                                        ? null
                                        : new Date(localConfig.StartDate)
                                }
                                initialEndDate={
                                    localConfig.IsEmailSearchActive || localConfig.EndDate === ""
                                        ? null
                                        : new Date(localConfig.EndDate)
                                }
                                startDateLabel="Earliest Event Date"
                                endDateLabel="Latest Event Date"
                                allowNullDates={false}
                                showResetButton={true}
                                clearSearchTrigger={undefined}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: 1,
                            height: 1,
                            marginTop: 2,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {actionEvents != null || isLoading ? (
                            <AccessLogsDataGridPro
                                loading={isLoading}
                                accessEvents={actionEvents ?? []}
                                showResultBar={false}
                                resultBarText={""}
                                showSaveState={true}
                            />
                        ) : (
                            <CclSearchDefault
                                line1="Start searching for Events"
                                line2="Enter User Email or enter a Date Range and click Search"
                            />
                        )}
                    </Box>
                </Card>
            </Stack>
        </StandardLayout>
    );
};

export default AccessLogs;
