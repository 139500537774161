import * as React from "react";
import { Box, Stack, Typography} from "@mui/material";
import AccountSearchList from "./accountSearchList";
import CESystemUserList from "./cESystemUserList";
import { AccountCEResponse, CopyFiscalExeSaveRequest } from "../../../../services/types/clientTypes";
import FlexGrid from "../../../../layouts/flexGrid";
import AccountManagerSubDataGridPro from "./accountManagerSubDataGridPro";
import CESystemUserSubDataGridPro from "./cESystemUserSubDataGridPro";
import CclButton from "../../../../components/common/cclButtons/cclButton";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import { useSaveAccountExecutiveMapperMutation } from "../../../../services/cclTokenedCustomerHierarchyApi";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";

export type AccountManagerPanelParams = {};

export const AccountManagerPanel: React.FC = (prop) => {
        // redux state
    const [searchAccountOrCETab, setsearchAccountOrCETab] = React.useState<boolean>(true);
    const [selectAccountExecData, setSelectAccountExecData] = React.useState<AccountCEResponse[]|undefined>();
    const [selectCESystemUserData, setSelectCESystemUserData] = React.useState<AccountCEResponse[]|undefined>();
    const [confirmSave, setConfirmSave] = React.useState<boolean>(false);

    const [handleSaveMapping, {isError}] = useSaveAccountExecutiveMapperMutation();

    const onCancel = () => {
        setSelectAccountExecData([]);
        setSelectCESystemUserData([]);
    };

    const handleSave = () => {
        if(selectAccountExecData && selectCESystemUserData){

            const data: CopyFiscalExeSaveRequest = {
                accExeCopy: 0,
                new_FY: 0,
                new_EStarteDate: selectCESystemUserData[0].effectiveStartDate,
                new_EEndDate: selectCESystemUserData[0].effectiveEndDate,
                parent_key: selectAccountExecData[0].parentKey,
                client_ID: selectCESystemUserData[0].mapperID,
                sys_ID: selectCESystemUserData[0].systemUserId,
                account_Name: ""
            }
            handleSaveMapping(data)
                .unwrap()
                .then(() => {
                    setConfirmSave(true);
                })
        }
    }

    return (        
            <Stack height={1} width={1}>
                <Stack  direction="row" justifyContent="center"  alignItems="center"  spacing={2}>
                    <CclButton 
                        onClick={(evt) => {
                            evt.stopPropagation(); setsearchAccountOrCETab(true);
                        } }
                        sx={{ m: 1, mr: 2, border: 1, borderRadius: 1, padding: "5px", }} 
                        mode={searchAccountOrCETab ? "primary" : "secondary"}
                    >
                         Account Executive Client Search
                    </CclButton>
                    <CclButton 
                        onClick={(evt) => {
                            evt.stopPropagation(); setsearchAccountOrCETab(false);
                        } }
                        sx={{ m: 1, mr: 2, border: 1, borderRadius: 1, padding: "5px", }} 
                        mode={searchAccountOrCETab ? "secondary" : "primary"}
                    >
                            Account Owner Search
                    </CclButton>
                </Stack>  
                <CclGenericAccordian sx={{mt: 2}} headingText={"Create Mappings"} defaultExpanded={false}>
                    <Box
                        sx={{
                            width: 1,
                            m: 2,
                            p: 3,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <CclAlertSnackbar 
                            open={confirmSave}
                            message={isError? "User mapping failed" : "User mapped to client"} 
                            severity={isError? "error" : "success"} 
                            onClose={() => setConfirmSave(false)}                        
                        />

                        <Typography variant="caption" display="block" gutterBottom>The following User.....
                        </Typography> 

                        <FlexGrid>
                            <CESystemUserSubDataGridPro
                                accountCELists={selectCESystemUserData??[]}
                                isLoading={false}/>
                            </FlexGrid>
                            <Typography variant="caption" display="block" gutterBottom>will now have the following Client
                            </Typography>                    
                        <FlexGrid>
                            <AccountManagerSubDataGridPro
                                accountCELists={selectAccountExecData ?? []}
                                isLoading={false}/>
                        </FlexGrid>

                        <Stack direction={"row"}>
                            <CclButton 
                                onClick={()=> onCancel()}
                                sx={{
                                    width: 0.1,
                                    m: 2,
                                    mt: 4,
                                    ml: "auto",
                                }}
                                mode={"primary"}> 
                                Cancel
                            </CclButton>
                            <CclButton 
                                onClick={(evt) => {
                                    evt.stopPropagation(); handleSave();
                                }}
                                sx={{
                                    width: 0.1,
                                    m: 2,
                                    mt: 4,
                                }}
                                disabled={!(selectAccountExecData && selectCESystemUserData)} 
                                mode={"primary"}                                        
                            >
                                Save Mapping(s)
                            </CclButton>
                        </Stack>
                    </Box>
                </CclGenericAccordian>
                <CclGenericAccordian headingText="Search Clients" sx={{mt: 4}}>
                    {searchAccountOrCETab && <AccountSearchList OnSelectedAccountExecData={setSelectAccountExecData} />}
                    {!searchAccountOrCETab && <CESystemUserList OnSelectedCESystemUserData={setSelectCESystemUserData}/>}
                 </CclGenericAccordian>
            </Stack> 
   );
                };

export default AccountManagerPanel;
