import * as React from "react";
import { addressList } from "../../../../services/types/clientTypes";
import FlexGrid from "../../../../layouts/flexGrid";
import HomeAddressDataGridPro from "./homeAddressDataGridPro";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export type ViewAddressDialogProps = {
    open: boolean;
    onOk: () => any;
    onCancel: () => any;
    Isloading: boolean;
    AddressList: addressList[];
    childName: string;
};

const ViewAddressDialog: React.FC<ViewAddressDialogProps> = (props) => {
    return (
        <CclDrawer 
            title={props.childName} 
            open={props.open} 
            onClose={()=>{props.onCancel()}}
        >
            <>
                <FlexGrid>
                    <HomeAddressDataGridPro
                        addressDataList={props.AddressList}
                        showToolbar={false}
                        isLoading={props.Isloading}
                    />
                </FlexGrid>
                <CclButton
                    color="primary"
                    id="cancel-btn"
                    onClick={() => props.onCancel()} 
                    mode={"primary"}            
                >
                    Cancel
                </CclButton>
            </>
        </CclDrawer>
    );
};

export default ViewAddressDialog;
