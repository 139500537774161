import React from "react";
import JournalEntryDrawer from "../../../components/common/jouralEntryDrawer/journalEntryDrawer";
import CclAlertDialog from "../../../components/common/cclAlertDialog";
import { Link, Typography } from "@mui/material";
import useLogAccessEvent from "../../../hooks/useLogAccessEvent";
import { AccessEventSessionDetails } from "../../../services/types/accessEventTypes";

export type SessionJournalDrawerProps = {
    sessionId: string;
    sessionCode: string;
    open: boolean;
    onClose: () => void;
    onSave: () => void;
};

const SessionJournalDrawer: React.FC<SessionJournalDrawerProps> = (props) => {
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>("");
    const { logEvent } = useLogAccessEvent();
    const catData = { sessionId: props.sessionId, sessionCode: props.sessionCode };

    function saveEntry() {
        const evtData: AccessEventSessionDetails = {
            sessionID: props.sessionId,
            projectCode: props.sessionCode,
        };
        logEvent("SessionJournalEntryCreated", evtData);
        props.onSave();
    }

    return (
        <>
            <CclAlertDialog
                open={alertOpen}
                onOk={() => setAlertOpen(false)}
                severity={"error"}
                title="Error Saving Journal Entry"
                msg={alertMessage}
            />
            {props.open ? (
                <JournalEntryDrawer
                    journalCategory={2}
                    journalCategoryData={JSON.stringify(catData)}
                    alert={
                        <Typography>
                            Please note: Any journal entries entered here will not display in IAS.
                            This feature is for proof of concept and user feedback purpose only.
                            Journaling will remain in IAS until this proof of concept is finalized,
                            including templates for journal entries. If you wish to provide any
                            feedback please contact Andy Wilson (
                            <Link href="mailto:wilsona@ccl.org">wilsona@ccl.org</Link>).
                        </Typography>
                    }
                    alertColor="error"
                    open={true}
                    onClose={() => props.onClose()}
                    onSave={() => saveEntry()}
                    onSaveError={(msg: string) => setAlertMessage(msg)}
                />
            ) : null}
        </>
    );
};

export default SessionJournalDrawer;
