import * as React from "react";
import { Alert, AlertColor, Card, CardContent, CardHeader } from "@mui/material";
import UpdateLoginApplicationCallbackDrawer from "./updateLoginApplicationCallbackDrawer";
import { GrandCentralCallbackUrl } from "../../../../services/types/cclGrandCentralApiTypes";
import {
    useAddCclApplicationCallbackMutation,
    useDeleteCclApplicationCallbackMutation,
    useUpdateCclApplicationCallbackMutation,
} from "../../../../services/cclTokenedGrandCentralApi";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { AccessEventGCSystemDetails } from "../../../../services/types/accessEventTypes";
import { DataGridPro, GridColumns, GridRowId, useGridApiRef } from "@mui/x-data-grid-pro";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridCustomButtonToolbar from "../../../../components/common/cclDataGridCustomButtonToolbar/cclDataGridCustomButtonToolbar";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";

export interface CallbackUrlCardProps {
    systemId: string;
    systemName: string;
    applicationId: string;
    gcCallbackUrls: GrandCentralCallbackUrl[];
    readonly: boolean;
}

export const CallbackUrlCard: React.FC<CallbackUrlCardProps> = (props) => {
    const [showUpdateCallbackDrawer, setShowUpdateCallbackDrawer] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");

    const [selectedCallbackUrl, setSelectedCallbackUrl] = React.useState<GrandCentralCallbackUrl>({
        applicationId: "",
        callbackUrlRegex: "",
        loginApplicationCallbackUrlId: "",
    });
    const [deleteUrl, { isLoading: deleteLoading }] = useDeleteCclApplicationCallbackMutation();
    const [updateCallback, { isLoading: updateLoading }] =
        useUpdateCclApplicationCallbackMutation();
    const [addCallback, { isLoading: addLoading }] = useAddCclApplicationCallbackMutation();
    const { logEvent } = useLogAccessEvent();
    const apiRef = useGridApiRef();

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    const onAddCallback = () => {
        setSelectedCallbackUrl({
            applicationId: props.applicationId,
            callbackUrlRegex: "",
            loginApplicationCallbackUrlId: "",
        });
        setShowUpdateCallbackDrawer(true);
    };

    const updateUrl = (url: GrandCentralCallbackUrl) => {
        setSelectedCallbackUrl(url);
        setShowUpdateCallbackDrawer(true);
    };

    const onCallbackUpdate = (url: string, callbackUrlId: string) => {
        if (callbackUrlId === "") {
            addCallback({
                url: url,
                applicationId: props.applicationId,
            })
                .unwrap()
                .then((res) => {
                    const evtData: AccessEventGCSystemDetails = {
                        systemId: props.systemId,
                        systemName: props.systemName,
                    };
                    logEvent("GCSystemChanged", evtData);
                    if (!res) {
                        ShowSnackbarElement("Add Callback URL Failed", "error");
                    } else {
                        ShowSnackbarElement("Callback URL Added", "success");
                    }
                })
                .catch((err) => {
                    ShowSnackbarElement("Add Callback URL Failed", "error");
                });
        } else {
            updateCallback({
                url: url,
                loginApplicationUrlId: callbackUrlId,
                applicationId: props.applicationId,
            })
                .unwrap()
                .then((res) => {
                    const evtData: AccessEventGCSystemDetails = {
                        systemId: props.systemId,
                        systemName: props.systemName,
                    };
                    logEvent("GCSystemChanged", evtData);
                    if (!res) {
                        ShowSnackbarElement("Update Callback URL Failed", "error");
                    } else {
                        ShowSnackbarElement("Callback URL Updated", "success");
                    }
                })
                .catch((err) => {
                    ShowSnackbarElement("Update Callback URL Failed", "error");
                });
        }
    };

    const onDeleteUrl = (url: GrandCentralCallbackUrl) => {
        deleteUrl({
            loginApplicationUrlId: url.loginApplicationCallbackUrlId,
            applicationId: url.applicationId,
            url: url.callbackUrlRegex,
        })
            .unwrap()
            .then((res) => {
                const evtData: AccessEventGCSystemDetails = {
                    systemId: props.systemId,
                    systemName: props.systemName,
                };
                logEvent("GCSystemChanged", evtData);
                if (!res) {
                    ShowSnackbarElement("Delete Callback URL Failed", "error");
                } else {
                    ShowSnackbarElement("Callback URL Deleted", "success");
                }
            })
            .catch((err) => {
                ShowSnackbarElement("Delete Callback URL Failed", "error");
            });
    };

    const updateSelectedRows = (rows: any[] | undefined) => {
        if (!rows) return;
        let rowsMap: Map<GridRowId, any> = apiRef.current.getSelectedRows();
        let rowArray: any[] = [];
        for (let row of Array.from(rowsMap.values())) {
            rowArray.push(row);
        }
        setSelectedRows(rowArray);
    };

    const handleBtnClick = (btnId: string) => {
        console.log("btnId: ", btnId);
        switch (btnId) {
            case "add-callback-url-button":
                onAddCallback();
                break;
            case "edit-callback-url-button":
                updateUrl(selectedRows[0]);
                break;
            case "delete-callback-url-button":
                onDeleteUrl(selectedRows[0]);
        }
    };

    const COLUMNS = [
        {
            field: "callbackUrlRegex",
            headerName: "Callback URL",
            type: "string",
            flex: 1,
        },
    ];

    return (
        <>
            {showSnackbar ? (
                <CclAlertSnackbar
                    open={true}
                    onClose={() => setShowSnackbar(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
            ) : null}
            {showUpdateCallbackDrawer ? (
                <UpdateLoginApplicationCallbackDrawer
                    open={true}
                    onClose={() => setShowUpdateCallbackDrawer(false)}
                    onSave={onCallbackUpdate}
                    url={selectedCallbackUrl.callbackUrlRegex}
                    loginApplicationCallbackUrlId={
                        selectedCallbackUrl.loginApplicationCallbackUrlId
                    }
                />
            ) : null}
            <Card variant="outlined" sx={{ height: 1 }}>
                <CardHeader title="Callback URLs" />
                <CardContent sx={{ padding: "5px" }}>
                    {props.gcCallbackUrls && props.gcCallbackUrls?.length > 0 ? (
                        <DataGridPro
                            apiRef={apiRef}
                            getRowId={(row) => row.loginApplicationCallbackUrlId}
                            rows={props.gcCallbackUrls}
                            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
                            components={{ Toolbar: CclDataGridCustomButtonToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showSaveState: false,
                                    hideSettingsButtons: true,
                                    miscButtons: [
                                        {
                                            id: "add-callback-url-button",
                                            caption: "Add",
                                            loadingBtn: true,
                                            isLoading: addLoading,
                                        },
                                        {
                                            id: "edit-callback-url-button",
                                            caption: "Edit",
                                            loadingBtn: true,
                                            isLoading: updateLoading,
                                            disable: selectedRows.length !== 1,
                                        },
                                        {
                                            id: "delete-callback-url-button",
                                            caption: "Delete",
                                            loadingBtn: true,
                                            isLoading: deleteLoading,
                                            disable: selectedRows.length !== 1,
                                        },
                                    ],
                                    btnClick: (btnId: string) => handleBtnClick(btnId),
                                    anySelected: null,
                                },
                            }}
                            checkboxSelection={true}
                            onSelectionModelChange={(rows: any[] | undefined) =>
                                updateSelectedRows(rows)
                            }
                            autoHeight={true}
                        />
                    ) : (
                        <>
                            <Alert severity="info">No Callback URLs Found</Alert>
                            <CclLoadingButton
                                loading={addLoading}
                                onClick={onAddCallback}
                                mode={"primary"}
                                size="small"
                                sx={{ mt: 3 }}
                            >
                                Add Callback URL{" "}
                            </CclLoadingButton>
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default CallbackUrlCard;
