import * as React from "react";
import {
    DataGridPremium,
    GridRenderCellParams,
    GridColumns,
    useGridApiRef,
    GridRowGroupingModel,
} from "@mui/x-data-grid-premium";
import { SessionDetailResourceAppointment } from "../../../../services/types/sessionDetailTypes";
import { UserClaimsService } from "../../../../services/currentUserService/currentUserService";
import { addCustomDataGridFilters } from "../../../../components/common/customDataGridFilters/customDataGridFilters";
import CclDataGridToolbar from "../../../../components/common/cclDataGridToolbar/cclDataGridToolbar";
import CclCommonLink from "../../../../components/common/cclCommonLink";

function getFullName(params: any) {
    if (params.rowNode.isAutoGenerated) {
        return params.rowNode.groupingKey;
    }
    return `${params.row.firstName || ""} ${params.row.lastName || ""}`;
}

export interface SessionAssignmentListProps {
    assignments: SessionDetailResourceAppointment[] | undefined;
}

export const SessionAssignmentListDataGridPro: React.FC<SessionAssignmentListProps> = (props) => {
    const claimsService = new UserClaimsService();
    const isNwaAdmin = claimsService.IsUserNwaAdmin();
    const [groupingColDef, setGroupingColDef] = React.useState<any>({ headerName: "Participant" });
    const apiRef = useGridApiRef();

    const handleRowGroupingModelChange = (model: GridRowGroupingModel) => {
        let groupedHeaders: string[] = [];
        model.forEach((group) => {
            groupedHeaders.push(COLUMNS.find((col) => col.field === group)?.headerName || "Group");
        });
        setGroupingColDef({ headerName: groupedHeaders.join(", ") });
    };

    const COLUMNS = [
        {
            field: "name",
            headerName: "Resource",
            renderCell: (params: GridRenderCellParams) =>
                isNwaAdmin ? (
                    <span>{params.value}</span>
                ) : (
                    <CclCommonLink
                        text={params.value}
                        keyVal={params.value}
                        to={`/resource/${params.row.pKey}/0`}
                    />
                ),
            flex: 0.8,
            valueGetter: getFullName,
            groupingValueGetter: getFullName,
        },
        {
            field: "email",
            headerName: "Email",
            renderCell: (params: GridRenderCellParams) => (
                <CclCommonLink
                    text={params.value}
                    keyVal={params.value}
                    to={`mailto:${params.value}`}
                    type={"email"}
                />
            ),
            type: "string",
            flex: 1,
        },
        {
            field: "mobilePhone",
            headerName: "Mobile Phone",
            type: "string",
            flex: 0.8,
        },
        {
            field: "resourceType",
            headerName: "Resource Type",
            type: "string",
            flex: 1,
        },
        {
            field: "startDateTimeUtc",
            headerName: "Start",
            valueGetter: (params: any) => new Date(params.row.startDateTimeUtc),
            groupingValueGetter: (params: any) => null,
            type: "string",
            renderCell: (params: GridRenderCellParams) => {
                if (!params.rowNode.isAutoGenerated) {
                    return <span>{params.value.toLocaleDateString()}</span>;
                } else {
                    return <span></span>;
                }
            },
            width: 150,
        },
        {
            field: "endDateTimeUtc",
            headerName: "End",
            valueGetter: (params: any) => new Date(params.row.endDateTimeUtc),
            type: "date",
            renderCell: (params: GridRenderCellParams) => {
                if (!params.rowNode.isAutoGenerated) {
                    return <span>{params.value.toLocaleDateString()}</span>;
                } else {
                    return <span></span>;
                }
            },
            width: 150,
        },
        {
            field: "categoryName",
            headerName: "Category",
            type: "string",
            flex: 1.25,
        },
        {
            field: "taskName",
            headerName: "Task Name",
            type: "string",
            flex: 1.25,
        },
        {
            field: "programTitle",
            headerName: "Title",
            type: "string",
            flex: 1,
        },
    ];

    return (
        <DataGridPremium
            getRowId={(row) => row.raKey}
            rows={props.assignments == null ? [] : props.assignments}
            columns={addCustomDataGridFilters(COLUMNS as GridColumns<any>)}
            components={{ Toolbar: CclDataGridToolbar }}
            componentsProps={{
                toolbar: {
                    showSaveState: false,
                    hideSettingsButtons: true,
                },
            }}
            groupingColDef={groupingColDef}
            onRowGroupingModelChange={handleRowGroupingModelChange}
            initialState={{
                sorting: { sortModel: [{ field: "startDateTimeUtc", sort: "desc" }] },
            }}
            apiRef={apiRef}
        />
    );
};

export default SessionAssignmentListDataGridPro;
