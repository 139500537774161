import { CclInfoTableRowTemplate } from "../../../../components/common/cclInfoTable/cclInfoTable";
import { eInfoTableValueFieldEditType } from "../../../../components/common/cclInfoTable/cclInfoTableValueField";

export const CclMyEmailTemplate: CclInfoTableRowTemplate[] = [
    {
        id: "templateId",
        fieldType: "string",
        editable: false,
        label: "ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.TextInput,
            url: null,
            hoverText: null,
            checked: null,
            id: "template_id",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "trackingId",
        fieldType: "string",
        editable: false,
        label: "Tracking ID",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.TextInput,
            url: null,
            hoverText: null,
            checked: null,
            id: "tracking_id",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "templateName",
        fieldType: "string",
        editable: true,
        label: "Name",
        required: true,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.TextInput,
            url: null,
            hoverText: null,
            checked: null,
            id: "template_name",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "subject",
        fieldType: "string",
        editable: true,
        label: "Subject",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.TextInput,
            url: null,
            hoverText: null,
            checked: null,
            id: "subject",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "ccRecipients",
        fieldType: "string",
        editable: true,
        label: "CC Recipients",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.TextInput,
            url: null,
            hoverText: null,
            checked: null,
            id: "cc_recipients",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "bccRecipients",
        fieldType: "string",
        editable: true,
        label: "BCC Recipients",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.TextInput,
            url: null,
            hoverText: null,
            checked: null,
            id: "bcc_recipients",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    // {
    //     id: "notificationType",
    //     fieldType: "string",
    //     editable: true,
    //     label: "Type",
    //     required: false,
    //     value: "",
    //     actionField: null,
    //     valueField: {
    //         value: "",
    //         editFieldType: eInfoTableValueFieldEditType.Select,
    //         url: null,
    //         hoverText: null,
    //         checked: null,
    //         id: "type",
    //         selectOptions: null, //TODO: Fix this for drop down option
    //     },
    // },
    {
        id: "createdUtc",
        fieldType: "string",
        editable: false,
        label: "Created",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.DateTimePicker,
            url: null,
            hoverText: null,
            checked: null,
            id: "created_utc",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "createdBy",
        fieldType: "string",
        editable: false,
        label: "Created By",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: "mailto:@@@",
            hoverText: null,
            checked: null,
            id: "created_by",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "modifiedUtc",
        fieldType: "string",
        editable: false,
        label: "Modified",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.DateTimePicker,
            url: null,
            hoverText: null,
            checked: null,
            id: "modified_utc",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
    {
        id: "modifiedBy",
        fieldType: "string",
        editable: false,
        label: "Modified By",
        required: false,
        value: "",
        actionField: null,
        valueField: {
            value: "",
            editFieldType: eInfoTableValueFieldEditType.Link,
            url: "mailto:@@@",
            hoverText: null,
            checked: null,
            id: "modified_by",
            selectOptions: null, //TODO: Fix this for drop down option
        },
    },
];
