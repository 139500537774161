import * as React from "react";
import { Alert, Box, Grid } from "@mui/material";
import { useGetSessionOptionsByEventKeyQuery } from "../../../../../services/cclTokenedSessionApi";
import ComponentLoader from "../../../../../components/common/componentLoader";
import ReadOnlyGate from "../../../../../components/userAccess/ReadOnlyGate";
import ActivateLookingGlassCard from "./activateLookingGlassCard";
import LookingGlassStatusCard from "./lookingGlassStatusCard";

interface ManageLookingGlassPanelProps {
    sessionId: string;
    readOnly?: boolean;
}

const ManageLookingGlassPanel: React.FC<ManageLookingGlassPanelProps> = (props) => {
    const {
        data: sessionOptions,
        isSuccess,
        isLoading,
    } = useGetSessionOptionsByEventKeyQuery(props.sessionId, { skip: props.sessionId === "" });

    if (isLoading) {
        return (
            <Box sx={{ mb: 2, mt: 2, p: 2, display: "flex", flexDirection: "column" }}>
                <ComponentLoader msg={"Loading Looking Glass Management Info"} />
            </Box>
        );
    }

    if (isSuccess && sessionOptions != null) {
        // looking glass can be activated for either digital tool id 2 or 3
        if (sessionOptions.digitalTools.find((dt) => dt.id === 2 || dt.id === 3) == null) {
            return (
                <Box sx={{ mb: 2, mt: 2, p: 2, display: "flex", flexDirection: "column" }}>
                    <Alert severity="info">
                        {
                            "Looking Glass must be added as an item requirement in F&O for this project in order to manage activation for participants"
                        }
                    </Alert>
                </Box>
            );
        }
        return (
            <Box sx={{ mb: 2, mt: 2, p: 2, display: "flex", flexDirection: "column" }}>
                <Grid container width={1} spacing={3}>
                    <ReadOnlyGate
                        readOnly={props.readOnly}
                        reactElement={
                            <Grid item xs={12} md={6}>
                                <ActivateLookingGlassCard sessionId={props.sessionId} />
                            </Grid>
                        }
                    />
                    <Grid item xs={12} md={6}>
                        <LookingGlassStatusCard
                            sessionId={props.sessionId}
                            sessionOptions={sessionOptions}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Box sx={{ mb: 2, mt: 2, p: 2, display: "flex", flexDirection: "column" }}>
            <Alert severity="error">
                {`Error retrieving Looking Glass information for session key ${props.sessionId}`}
            </Alert>
        </Box>
    );
};

export default ManageLookingGlassPanel;
