import React, { useEffect } from "react";
import { Alert, Box } from "@mui/material";
import { SessionCalendarEvent } from "../../services/types/sessionDetailTypes";
import CclDrawer from "../../components/common/cclDrawer/cclDrawer";
import { useGetAppUrlsQuery } from "../../services/cclTokenedSettingsApi";
import CclDrawerActionBar from "../../components/common/cclDrawer/cclDrawerActionBar";
import CclButton from "../../components/common/cclButtons/cclButton";
import CclInfoTable from "../../components/common/cclInfoTable/cclInfoTable";
import { CclInfoTblCalendarSessionDetailTemplate } from "./cclInfoTbCalendarSessionDetailTemplate";
import useLogAccessEvent from "../../hooks/useLogAccessEvent";
import { AccessEventSessionDetails } from "../../services/types/accessEventTypes";

export type CalendarSessionDetailDrawerProps = {
    open: boolean;
    evt: SessionCalendarEvent | null;
    onClose: () => void;
};

const CalendarSessionDetailDrawer: React.FC<CalendarSessionDetailDrawerProps> = (props) => {
    const { logEvent } = useLogAccessEvent();
    const { data: urls } = useGetAppUrlsQuery();
    const template = CclInfoTblCalendarSessionDetailTemplate;

    useEffect(() => {
        if (props.evt?.programId != null) {
            let row = template.find((r) => r.id === "title");
            if (row != null) {
                row.valueField.url = `/session/${props.evt?.programId}`;
            }
            row = template.find((r) => r.id === "programCode");
            if (row != null) {
                row.valueField.url = `/session/${props.evt?.programId}`;
            }
        }
    }, [props.evt, template]);

    const handleClose = () => {
        props.onClose();
    };

    const handleScore = () => {
        const evtData: AccessEventSessionDetails = {
            sessionID: props.evt?.programId.toString() ?? "unknow",
            projectCode: props.evt?.programCode ?? "unknown",
        };
        logEvent("AssessmentsScored", evtData);
        window.open(`${urls["CCLScoring"]}/program-status/${props.evt?.programId}`, "_blank");
    };

    return (
        <CclDrawer open={props.open} onClose={handleClose} title={"Session Details"}>
            <Box>
                {props.evt == null ? (
                    <Alert severity="error">Error retrieving session data</Alert>
                ) : (
                    <CclInfoTable template={template} data={props.evt} disableSave={true} />
                )}
            </Box>
            <CclDrawerActionBar>
                <CclButton onClick={handleScore} mode={"primary"}>
                    Score Assessments
                </CclButton>
                <CclButton onClick={handleClose} mode={"secondary"}>
                    Close
                </CclButton>
            </CclDrawerActionBar>
        </CclDrawer>
    );
};

export default CalendarSessionDetailDrawer;
