import {
    Autocomplete,
    Box,
    InputAdornment,
    TextField,
} from "@mui/material";
import * as React from "react";
import { CESystemUserList } from "../../../../services/types/clientTypes";
import CclDrawer from "../../../../components/common/cclDrawer/cclDrawer";
import CclButton from "../../../../components/common/cclButtons/cclButton";

export type AddTargetRevenueDialogProps = {
    open: boolean;
    onOk: (
        fiscalYear: number,
        accountOwnerName: string,
        revenueTarget: number,
        wildSearch: boolean
    ) => void;
    onCancel: () => any;
    CESystemData: CESystemUserList[];
    fiscalYearValue: number;
    revenueTargetValue: number;
    accountOwnerValue: string;
    addUpdateValue: boolean;
};

const AddTargetRevenueDialog: React.FC<AddTargetRevenueDialogProps> = (props) => {
    const title = "Add/Update Target Revenue";
    const [fiscalYear, setFiscalYear] = React.useState<number>();
    const [fiscalYearError, setFiscalYearError] = React.useState<string>("");
    const [accountOwner, setAccountOwner] = React.useState<string>("");
    const [accountOwnerError, setAccountOwnerError] = React.useState<string>("");
    const [revenueTarget, setRevenueTarget] = React.useState<number>();
    const [revenueTargetError, setRevenueTargetError] = React.useState<string>("");

    let accountOwnerList = props.CESystemData.map((option) => option.account_Name);
    let s = new Set(accountOwnerList);
    accountOwnerList = [...s];
    if (accountOwnerList[0] !== "") {
        accountOwnerList.unshift("");
    }

    React.useEffect(() => {
        if (props.addUpdateValue) {
            setFiscalYear(props.fiscalYearValue);
            setAccountOwner(props.accountOwnerValue);
            setRevenueTarget(props.revenueTargetValue);
        } else {
            setFiscalYear(0);
            setAccountOwner("");
            setRevenueTarget(0);
        }
    }, [
        props.accountOwnerValue,
        props.addUpdateValue,
        props.fiscalYearValue,
        props.open,
        props.revenueTargetValue,
    ]);

    const handleOk = () => {
        let eie: string = "";
        let fie: string = "";
        let lie: string = "";

        if (fiscalYear?.toString().length !== 4) eie = "Not a valid fiscal year";
        if (accountOwner === "" || accountOwner === "Please select a Account owner name")
            fie = "Please select a account owner name";
        if (revenueTarget === 0) lie = "Revenue Target can not be empty";

        setFiscalYearError(eie);
        setAccountOwnerError(fie);
        setRevenueTargetError(lie);

        if (eie !== "" || fie !== "" || lie !== "") return;

        props.onOk(fiscalYear ?? 0, accountOwner, revenueTarget ?? 0, props.addUpdateValue);
    };

    const handleKeyPress = async (evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === "Enter") {
            handleOk();
        }
    };

    return (

        <CclDrawer 
            title={title}
            open={props.open} 
            onClose={props.onCancel}
        >
            <TextField
                name="newFiscalYearValue"
                placeholder="Fiscal Year"
                value={fiscalYear}
                id="outlined-adornment-new-user-first-name"
                label="Fiscal Year"
                variant="outlined"
                fullWidth={true}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {/* <AccountBoxIcon /> */}
                        </InputAdornment>
                    ),
                }}
                error={fiscalYearError !== ""}
                helperText={fiscalYearError}
                onChange={(e) => {
                    e.preventDefault();
                    if (parseInt(e.target.value)=== null || parseInt(e.target.value) === undefined || e.target.value === "") {
                        setFiscalYear(0);
                        return;
                    }
                    setFiscalYear(parseInt(e.target.value));
                }}
                onKeyPress={(e) => handleKeyPress(e)}
                sx={{ mt: 2, mb: 2 }}
            />
            <Autocomplete
                id="tags-standard"
                options={accountOwnerList}
                value={accountOwner}
                fullWidth={true}
                disabled={props.addUpdateValue}
                onChange={(e: any, newValue: string | null) => {
                    setAccountOwner(newValue || "");              
                }}
                // defaultValue={props.applicationGroups}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder="Account System Owner"
                        error={accountOwnerError !== ""}
                        helperText={accountOwnerError}
                        sx={{ mt: 2, mb: 2 }}
                    />
                )}
                sx={{ mt: 2, mb: 2 }}
            />
            <TextField
                name="newRevenueTarget"
                placeholder="Target Revenue"
                value={revenueTarget}
                id="outlined-adornment-new-user-email"
                label="Revenue Target"
                variant="outlined"
                fullWidth={true}
                InputProps={{
                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                }}
                error={revenueTargetError !== ""}
                helperText={revenueTargetError}
                onChange={(e) => {
                    e.preventDefault();
                    if (parseInt(e.target.value)=== null || parseInt(e.target.value) === undefined || e.target.value === "") {
                        setRevenueTarget(0);
                        return;
                    }
                    setRevenueTarget(parseInt(e.target.value));
                }}
                onKeyPress={(e) => handleKeyPress(e)}
                sx={{ mt: 2, mb: 2 }}
            />
            <Box 
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    mt: 2,
                }}
            >
                <CclButton
                    id="cancel-btn"
                    onClick={() => props.onCancel()} 
                    mode={"primary"} 
                    sx={{ mr: 1 }}           
                >
                    Cancel
                </CclButton>
                <CclButton id="ok-btn" onClick={handleOk} mode={"primary"}>
                    Add
                </CclButton>
            </Box>
        </CclDrawer>
    );
};

export default AddTargetRevenueDialog;
